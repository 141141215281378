import CryptoJS, { format } from "crypto-js";
import { ArchiveService } from "../WebApiServices/Archive.service";
import ArchiveModel from "../DataModels/ArchiveModel";
import { DatePart, DayMonthUnknown, DefaultDate } from "./Enums";
import moment from "moment";
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);


/* <summary>
date: 06-04-2024
Name: AP
description: Get logged in user's ID
<summary>*/
export const GetLoggedInUserID = () => {
  const userId = GetLocalStorageData("userId");
  return Number(userId);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Add data to local storage in encrypted form
<summary>*/
export const AddLocalStorageData = (storageKey, value) => {

  const encryptedValue = EncryptUsingAES256(JSON.stringify(value));
  const encryptedKey = EncryptUsingAES256(storageKey);
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  }
  localStorage.setItem(encryptedKey, encryptedValue);
};

/* <summary>
date: 06-04-2024
Name: AP
description: get decrypted data from local storage
<summary>*/
export const GetLocalStorageData = (storageKey) => {

  const encryptedKey = EncryptUsingAES256(storageKey);
  const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
  try {
    if (encryptedValue) {
      return JSON.parse(DecryptUsingAES256(encryptedValue));
    }
  } catch (error) { }
  return encryptedValue;
};

/* <summary>
date: 06-04-2024
Name: AP
description: get decrypted data from local storage
<summary>*/
export const RemoveLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  localStorage.removeItem(encryptedKey);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Check if string is empty/undefined/null
<summary>*/
export const isStringEmpty = (text) => {

  //try {
  return !text || text.trim().length === 0;
  // } catch (error) {
  //   console.log(error)
  // }
};

/* <summary>
date: 06-04-2024
Name: AP
description: Encryption using CryptoJS
<summary>*/
export const EncryptUsingAES256 = (text) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/* <summary>
date: 06-04-2024
Name: AP
description: Decryption using CryptoJS
<summary>*/
export const DecryptUsingAES256 = (decString) => {
  const decrypted = CryptoJS.AES.decrypt(decString, key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
/* <summary>
date: 06-04-2024
Name: AP
description: Email Validation
<summary>*/
export const IsEmailValid = (emailAddress) => {
  const emailRegexString =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  ///^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/; from other ag projects
  return emailRegexString.test(emailAddress);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Validates password
<summary>*/
export const IsPasswordValid = (passowrd) => {
  const passwordRegexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
  return passwordRegexString.test(passowrd);
};
/* <summary>
date: 06-04-2024
Name: AP
description: Common fn calling archive service to add archive
<summary>*/
export const AddArchiveFunction = (actionNumber, data, userId, secondaryId) => {
  const payLoad = new ArchiveModel();
  payLoad.archiveaction = actionNumber;
  payLoad.archivedata = data;
  payLoad.userid = userId;
  payLoad.secondaryid = secondaryId;
  //payLoad.CreatedBy = userId;

  ArchiveService.AddArchive(payLoad);
};


//Allow only positive integers
//Value being passed shouldn't be blank
export const AllowOnlyIntegers = (value) => {
  const NUMBER_REGEX = /^[0-9\b]+$/;
  return NUMBER_REGEX.test(value)
}
//Allow only positive Decimals max 2 places
export const AllowPositiveDecimalNumbers = (value) => {
  const NUMBER_REGEX = /^[0-9]*(\.[0-9]{0,2})?$/;
  return NUMBER_REGEX.test(value)
}

/* <summary>
date: 07-06-2024
Name: AP
description: Sets the state for the date control
params: date: current date state value, value: used to update, datePart: D/M/Y
<summary>*/
export const SetDateState = (date, value, datePart) => {

  const parts = date?.split('/') ?? [];
  let month = parts[0] ?? "";
  let day = parts[1] ?? "";
  let year = parts[2] ?? "";

  // const parts = date.split('/');
  // let month = parts[0];
  // let day = parts[1];
  // let year = parts[2];

  if (datePart === DatePart.Day) {
    day = value;
  }
  else if (datePart === DatePart.Month) {
    month = value;
  }
  else if (datePart === DatePart.Year) {
    year = value;
  }

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}
/* <summary>
date: 07-06-2024
Name: AP
description: Handles UNK selection in date. Returns absolute date
<summary>*/
export const ParseDate = (dateStr) => {
  const parts = dateStr.split('/');
  let month = parts[0];
  let day = parts[1];
  const year = parts[2];

  if (day === "UNK" && month === "UNK") {
    day = "01";
    month = "07";
  } else if (day === "UNK") {
    day = "15";
  } else if (month === "UNK") {
    month = "06";
  }
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}
/* <summary>
date: 07-06-2024
Name: AP
description: Checks if date is empty or contains any default value in day month year dropdown
<summary>*/
export const IsDateEmpty = (date) => {
  return isStringEmpty(date) || date.includes("MM") || date.includes("DD") || date.includes("YYYY");
}
/* <summary>
date: 07-06-2024
Name: AP
description: Checks if date exists. If UNK is selected return true
<summary>*/

export function IsDtValid(date) {
  if (date === "Invalid Date") {
    return false;
  }
  else{
    return true;
  }
};

export const IsDateValid = (dateStr) => {
  //if UNK is present return true
  if (dateStr.includes("UNK")) {
    return true;
  }
  else {
    const date = moment(dateStr, "MM/DD/YYYY");
    return date.isValid();
  }
}

/* <summary>
date: 10-06-2024
Name: AP
description: Returns the Day Month Unknown id as per the presence of UNK value in the date
<summary>*/
export const GetDMUID = (dateStr) => {

  const parts = dateStr.split('/');
  let month = parts[0];
  let day = parts[1];

  if (day === "UNK" && month === "UNK") {
    return DayMonthUnknown.DayMonthUnk;
  } else if (day === "UNK") {
    return DayMonthUnknown.DayUnk;
  } else if (month === "UNK") {
    return DayMonthUnknown.MonthUnk;
  } else
    return null;
}
/* <summary>
date: 20-10-2023
Name: AP
description: Subtract months from date
<summary>*/
export const SubtractMonths = (dateStr, months) => {
  if (!dateStr) {
    return null;
  }
  const momentDate = moment(dateStr, "MM/DD/YYYY");
  if (!momentDate.isValid()) {
    return null;
  }
  return momentDate.subtract(months, 'months').toDate();
}
/* <summary>
date: 20-10-2023
Name: AP
description: Format date to display
<summary>*/
export const DateDisplayFormat = (date) => {
  const momentDate = moment(date);

  if (momentDate.isValid()) {
    const month = momentDate.format('MM');
    const day = momentDate.format('DD');
    const year = momentDate.format('YYYY');

    return `${month}/${day}/${year}`;
  } else {
    return false;
  }
}
/* <summary>
date: 10-06-2024
Name: AP
description: Render UNK selection in day month dropdowns
<summary>*/
export const GetDisplayDate = (dateStr, dmuId) => {
  if (IsDateEmpty(dateStr) || !dmuId) {
    return dateStr;
  }
  const parts = dateStr.split('/');
  let month = parts[0];
  let day = parts[1];
  const year = parts[2];

  if (dmuId === DayMonthUnknown.DayMonthUnk) {
    day = "UNK";
    month = "UNK";
  } else if (dmuId === DayMonthUnknown.MonthUnk) {
    month = "UNK";
  } else if (dmuId === DayMonthUnknown.DayUnk) {
    day = "UNK";
  }
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

/* <summary>
date: 11-06-2024
Name: NN
description: Check if age greater than equal to 18
<summary>*/
export const IsAge18 = (dob, referenceDate) => {

  if (!dob || !referenceDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDob = ParseDate(dob);
  const parsedReferenceDate = ParseDate(referenceDate);

  const birthDate = moment(parsedDob, "MM/DD/YYYY");
  const refDate = moment(parsedReferenceDate, "MM/DD/YYYY");

  if (!birthDate.isValid() || !refDate.isValid()) {
    return false;
  }

  const age = refDate.diff(birthDate, 'years', true); // The true parameter returns the difference as a float
  return age >= 18;
};

/* <summary>
date: 11-06-2024
Name: AP
description: Gets the ordinal value of a number
<summary>*/
export const GetOrdinal = (n) => {
  let suffix;
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    suffix = "th";
  } else {
    switch (lastDigit) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
        break;
    }
  }
  return <span>{n}<sup>{suffix}</sup></span>;
};

/* <summary>
date: 11-06-2024
Name: AP
description: Gets the ordinal value of a number in text format
<summary>*/
export const GetOrdinalInWord = (num) => {
  const ordinals = [
    "first", "second", "third", "fourth",
    "fifth", "sixth", "seventh", "eighth", "ninth", "tenth"
  ];
  if (num > 0 && num < 11) {
    return ordinals[num - 1];
  } else {
    return `${num}th`;
  }
};

export const ValueInRange = (value, lowLim, highLim) => {
  return value >= lowLim && value <= highLim;
}

// Function to display selected items as comma-separated text
// list of selected checkboxes and their associated text
export const DisplaySelectedText = (list) => {
  const sortedKeys = Array.from(list.keys()).sort((a, b) => a - b);
  const orderedValues = sortedKeys.map(key => list.get(key));
  return orderedValues.join(', ');
};

export const GetDifferenceInDays = (date1, date2) => {
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  const timeDiff = Math.abs(dateObj1 - dateObj2);
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return diffDays; // Convert milliseconds to days
};

export const IsDateBefore = (date1, date2) => {
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  if (dateObj1 < dateObj2)
    return true; // Convert milliseconds to days
  else
    return false;
};

export const IsDateAfter = (date1, date2) => {
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  if (dateObj1 > dateObj2)
    return true; // Convert milliseconds to days
  else
    return false;
};

export const IsDateBeforeMinus22Days = (date1, date2) => {
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  const dateObj2Minuss22Days = dateObj2.setDate(dateObj2.getDate() - 22);
  if (dateObj1 < dateObj2Minuss22Days)
    return true; // Convert milliseconds to days
  else
    return false;
};

export const IsDateBetween = (date1, date2, date3) => {
  const dateObj1 = new Date(date1);
  const dateEntered = new Date(date2);
  const dateObj3 = new Date(date3);

  
  if (dateEntered > dateObj1 && dateEntered < dateObj3)
  {

    return true;
  }
  else {

    return false;
  }
};

export const getOrdinalSuffix = (index) => {
  const j = index % 10;
  const k = index % 100;

  if (j === 1 && k !== 11) {
    return (
      <>
        {index}
        <sup>st</sup>
      </>
    );
  }
  if (j === 2 && k !== 12) {
    return (
      <>
        {index}
        <sup>nd</sup>
      </>
    );
  }
  if (j === 3 && k !== 13) {
    return (
      <>
        {index}
        <sup>rd</sup>
      </>
    );
  }
  return (
    <>
      {index}
      <sup>th</sup>
    </>
  );
};
/* <summary>
date: 10-10-2024
Name: AP
description: Checks if a word is found in a word list
<summary>*/ 
export const MatchesAnyWord = (inputStr, wordList) => {
  
  return wordList.some(w => w.toLowerCase() === inputStr.toLowerCase());
}