import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import DateControl from "../../Components/DateControl";
import InfoPopUp from "../../Components/InfoPopUp";
import { CommonError_Msgs, RelevantBloodTests, TLSMonitoring_Msgs, TLS_Table } from "../../Helpers/HelperText";
import ErrorField from "../../Components/ErrorField";
import { AppContext } from "../../Contexts/AppContextProvider";
import { TLSMonitoringService } from "../../WebApiServices/TLSMonitoring.service";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
  isStringEmpty,
  ParseDate,
  GetDifferenceInDays,
  IsDateBefore,
  IsDateBeforeMinus22Days,
  IsDateAfter,
  SubtractMonths,
  DateDisplayFormat,
  IsDateBetween,
  getOrdinalSuffix,
  GetOrdinal,
} from "../../Helpers/Utilities";
import dayjs from "dayjs";

const TlsMonitoring = () => {
  const navigatePaths = {
    prevPage: "/VenetoclaxRamUp",
    currPage: "/TlsMonitoring",
    nextPage: "/TreatmentInterruptions",
  };
  const pid = GetLocalStorageData("patientId") ?? 0;
  const {
    ShowToast,
    ToggleLoader,
    HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive,
  } = useContext(AppContext);

  const [showHideModalPopup, setShowHideModalPopup] = useState(false);
  const [showHideModalPopupLink, setShowHideModalPopupLink] = useState(false);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const [q33ErrorMsg, setQ33ErrorMsg] = useState(null);
  const [q35ErrorMsg, setQ35ErrorMsg] = useState(null);
  const [q36ResultAlcOutOfRange, setQ36ResultAlcOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
  const [q36ResultCalOutOfRange, setQ36ResultCalOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
  const [q36ResultScOutOfRange, setQ36ResultScOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
  const [q36ResultCrclOutOfRange, setQ36ResultCrclOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
  const [q36ResultLdhOutOfRange, setQ36ResultLdhOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
  const [q36ResultPhoOutOfRange, setQ36ResultPhoOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
  const [q36ResultPotOutOfRange, setQ36ResultPotOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
  const [q36ResultUricOutOfRange, setQ36ResultUricOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);

  const [radiographic_evaluationsRangeError_msg, setRadiographic_evaluationsRangeError_msg] = useState("");
  const [indexDate, setIndexDate] = useState('[Index Date]');
  const [index_dt_minus_2mo, setIndexDateMinus2Months] = useState('[index_date] - 2 months')
  const [venetoclaxStartDate, setVenetoclaxStartDate] = useState('[Venetoclax Start Date]');
  const [venetoclaxEndDate, setVenetoclaxEndDate] = useState('[Venetoclax End Date]');


  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);
      let isValid = true;

      if (tlsmonitoringDetails.weekCount !== null && tlsmonitoringDetails.weekCount > 0)
        isValid = validate ? (await ValidateForm() && await ValidateQ35Q36Repeater(bloodTestRowData)) : true;
      else
        isValid = validate ? await ValidateForm() : true;

      if (isValid) {
        setShowTopErrMsg(false)

        const radiographicData = radiographiceval_lymphDetails.map((lymphNodeRow) => {

          const hours = lymphNodeRow.timetestperformedHrsTxt ? parseInt(lymphNodeRow.timetestperformedHrsTxt, 10) : 0;
          const minutes = lymphNodeRow.timetestperformedMinTxt ? parseInt(lymphNodeRow.timetestperformedMinTxt, 10) : 0;

          // Check if hours and minutes are entered to create TestperformedTime
          const TestperformedTime = (lymphNodeRow.timetestperformedHrsTxt || lymphNodeRow.timetestperformedMinTxt)
            ? `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
            : ""; // If both are not entered, return an empty string

          // const Tdate = lymphNodeRow.testperformed_dt

          return {
            patientId: pid,
            Tlsradiographicevaluationsid: lymphNodeRow.tlsradiographicevaluationsid,
            Tlsmonitoringid: tlsmonitoringDetails.tlsmonitoringid,
            Testtype: lymphNodeRow.lymphNodetestTypeRd,
            DetermineProcess: lymphNodeRow.testtodetermineRd,
            TestperformedDate: lymphNodeRow.testperformed_dt === "Invalid Date" ? "" : lymphNodeRow.testperformed_dt,
            TestperformedUnk: lymphNodeRow.testperformedRd === 99 ? true : false,
            TestperformedTimeUnk: lymphNodeRow.timetestperformedRd === 99 ? true : false,
            LymphnodeSize: lymphNodeRow.lymphNode_sizeTxt,
            LymphnodeSizeUnk: lymphNodeRow.lymphNode_sizeRd === 99 ? true : false,
            TestperformedTime: TestperformedTime, // Use the merged time here
            LymphnodeCount: lymphNodeRow.lymphNodegreaterthanfiveTxt,
            LymphnodeCountUnk: lymphNodeRow.lymphNodegreaterthanfiveRd === 99 ? true : false,
          };
        });


        const alcCrclData = alc_crclDetails.map((item) => {

          const alchours = item.alc_timetestHrsTxt ? parseInt(item.alc_timetestHrsTxt, 10) : 0;
          const alcminutes = item.alc_timetestMinsTxt ? parseInt(item.alc_timetestMinsTxt, 10) : 0;

          // Check if hours and minutes are entered to create TestperformedTime
          const alcTestperformedTime = (item.alc_timetestHrsTxt || item.alc_timetestMinsTxt)
            ? `${alchours.toString().padStart(2, "0")}:${alcminutes.toString().padStart(2, "0")}`
            : ""; // If both are not entered, return an empty string


          const crclhours = item.crcl_timetestHrsTxt ? parseInt(item.crcl_timetestHrsTxt, 10) : 0;
          const crclminutes = item.crcl_timetestMinsTxt ? parseInt(item.crcl_timetestMinsTxt, 10) : 0;

          // Check if hours and minutes are entered to create TestperformedTime
          const crclTestperformedTime = (item.crcl_timetestHrsTxt || item.crcl_timetestMinsTxt)
            ? `${crclhours.toString().padStart(2, "0")}:${crclminutes.toString().padStart(2, "0")}`
            : ""; // If both are not entered, return an empty string



          return {
            patientId: pid,
            Tlsmonitoringsalcbridgeid: item.tlsmonitoringsalcbridgeid,
            Tlsmonitoringid: tlsmonitoringDetails.tlsmonitoringid,

            AlcTestused: item.alc_testtodetermineVCRamp_up,

            AlcTestperformed: item.alc_datetestperformed_dtRd,
            AlcDate: item.alc_datetestperformed_dt === "Invalid Date" ? "" : item.alc_datetestperformed_dt,
            AlcTime: alcTestperformedTime,
            AlcTimeUnk: item.alc_timetestperformedRd === 99 ? true : false,
            AlcResult: item.alctestresultTxt,
            AlcResultUnk: item.alc_testresultRd === 99 ? true : false,

            CrclTestUsed: item.crcl_testtodetermineVCRamp_up,
            CrclTestperformed: item.crcl_datetestperformed_dtRd,
            CrclDate: item.crcl_datetestperformed_dt === "Invalid Date" ? "" : item.crcl_datetestperformed_dt,
            CrclTime: crclTestperformedTime,
            CrclTimeUnk: item.crcl_timetestperformedRd === 99 ? true : false,
            CrclResult: item.crcl_testresultTxt,
            CrclResultUnk: item.crcl_testresultRd === 99 ? true : false,

          };
        });

        const bloodTestRowDataToSave = (tlsmonitoringDetails.weekCount !== null && tlsmonitoringDetails.weekCount > 0) ? mapBloodTestRowDataToTlsBloodTestModelSave(bloodTestRowData) : null;

        const tlsMonitoringModel = {
          patientId: pid,
          //31
          RadiographicCount: tlsmonitoringDetails.radiographic_evaluations_txt_value,
          RadiographicUnk: tlsmonitoringDetails.radiographic_evaluations === 99 ? true : false,

          AlcCount: tlsmonitoringDetails.testCompletedCount,
          AlcCountUnk: tlsmonitoringDetails.testCompletedCount_checked === 99 ? true : false,

          LoggedInUserId: GetLoggedInUserID(),
          isValidated: validate,
          TlsRadiographicData: radiographicData,
          AlcCrclData: alcCrclData,
          TlsBloodTestData: bloodTestRowDataToSave,
        };
        if (pid > 0) {
          const response = await TLSMonitoringService.SaveTLSMonitoringDetails(
            tlsMonitoringModel
          );
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            await LoadData();
            ToggleLoader(false);
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
          }
        }
        return true;
      }
      else {
        ToggleLoader(false);
        setShowTopErrMsg(true)
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };



  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);



  const LoadData = async () => {
    try {
      if (pid > 0) {
        const response = await TLSMonitoringService.LoadTLSMonitoringData(
          pid
        );
        if (response?.status === HTTPResponse.OK) {
          const { data } = response;

          setIndexDate(data.indexDate);
          setVenetoclaxStartDate(data.venetoclaxStartDate || "[Venetoclax Start Date]");
          setVenetoclaxEndDate(data.venetoclaxEndDate || "[Venetoclax End Date]");
          const indexDtMinus2MoObj = SubtractMonths(data.indexDate, 2);
          const indexDtMinus2Mo = DateDisplayFormat(indexDtMinus2MoObj);

          setIndexDateMinus2Months(indexDtMinus2Mo || '[index_date] - 2 months');

          setTlsmonitoringDetails((prev) => ({
            ...prev,
            userId: data.userId,
            tlsmonitoringid: data.tlsmonitoringid,

            radiographic_evaluations: data.radiographicUnk === true ? 99 :
              (data.radiographicCount !=null)  ? 1 : null,
            radiographic_evaluations_txt_value: data.radiographicCount,
            weekCount: data.weekCount,


            testCompletedCount_checked: data.alcCount !=null ? 1 : data.alcCountUnk === true ? 99 : null,
            testCompletedCount: data.alcCount,



            testCompleteEmptyHard: false,
            testCompleteTxtEmptyHard: false,
          }));

          setSoftErrList((prev) => ({
            ...prev,
            radiographic_evaluationsSoft: false,
            testCompleteTxtEmptyHard: false
          }))

          if (data.tlsBloodTestData != null &&
            data.tlsBloodTestData.length > 0) {
            const { tlsBloodTestData } = data;
            const tlsBloodTestArray = tlsBloodTestData.map((item, index) => {
              const bloodTestDetails = item.tlsbloodtestdetails?.length
                ? item.tlsbloodtestdetails.map((detail, j) => ({
                  // Q36 - General
                  tlsbloodtestdetailsbridgeid: detail.tlsbloodtestdetailsbridgeid || null,

                  // Col A
                  blooddraw_date: detail.blooddraw_date || null,
                  blooddraw_date_checked: detail.blooddraw_date_checked || null,
                  blooddraw_date_unk: detail.blooddraw_date_unk || null,
                  q36T1AEmpty: false,
                  q36T1ADateEmpty: false,
                  q36T1ADateCheckError: false,
                  q36T1ADateVenCheckError: false,
                  q36T1AInvalidDtError: false,

                  // Col B
                  reasonrepeattest: detail.reasonrepeattest || null,
                  confirm_lab_hemolyzed: detail.confirm_lab_hemolyzed || null,
                  post_hemolyzed: detail.post_hemolyzed || null,
                  inconclusive_hemolyzed: detail.inconclusive_hemolyzed || "",
                  q36T1BEmpty: false,
                  q36T1BSubValueEmpty: false,

                  // Col C
                  timeofblooddraw: detail.timeofblooddraw || null,
                  // blooddraw_oth_time: detail.blooddraw_oth_time || null,
                  blooddraw_oth_time_hr: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[0]
                    : "",
                  blooddraw_oth_time_min: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[1]
                    : "",
                  q36T1CEmpty: false,
                  q36T1CTimeEmpty: false,
                  q36T1CHrsEmpty: false,
                  q36T1CMinsEmpty: false,

                  // Col D
                  testsetting: detail.testsetting || null,
                  q36T1DEmpty: false,

                  // Col E
                  tls_laboratory: detail.tls_laboratory || null,
                  tls_clinical: detail.tls_clinical || null,
                  tls_neither: detail.tls_neither || null,
                  tls_unk: detail.tls_unk || null,
                  q36T1EEmpty: false,

                  // Col F
                  intervention_given: detail.intervention_given || null,
                  yes_dosehold: detail.yes_dosehold || null,
                  yes_ivhydration: detail.yes_ivhydration || null,
                  yes_metabolic: detail.yes_metabolic || null,
                  yes_oth: detail.yes_oth || null,
                  yes_oth_txt: detail.yes_oth_txt || null,
                  q36T1FEmpty: false,
                  q36T1FChildEmpty: false,
                  q36T1FOtherTxtEmpty: false,

                  // T2 - Alc
                  alc_test_performed: detail.alc_test_performed || null,
                  alc_test_result_checked: detail.alc_test_result_checked || null,
                  alc_formula_type: detail.alc_formula_type || null,
                  alc_formula_type_oth: detail.alc_formula_type_oth || null,
                  alc_test_result: detail.alc_test_result || null,
                  alc_test_result_unk: detail.alc_test_result_unk || null,
                  q36T2BAlcEmpty: false,
                  q36T2BAlcAllUnkNotTestedEmpty: false,
                  q36T2DAlcEmpty: false,
                  q36T2DAlcResultEmpty: false,
                  q36T2DAlcAllUnknownEmpty: false,
                  q36T2DAlcResultRange: false,

                  // T2 - Calcium
                  cal_test_performed: detail.cal_test_performed || null,
                  cal_test_result_checked: detail.cal_test_result_checked || null,
                  cal_formula_type: detail.cal_formula_type || null,
                  cal_formula_type_oth: detail.cal_formula_type_oth || null,
                  cal_test_result: detail.cal_test_result || null,
                  cal_test_result_unk: detail.cal_test_result_unk || null,
                  q36T2BCalEmpty: false,
                  q36T2BCalAllUnkNotTestedEmpty: false,
                  q36T2DCalEmpty: false,
                  q36T2DCalResultEmpty: false,
                  q36T2DCalAllUnknownEmpty: false,
                  q36T2DCalResultRange: false,

                  // T2 - Serum Creatinine
                  sc_test_performed: detail.sc_test_performed || null,
                  sc_test_result_checked: detail.sc_test_result_checked || null,
                  sc_formula_type: detail.sc_formula_type || null,
                  sc_formula_type_oth: detail.sc_formula_type_oth || null,
                  sc_test_result: detail.sc_test_result || null,
                  sc_test_result_unk: detail.sc_test_result_unk || null,
                  q36T2BScEmpty: false,
                  q36T2BScAllUnkNotTestedEmpty: false,
                  q36T2DScEmpty: false,
                  q36T2DScResultEmpty: false,
                  q36T2DScAllUnknownEmpty: false,
                  q36T2DScResultRange: false,

                  // T2 - Creatinine Clearance Level
                  crcl_test_performed: detail.crcl_test_performed || null,
                  crcl_test_result_checked: detail.crcl_test_result_checked || null,
                  crcl_formula_type: detail.crcl_formula_type || null,
                  crcl_formula_type_oth: detail.crcl_formula_type_oth || null,
                  crcl_test_result: detail.crcl_test_result || null,
                  crcl_test_result_unk: detail.crcl_test_result_unk || null,
                  q36T2BCrclEmpty: false,
                  q36T2CCrclEmpty: false,
                  q36T2CCrclOthTxtEmpty: false,
                  q36T2DCrclEmpty: false,
                  q36T2DCrclResultEmpty: false,
                  q36T2DCrclAllUnknownEmpty: false,
                  q36T2BCrclAllUnkNotTestedEmpty: false,
                  q36T2DCrclResultRange: false,


                  //T2 - Serum lactate dehydrogenase  
                  ldh_test_performed: detail.crcl_test_performed || null,
                  ldh_test_result_checked: detail.ldh_test_result_checked || null,
                  ldh_formula_type: detail.ldh_formula_type || null,
                  ldh_formula_type_oth: detail.ldh_formula_type_oth || null,
                  ldh_test_result: detail.ldh_test_result || null,
                  ldh_test_result_unk: detail.ldh_test_result_unk || null,
                  q36T2BLdhEmpty: false,
                  q36T2BLdhAllUnkNotTestedEmpty: false,
                  q36T2DLdhEmpty: false,
                  q36T2DLdhResultEmpty: false,
                  q36T2DLdhAllUnknownEmpty: false,
                  q36T2DLdhResultRange: false,

                  //T2 - Phosphorus
                  pho_test_performed: detail.pho_test_performed || null,
                  pho_test_result_checked: detail.pho_test_result_checked || null,
                  pho_formula_type: detail.pho_formula_type || null,
                  pho_formula_type_oth: detail.pho_formula_type_oth || null,
                  pho_test_result: detail.pho_test_result || null,
                  pho_test_result_unk: detail.pho_test_result_unk || null,
                  q36T2BPhoEmpty: false,
                  q36T2BPhoAllUnkNotTestedEmpty: false,
                  q36T2DPhoEmpty: false,
                  q36T2DPhoResultEmpty: false,
                  q36T2DPhoAllUnknownEmpty: false,
                  q36T2DPhoResultRange: false,

                  //T2 - Potassium
                  pot_test_performed: detail.pot_test_performed || null,
                  pot_test_result_checked: detail.pot_test_result_checked || null,
                  pot_formula_type: detail.pot_formula_type || null,
                  pot_formula_type_oth: detail.pot_formula_type_oth || null,
                  pot_test_result: detail.pot_test_result || null,
                  pot_test_result_unk: detail.pot_test_result_unk || null,
                  q36T2BPotEmpty: false,
                  q36T2BPotAllUnkNotTestedEmpty: false,
                  q36T2DPotEmpty: false,
                  q36T2DPotResultEmpty: false,
                  q36T2DPotAllUnknownEmpty: false,
                  q36T2DPotResultRange: false,

                  //T2 - Uric acid
                  uric_test_performed: detail.uric_test_performed || null,
                  uric_test_result_checked: detail.uric_test_result_checked || null,
                  uric_formula_type: detail.uric_formula_type || null,
                  uric_formula_type_oth: detail.uric_formula_type_oth || null,
                  uric_test_result: detail.uric_test_result || null,
                  uric_test_result_unk: detail.uric_test_result_unk || null,
                  q36T2BUricEmpty: false,
                  q36T2BUricAllUnkNotTestedEmpty: false,
                  q36T2DUricEmpty: false,
                  q36T2DUricResultEmpty: false,
                  q36T2DUricAllUnknownEmpty: false,
                  q36T2DUricResultRange: false,
                }))
                : [];

              // Generate the parent row
              return {
                ...BloodTestRow,
                id: item + 1, // Generate a unique ID for each row
                bloodTestDetails,
              };
            });

            // Update state with new Blood test data for Q36
            setBloodTestRowData((prevRows) => [
              ...tlsBloodTestArray
            ]);

          }


          //  #region tls radiographic evaluation
          if (
            data.tlsRadiographicData != null &&
            data.tlsRadiographicData.length > 0
          ) {
            const { tlsRadiographicData } = data;
            const tlsRadiographicDetailsArray = tlsRadiographicData.map((item, index) => {
              const tlsradiographicevaluationsid =
                item.tlsradiographicevaluationsid != null
                  ? item.tlsradiographicevaluationsid
                  : 0;

              const tlsmonitoringid = item.tlsmonitoringid != null
                ? item.tlsmonitoringid
                : 0;

              const testperformed_dt = !isStringEmpty(
                item.
                  testperformedDate)
                ?
                item.testperformedDate
                : "";


              const testperformedRd = item.testperformedUnk === true ? 99 :
                !isStringEmpty(item.testperformedDate) ? 1 : null;

              // Extracting hours and minutes from TimeOnly (assuming it's formatted as "HH:mm")
              const timetestperformedHrsTxt = item.testperformedTime
                ? item.testperformedTime.split(":")[0]
                : ""; // Extract hours
              const timetestperformedMinTxt = item.testperformedTime
                ? item.testperformedTime.split(":")[1]
                : ""; // Extract minutes


              return {
                ...LymphNodeRow,
                tlsradiographicevaluationsid,
                tlsmonitoringid,
                lymphNodetestTypeRd: item.testtype,
                testtodetermineRd: item.determineProcess,
                testperformed_dt,
                testperformedRd,
                lymphNode_sizeTxt: item.lymphnodeSize,
                lymphNode_sizeRd: item.lymphnodeSizeUnk === true ? 99 : item.lymphnodeSize != null ? 1 : null,

                lymphNodegreaterthanfiveTxt: item.lymphnodeCount,
                lymphNodegreaterthanfiveRd: item.lymphnodeCountUnk === true ? 99 : item.lymphnodeCount != null ? 1 : null,
                timetestperformedHrsTxt,
                timetestperformedMinTxt,
                timetestperformedRd: item.testperformedTimeUnk === true ? 99 : (timetestperformedHrsTxt || timetestperformedMinTxt) ? 1 : null

              };
            });
            setRadiographiceval_lymphDetails(tlsRadiographicDetailsArray);
          }
          //#endregion


          //#region Q35 & Q36 Blood test data binding
          if (
            data.tlsBloodTestData != null &&
            data.tlsBloodTestData.length > 0
          ) {
            const { tlsBloodTestData } = data;
            const bloodTestData = mapTlsBloodTestDataToBloodTestRowDataLoad(tlsBloodTestData);
            setBloodTestRowData(bloodTestData);
          }
          //#endregion


          //  #region tls alc crcl 
          if (
            data.alcCrclData != null &&
            data.alcCrclData.length > 0
          ) {
            const { alcCrclData } = data;
            const alcCrclcDetailsArray = alcCrclData.map((item, index) => {

              const tlsmonitoringsalcbridgeid =
                item.tlsmonitoringsalcbridgeid != null
                  ? item.tlsmonitoringsalcbridgeid
                  : 0;

              const tlsmonitoringid = item.tlsmonitoringid != null
                ? item.tlsmonitoringid
                : 0;



              const alc_datetestperformed_dt = !isStringEmpty(
                item.
                  alcDate)
                ?
                item.alcDate
                : "";

              const crcl_datetestperformed_dt = !isStringEmpty(
                item.
                  crclDate)
                ?
                item.crclDate
                : "";



              const alc_datetestperformed_dtRd = item.alcTestperformed;
              const crcl_datetestperformed_dtRd = item.crclTestperformed;

              // Extracting hours and minutes from TimeOnly ( formatted as "HH:mm")
              const alc_timetestHrsTxt = item.alcTime
                ? item.alcTime.split(":")[0]
                : ""; // Extract hours
              const alc_timetestMinsTxt = item.alcTime
                ? item.alcTime.split(":")[1]
                : ""; // Extract minutes


              // Extracting hours and minutes from TimeOnly ( formatted as "HH:mm")
              const crcl_timetestHrsTxt = item.crclTime
                ? item.crclTime.split(":")[0]
                : ""; // Extract hours
              const crcl_timetestMinsTxt = item.crclTime
                ? item.crclTime.split(":")[1]
                : ""; // Extract minutes

              return {
                ...AlcCrclRow,
                tlsmonitoringsalcbridgeid,
                tlsmonitoringid,

                alc_testtodetermineVCRamp_up: item.alcTestused,
                alc_datetestperformed_dt,
                alc_datetestperformed_dtRd,

                alc_timetestperformedRd: item.alcTimeUnk === true ? 99 : (alc_timetestHrsTxt || alc_timetestMinsTxt) ? 1 : null,
                alc_timetestHrsTxt,
                alc_timetestMinsTxt,
                alc_testresultRd: item.alcResultUnk === true ? 99 : item.alcResult !=null ? 1 : null,
                alctestresultTxt: item.alcResult,




                crcl_testtodetermineVCRamp_up: item.crclTestUsed,
                crcl_datetestperformed_dt,
                crcl_datetestperformed_dtRd,

                crcl_timetestperformedRd: item.crclTimeUnk === true ? 99 : (crcl_timetestHrsTxt || crcl_timetestMinsTxt) ? 1 : null,
                crcl_timetestHrsTxt,
                crcl_timetestMinsTxt,
                crcl_testresultRd: item.crclResultUnk === true ? 99 : item.crclResult != null ? 1 : null,
                crcl_testresultTxt: item.crclResult

              };
            });
            setAlc_CrclDetails(alcCrclcDetailsArray);
          }
          //#endregion

        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  // const mapBloodTestRowDataToTlsBloodTestModelSave = (bloodTestRowData) => {

  //   return bloodTestRowData.map((row) => ({


  //     // Q35 Parent properties
  //     Tlsmonitoringweekbridgeid: row.tlsmonitoringweekbridgeid,
  //     BloodtestCountChecked: row.bloodtest_count_checked,
  //     BloodtestCount: row.bloodtest_count,
  //     BloodtestCountUnk: row.bloodtest_count_unk,
  //     BloodTestCompleteEmpty: row.bloodTestCompleteEmpty || false,
  //     BloodTestCountEmpty: row.bloodTestCountEmpty || false,

  //     // Sublist of blood test details
  //     Tlsbloodtestdetails: row.bloodTestDetails.map((detail) => ({
  //       // Q36
  //       Tlsbloodtestdetailsbridgeid: detail.tlsbloodtestdetailsbridgeid,

  //       // T1 - col A
  //       BlooddrawDateChecked: detail.blooddraw_date_checked,
  //       BlooddrawDate: detail.blooddraw_date,
  //       BlooddrawDateUnk: detail.blooddraw_date_unk,

  //       // T1 - col B
  //       Reasonrepeattest: detail.reasonrepeattest,
  //       ConfirmLabHemolyzed: detail.confirm_lab_hemolyzed,
  //       PostHemolyzed: detail.post_hemolyzed,
  //       InconclusiveHemolyzed: detail.inconclusive_hemolyzed || "",

  //       // T1 - col C
  //       Timeofblooddraw: detail.timeofblooddraw,

  //       BlooddrawOthTime: blooddraw_oth_time,

  //       // T1 - col D
  //       Testsetting: detail.testsetting,

  //       // T1 - col E
  //       TlsLaboratory: detail.tls_laboratory,
  //       TlsClinical: detail.tls_clinical,
  //       TlsNeither: detail.tls_neither,
  //       TlsUnk: detail.tls_unk,

  //       // T1 - col F
  //       InterventionGiven: detail.intervention_given,
  //       YesDosehold: detail.yes_dosehold,
  //       YesIvhydration: detail.yes_ivhydration,
  //       YesMetabolic: detail.yes_metabolic,
  //       YesOth: detail.yes_oth,
  //       YesOthTxt: detail.yes_oth_txt,

  //       // T2 - Alc
  //       ALCTestperformed: detail.alc_test_performed,
  //       ALCTestresultint: null,
  //       ALCFormulaType: null,
  //       ALCFormulaTypeOth: null,
  //       ALCTestresult: detail.alc_test_result,
  //       ALCResultUnk: detail.alc_test_result_unk,

  //       // T2 - Calcium
  //       CalTestperformed: detail.cal_test_performed,
  //       CalTestresultint: null,
  //       CalFormulaType: null,
  //       CalFormulaTypeOth: null,
  //       CalTestresult: detail.cal_test_result,
  //       CalResultUnk: detail.cal_test_result_unk,

  //       // T2 - Serum creatinine
  //       SCTestperformed: detail.sc_test_performed,
  //       SCTestresultint: detail.sc_test_result,
  //       SCFormulaType: null,
  //       SCFormulaTypeOth: null,
  //       SCTestresult: null,
  //       SCResultUnk: detail.sc_test_result_unk,

  //       // T2 - Creatinine clearance level 
  //       CrclTestperformed: detail.crcl_test_performed,
  //       CrclTestresultint: detail.crcl_test_result,
  //       CrclFormulatype: detail.crcl_formula_type,
  //       CrclOthTxt: detail.crcl_formula_type_oth,
  //       CrclTestresult: null,
  //       CrclResultUnk: detail.crcl_test_result_unk,

  //       // T2 - Serum lactate dehydrogenase  
  //       LdhTestperformed: detail.ldh_test_performed,
  //       LdhTestresultint: detail.ldh_test_result,
  //       LdhFormulaType: null,
  //       LdhFormulaTypeOth: null,
  //       LdhTestresult: null,
  //       LdhResultUnk: detail.ldh_test_result_unk,

  //       // T2 - Phosphorus
  //       PhoTestperformed: detail.pho_test_performed,
  //       PhoTestresultint: detail.pho_test_result,
  //       PhoFormulaType: null,
  //       PhoFormulaTypeOth: null,
  //       PhoTestresult: null,
  //       PhoResultUnk: detail.pho_test_result_unk,

  //       // T2 - Potassium
  //       PotTestperformed: detail.pot_test_performed,
  //       PotTestresultint: detail.pot_test_result,
  //       PotFormulaType: null,
  //       PotFormulaTypeOth: null,
  //       PotTestresult: null,
  //       PotResultUnk: detail.pot_test_result_unk,

  //       // T2 - Uric acid
  //       UricTestperformed: detail.uric_test_performed,
  //       UricTestresultint: detail.uric_test_result,
  //       UricFormulaType: null,
  //       UricFormulaTypeOth: null,
  //       UricTestresult: null,
  //       UricResultUnk: detail.uric_test_result_unk,
  //     }))
  //   }));
  // };


  const mapBloodTestRowDataToTlsBloodTestModelSave = (bloodTestRowData) => {
    return bloodTestRowData.map((row) => ({
      // Q35 Parent properties
      Tlsmonitoringweekbridgeid: row.tlsmonitoringweekbridgeid,
      BloodtestCountChecked: row.bloodtest_count_checked,
      BloodtestCount: row.bloodtest_count,
      BloodtestCountUnk: row.bloodtest_count_unk,
      BloodTestCompleteEmpty: row.bloodTestCompleteEmpty || false,
      BloodTestCountEmpty: row.bloodTestCountEmpty || false,

      // Sublist of blood test details
      Tlsbloodtestdetails: row.bloodTestDetails.map((detail) => {
        // Parse and format hours and minutes
        const hours = detail.blooddraw_oth_time_hr ? parseInt(detail.blooddraw_oth_time_hr, 10) : 0;
        const minutes = detail.blooddraw_oth_time_min ? parseInt(detail.blooddraw_oth_time_min, 10) : 0;

        // Create time string if hours or minutes are present
        const blooddraw_oth_time = (detail.blooddraw_oth_time_hr || detail.blooddraw_oth_time_min)
          ? `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
          : ""; // If neither are present, return an empty string

        return {
          // Q36
          Tlsbloodtestdetailsbridgeid: detail.tlsbloodtestdetailsbridgeid,

          // T1 - col A
          BlooddrawDateChecked: detail.blooddraw_date_checked,
          BlooddrawDate: detail.blooddraw_date,
          BlooddrawDateUnk: detail.blooddraw_date_unk,

          // T1 - col B
          Reasonrepeattest: detail.reasonrepeattest,
          ConfirmLabHemolyzed: detail.confirm_lab_hemolyzed,
          PostHemolyzed: detail.post_hemolyzed,
          InconclusiveHemolyzed: detail.inconclusive_hemolyzed || "",

          // T1 - col C
          Timeofblooddraw: detail.timeofblooddraw,
          BlooddrawOthTime: blooddraw_oth_time,

          // T1 - col D
          Testsetting: detail.testsetting,

          // T1 - col E
          TlsLaboratory: detail.tls_laboratory,
          TlsClinical: detail.tls_clinical,
          TlsNeither: detail.tls_neither,
          TlsUnk: detail.tls_unk,

          // T1 - col F
          InterventionGiven: detail.intervention_given,
          YesDosehold: detail.yes_dosehold,
          YesIvhydration: detail.yes_ivhydration,
          YesMetabolic: detail.yes_metabolic,
          YesOth: detail.yes_oth,
          YesOthTxt: detail.yes_oth_txt,

          // T2 - Alc
          ALCTestperformed: detail.alc_test_performed,
          ALCTestresultint: null,
          ALCFormulaType: null,
          ALCFormulaTypeOth: null,
          ALCTestresult: detail.alc_test_result,
          ALCResultUnk: detail.alc_test_result_unk,

          // T2 - Calcium
          CalTestperformed: detail.cal_test_performed,
          CalTestresultint: null,
          CalFormulaType: null,
          CalFormulaTypeOth: null,
          CalTestresult: detail.cal_test_result,
          CalResultUnk: detail.cal_test_result_unk,

          // T2 - Serum creatinine
          SCTestperformed: detail.sc_test_performed,
          SCTestresultint: detail.sc_test_result,
          SCFormulaType: null,
          SCFormulaTypeOth: null,
          SCTestresult: null,
          SCResultUnk: detail.sc_test_result_unk,

          // T2 - Creatinine clearance level
          CrclTestperformed: detail.crcl_test_performed,
          CrclTestresultint: detail.crcl_test_result,
          CrclFormulatype: detail.crcl_formula_type,
          CrclOthTxt: detail.crcl_formula_type_oth,
          CrclTestresult: null,
          CrclResultUnk: detail.crcl_test_result_unk,

          // T2 - Serum lactate dehydrogenase
          LdhTestperformed: detail.ldh_test_performed,
          LdhTestresultint: detail.ldh_test_result,
          LdhFormulaType: null,
          LdhFormulaTypeOth: null,
          LdhTestresult: null,
          LdhResultUnk: detail.ldh_test_result_unk,

          // T2 - Phosphorus
          PhoTestperformed: detail.pho_test_performed,
          PhoTestresultint: detail.pho_test_result,
          PhoFormulaType: null,
          PhoFormulaTypeOth: null,
          PhoTestresult: null,
          PhoResultUnk: detail.pho_test_result_unk,

          // T2 - Potassium
          PotTestperformed: detail.pot_test_performed,
          PotTestresultint: detail.pot_test_result,
          PotFormulaType: null,
          PotFormulaTypeOth: null,
          PotTestresult: null,
          PotResultUnk: detail.pot_test_result_unk,

          // T2 - Uric acid
          UricTestperformed: detail.uric_test_performed,
          UricTestresultint: detail.uric_test_result,
          UricFormulaType: null,
          UricFormulaTypeOth: null,
          UricTestresult: null,
          UricResultUnk: detail.uric_test_result_unk,
        };
      }),
    }));
  };

  const mapTlsBloodTestDataToBloodTestRowDataLoad = (tlsBloodTestData) => {
    return tlsBloodTestData.map((data) => ({
      // Q35 Parent properties
      tlsmonitoringweekbridgeid: data.tlsmonitoringweekbridgeid,
      bloodtest_count_checked: data.bloodtestCount !=null ? 1 : null,
      bloodtest_count: data.bloodtestCount,
      bloodtest_count_unk: data.bloodtestCountUnk,
      bloodTestCompleteEmpty: data.BloodTestCompleteEmpty || false,
      bloodTestCountEmpty: data.BloodTestCountEmpty || false,

      // Sublist of blood test details
      bloodTestDetails: Array.isArray(data.tlsbloodtestdetails) && data.tlsbloodtestdetails.length > 0
        ? data.tlsbloodtestdetails.map((detail) => ({

          // Q36
          tlsbloodtestdetailsbridgeid: detail.tlsbloodtestdetailsbridgeid,

          // T1 - col A
          blooddraw_date_checked: detail.blooddrawDate != null ? 1 : null,
          blooddraw_date: detail.blooddrawDate,
          blooddraw_date_unk: detail.blooddrawDateUnk,
          q36T1AEmpty: false,
          q36T1ADateEmpty: false,
          q36T1ADateCheckError: false,
          q36T1ADateVenCheckError: false,
          q36T1AInvalidDtError: false,

          // T1 - col B
          reasonrepeattest: detail.reasonrepeattest,
          confirm_lab_hemolyzed: detail.confirmLabHemolyzed,
          post_hemolyzed: detail.postHemolyzed,
          inconclusive_hemolyzed: detail.inconclusiveHemolyzed || "",
          q36T1BEmpty: false,
          q36T1BSubValueEmpty: false,

          // T1 - col C
          timeofblooddraw: detail.timeofblooddraw,
          // blooddraw_oth_time: detail.blooddrawOthTime,
          blooddraw_oth_time_hr: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[0]
            : "",
          blooddraw_oth_time_min: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[1]
            : "",
          q36T1CEmpty: false,
          q36T1CTimeEmpty: false,
          q36T1CHrsEmpty: false,
          q36T1CMinsEmpty: false,

          // T1 - col D
          testsetting: detail.testsetting,
          q36T1DEmpty: false,

          // T1 - col E
          tls_laboratory: detail.tlsLaboratory,
          tls_clinical: detail.tlsClinical,
          tls_neither: detail.tlsNeither,
          tls_unk: detail.tlsUnk,
          q36T1EEmpty: false,

          // T1 - col F
          intervention_given: detail.interventionGiven,
          yes_dosehold: detail.yesDosehold,
          yes_ivhydration: detail.yesIvhydration,
          yes_metabolic: detail.yesMetabolic,
          yes_oth: detail.yesOth,
          yes_oth_txt: detail.yesOthTxt,
          q36T1FEmpty: false,
          q36T1FChildEmpty: false,
          q36T1FOtherTxtEmpty: false,

          // T2 - Alc
          alc_test_performed: detail.alcTestperformed,
          alc_test_result_checked: detail.alcTestresult != null ? 1 : null,
          alc_test_result: detail.alcTestresult,
          alc_test_result_unk: detail.alcResultUnk ? 99 : null,
          q36T2BAlcEmpty: false,
          q36T2BAlcAllUnkNotTestedEmpty: false,
          q36T2DAlcEmpty: false,
          q36T2DAlcResultEmpty: false,
          q36T2DAlcAllUnknownEmpty: false,
          q36T2DAlcResultRange: false,

          // T2 - Calcium
          cal_test_performed: detail.calTestperformed,
          cal_test_result_checked: detail.calTestresult != null ? 1 : null,
          cal_test_result: detail.calTestresult,
          cal_test_result_unk: detail.calResultUnk ? 99 : null,
          q36T2BCalEmpty: false,
          q36T2BCalAllUnkNotTestedEmpty: false,
          q36T2DCalEmpty: false,
          q36T2DCalResultEmpty: false,
          q36T2DCalAllUnknownEmpty: false,
          q36T2DCalResultRange: false,

          // T2 - Serum creatinine
          sc_test_performed: detail.scTestperformed,
          sc_test_result_checked: detail.scTestresultint != null ? 1 : null,
          sc_test_result: detail.scTestresultint,
          sc_test_result_unk: detail.scResultUnk ? 99 : null,
          q36T2BScEmpty: false,
          q36T2BScAllUnkNotTestedEmpty: false,
          q36T2DScEmpty: false,
          q36T2DScResultEmpty: false,
          q36T2DScAllUnknownEmpty: false,
          q36T2DScResultRange: false,

          // T2 - Creatinine clearance level 
          crcl_test_performed: detail.crclTestperformed,
          crcl_test_result_checked: detail.crclTestresultint != null ? 1 : null,
          crcl_formula_type: detail.crclFormulatype,
          crcl_formula_type_oth: detail.crclOthTxt,
          crcl_test_result: detail.crclTestresultint,
          crcl_test_result_unk: detail.crclResultUnk ? 99 : null,
          q36T2BCrclEmpty: false,
          q36T2CCrclEmpty: false,
          q36T2CCrclOthTxtEmpty: false,
          q36T2DCrclEmpty: false,
          q36T2DCrclResultEmpty: false,
          q36T2DCrclAllUnknownEmpty: false,
          q36T2BCrclAllUnkNotTestedEmpty: false,
          q36T2DCrclResultRange: false,

          // T2 - Serum lactate dehydrogenase  
          ldh_test_performed: detail.ldhTestperformed,
          ldh_test_result_checked: detail.ldhTestresultint != null ? 1 : null,
          ldh_test_result: detail.ldhTestresultint,
          ldh_test_result_unk: detail.ldhResultUnk ? 99 : null,
          q36T2BLdhEmpty: false,
          q36T2BLdhAllUnkNotTestedEmpty: false,
          q36T2DLdhEmpty: false,
          q36T2DLdhResultEmpty: false,
          q36T2DLdhAllUnknownEmpty: false,
          q36T2DLdhResultRange: false,

          // T2 - Phosphorus
          pho_test_performed: detail.phoTestperformed,
          pho_test_result_checked: detail.phoTestresultint != null ? 1 : null,
          pho_test_result: detail.phoTestresultint,
          pho_test_result_unk: detail.phoResultUnk ? 99 : null,
          q36T2BPhoEmpty: false,
          q36T2BPhoAllUnkNotTestedEmpty: false,
          q36T2DPhoEmpty: false,
          q36T2DPhoResultEmpty: false,
          q36T2DPhoAllUnknownEmpty: false,
          q36T2DPhoResultRange: false,

          // T2 - Potassium
          pot_test_performed: detail.potTestperformed,
          pot_test_result_checked: detail.potTestresultint != null ? 1 : null,
          pot_test_result: detail.potTestresultint,
          pot_test_result_unk: detail.potResultUnk ? 99 : null,
          q36T2BPotEmpty: false,
          q36T2BPotAllUnkNotTestedEmpty: false,
          q36T2DPotEmpty: false,
          q36T2DPotResultEmpty: false,
          q36T2DPotAllUnknownEmpty: false,
          q36T2DPotResultRange: false,

          // T2 - Uric acid
          uric_test_performed: detail.uricTestperformed,
          uric_test_result_checked: detail.uricTestresultint != null ? 1 : null,
          uric_test_result: detail.uricTestresult,
          uric_test_result_unk: detail.uricResultUnk ? 99 : null,
          q36T2BUricEmpty: false,
          q36T2BUricAllUnkNotTestedEmpty: false,
          q36T2DUricEmpty: false,
          q36T2DUricResultEmpty: false,
          q36T2DUricAllUnknownEmpty: false,
          q36T2DUricResultRange: false,
        }))
        : [],
    }));
  };


  const InitialSoftErrList = {
    radiographic_evaluationsSoft: false,
  }

  const InitialHardErrList = {
    radiographic_evaluationsEmptyHard: false,
    radiographic_evaluationsTxtEmptyHard: false,

    testCompleteEmptyHard: false,
    testCompleteTxtEmptyHard: false,
    testCompleteTxtEmpty: false,

  }

  const [softErrList, setSoftErrList] = useState(InitialSoftErrList)
  const [hardErrList, setHardErrList] = useState(InitialHardErrList)



  //question 36 state handling properties
  const BloodTestRow = {
    //Q35 Parent properties
    tlsmonitoringweekbridgeid: 0,
    bloodtest_count_checked: null,
    bloodtest_count: null,
    bloodtest_count_unk: null,
    bloodTestCompleteEmpty: false,
    bloodTestCountEmpty: false,

    // Sublist of blood test details
    bloodTestDetails: {
      //Q36
      tlsbloodtestdetailsbridgeid: 0,

      //T1 - col A
      blooddraw_date_checked: null,
      blooddraw_date: null,
      blooddraw_date_unk: null,
      q36T1AEmpty: false,
      q36T1ADateEmpty: false,
      q36T1ADateCheckError: false,
      q36T1ADateVenCheckError: false,
      q36T1AInvalidDtError: false,
      //T1 - col B
      reasonrepeattest: null,
      confirm_lab_hemolyzed: null,
      post_hemolyzed: null,
      inconclusive_hemolyzed: "",
      q36T1BEmpty: false,
      q36T1BSubValueEmpty: false,
      //T1 - col C
      timeofblooddraw: null,
      blooddraw_oth_time_hr: "",
      blooddraw_oth_time_min: "",
      blooddraw_oth_time: "",
      q36T1CEmpty: false,
      q36T1CTimeEmpty: false,
      q36T1CHrsEmpty: false,
      q36T1CMinsEmpty: false,
      //T1 - col D
      testsetting: null,
      q36T1DEmpty: false,
      //T1 - col E
      tls_laboratory: null,
      tls_clinical: null,
      tls_neither: null,
      tls_unk: null,
      q36T1EEmpty: false,
      //T1 - col F
      intervention_given: null,
      yes_dosehold: null,
      yes_ivhydration: null,
      yes_metabolic: null,
      yes_oth: null,
      yes_oth_txt: null,
      q36T1FEmpty: false,
      q36T1FChildEmpty: false,
      q36T1FOtherTxtEmpty: false,

      //T2 - Alc
      alc_test_performed: null,
      alc_test_result_checked: null,
      alc_formula_type: null,
      alc_formula_type_oth: null,
      alc_test_result: null,
      alc_test_result_unk: null,
      q36T2BAlcEmpty: false,
      q36T2DAlcEmpty: false,
      q36T2DAlcResultEmpty: false,
      q36T2DAlcAllUnknownEmpty: false,
      q36T2BAlcAllUnkNotTestedEmpty: false,
      q36T2DAlcResultRange: false,

      //T2 - Calcium
      cal_test_performed: null,
      cal_test_result_checked: null,
      cal_formula_type: null,
      cal_formula_type_oth: null,
      cal_test_result: null,
      cal_test_result_unk: null,
      q36T2BCalEmpty: false,
      q36T2DCalEmpty: false,
      q36T2DCalResultEmpty: false,
      q36T2DCalAllUnknownEmpty: false,
      q36T2BCalAllUnkNotTestedEmpty: false,
      q36T2DCalResultRange: false,

      //T2 - Serum creatinine
      sc_test_performed: null,
      sc_test_result_checked: null,
      sc_formula_type: null,
      sc_formula_type_oth: null,
      sc_test_result: null,
      sc_test_result_unk: null,
      q36T2BScEmpty: false,
      q36T2DScEmpty: false,
      q36T2DScResultEmpty: false,
      q36T2DScAllUnknownEmpty: false,
      q36T2BScAllUnkNotTestedEmpty: false,
      q36T2DScResultRange: false,

      //T2 - Creatinine clearance level 
      crcl_test_performed: null,
      crcl_test_result_checked: null,
      crcl_formula_type: null,
      crcl_formula_type_oth: null,
      crcl_test_result: null,
      crcl_test_result_unk: null,
      q36T2BCrclEmpty: false,
      q36T2CCrclEmpty: false,
      q36T2CCrclOthTxtEmpty: false,
      q36T2DCrclEmpty: false,
      q36T2DCrclResultEmpty: false,
      q36T2DCrclAllUnknownEmpty: false,
      q36T2BCrclAllUnkNotTestedEmpty: false,
      q36T2DCrclResultRange: false,

      //T2 - Serum lactate dehydrogenase  
      ldh_test_performed: null,
      ldh_test_result_checked: null,
      ldh_formula_type: null,
      ldh_formula_type_oth: null,
      ldh_test_result: null,
      ldh_test_result_unk: null,
      q36T2BLdhEmpty: false,
      q36T2DLdhEmpty: false,
      q36T2DLdhResultEmpty: false,
      q36T2DLdhAllUnknownEmpty: false,
      q36T2BLdhAllUnkNotTestedEmpty: false,
      q36T2DLdhResultRange: false,

      //T2 - Phosphorus
      pho_test_performed: null,
      pho_test_result_checked: null,
      pho_formula_type: null,
      pho_formula_type_oth: null,
      pho_test_result: null,
      pho_test_result_unk: null,
      q36T2BPhoEmpty: false,
      q36T2DPhoEmpty: false,
      q36T2DPhoResultEmpty: false,
      q36T2DPhoAllUnknownEmpty: false,
      q36T2BPhoAllUnkNotTestedEmpty: false,
      q36T2DPhoResultRange: false,

      //T2 - Potassium
      pot_test_performed: null,
      pot_test_result_checked: null,
      pot_formula_type: null,
      pot_formula_type_oth: null,
      pot_test_result: null,
      pot_test_result_unk: null,
      q36T2BPotEmpty: false,
      q36T2DPotEmpty: false,
      q36T2DPotResultEmpty: false,
      q36T2DPotAllUnknownEmpty: false,
      q36T2BPotAllUnkNotTestedEmpty: false,
      q36T2DPotResultRange: false,

      //T2 - Uric acid
      uric_test_performed: null,
      uric_test_result_checked: null,
      uric_formula_type: null,
      uric_formula_type_oth: null,
      uric_test_result: null,
      uric_test_result_unk: null,
      q36T2BUricEmpty: false,
      q36T2DUricEmpty: false,
      q36T2DUricResultEmpty: false,
      q36T2DUricAllUnknownEmpty: false,
      q36T2BUricAllUnkNotTestedEmpty: false,
      q36T2DUricResultRange: false,
    }
  }

  const [bloodTestRowData, setBloodTestRowData] = useState([BloodTestRow]);


  const AlcCrclRow = {

    tlsmonitoringsalcbridgeid: 0, // primary key 
    tlsmonitoringid: 0,

    alc_testtodetermineVCRamp_up: null,

    alc_datetestperformed_dtRd: null,
    alc_datetestperformed_dt: "",
    alc_datetestperformed_dtSoft: false,

    alc_timetestperformedRd: null,
    alc_timetestHrsTxt: "",
    alc_timetestMinsTxt: "",

    alcTestperformedTime: null,

    alc_testresultRd: null,
    alctestresultTxt: "",
    alctestresultSoft: false,


    alctesttodetermineVCRampEmpty: false,
    alc_datetestperformed_dtRdEmpty: false,
    alc_datetestperformed_dtEmpty: false,
    alc_timetestperformedRdEmpty: false,

    alc_timetestperformedHrsEmpty: false,
    alc_timetestperformedMinsEmpty: false,
    alc_timetestperformedMinsHrsEmpty: false,
    alc_testresultRdEmpty: false,
    alc_testresultTxtEmpty: false,

    alc_datetestperformedInvalid: false,
    crcl_datetestperformedInvalid: false,

    crcltesttodetermineVCRampEmpty: false,
    crcl_datetestperformed_dtRdEmpty: false,
    crcl_datetestperformed_dtEmpty: false,
    crcl_timetestperformedRdEmpty: false,

    crcl_timetestperformedHrsEmpty: false,
    crcl_timetestperformedMinsEmpty: false,
    crcl_timetestperformedMinsHrsEmpty: false,
    crcl_testresultRdEmpty: false,
    crcl_testresultTxtEmpty: false,


    crcl_testtodetermineVCRamp_up: null,

    crcl_datetestperformed_dtRd: null,
    crcl_datetestperformed_dt: "",
    crcl_datetestperformed_dtSoft: false,


    crcl_timetestperformedRd: null,
    crcl_timetestHrsTxt: "",
    crcl_timetestMinsTxt: "",

    crclTestperformedTime: null,

    crcl_testresultRd: null,
    crcl_testresultTxt: "",

    crcl_testresultSoft: false,
  }
  const [alc_crclDetails, setAlc_CrclDetails] = useState([AlcCrclRow])



  const [tlsmonitoringDetails, setTlsmonitoringDetails] = useState({
    tlsmonitoringid: 0,
    radiographic_evaluations: null,
    radiographic_evaluations_txt_value: "",
    testCompletedCount_checked: null,
    testCompletedCount: "",
    testCompletedCount_unk: null,
    weekCount: null,

    bloodTestCompletedCount_checked: null,
    bloodTestCompletedCount: "",
    bloodTestCompletedCount_unk: null,
  });

  //question 32 state handling properties
  const LymphNodeRow = {
    tlsradiographicevaluationsid: 0, //primary key of the table 
    tlsmonitoringid: 0,
    //col 1
    lymphNodetestTypeRd: null,
    lymphNodetestTypeEmptyHard: false,
    //col 2
    testtodetermineRd: null,
    testtodetermineEmptyHard: false,
    //col 3
    testperformedRd: null,
    testperformed_dt: "",
    testperformedEmptyHard: false,
    testperformedDateEmptyHard: false,
    testperformed_dtSoft: false,

    testperformed_dtInvalid: false,

    //col 4
    timetestperformedRd: null,
    timetestperformedHrsTxt: "",
    TestperformedTime: null,
    timetestperformedMinTxt: "",
    timetestperformedEmptyHard: false,
    timetestperformedHrsEmptyHard: false,
    timetestperformedMinEmptyHard: false,
    timetestperformedHrsMinEmptyHard: false,
    //col 5
    lymphNode_sizeRd: null,
    lymphNode_sizeTxt: "",
    lymphNode_sizeEmptyHard: false,
    lymphNode_sizeTxtEmptyHard: false,

    lymphNode_sizeTxtSoft: false,

    // col 6
    lymphNodegreaterthanfiveRd: null,
    lymphNodegreaterthanfiveTxt: "",
    lymphNodegreaterthanfiveEmptyHard: false,
    lymphNodegreaterthanfiveTxtEmptyHard: false,

    lymphNodegreaterthanfiveTxtSoft: false,
  }

  const [radiographiceval_lymphDetails, setRadiographiceval_lymphDetails] = useState([LymphNodeRow]);


  async function ValidateQ35Q36Repeater(list) {
    // Your asynchronous processing logic for the list goes here
    const promises = list.map((item) => ValidateList(item));
    const results = await Promise.all(promises);
    return !results.includes(false);
  }

  async function ValidateList(item) {
    // Your asynchronous processing logic for an individual item
    return new Promise((resolve) => {
      setTimeout(() => {
        const isValid = ValidateRepeaterData(item);
        resolve(isValid);
      }, 1000); // Simulate async work
    });
  }

  async function ValidateRepeaterData(repeaterarray) {
    let result = true;

    //Q35
    if (repeaterarray.bloodtest_count_checked === null && repeaterarray.bloodtest_count_unk === null) {
      result = false;
      repeaterarray.bloodTestCompleteEmpty = true;
    } else {
      repeaterarray.bloodTestCompleteEmpty = false;
    }

    //Q35
    if (repeaterarray.bloodtest_count_checked === 1 && repeaterarray.bloodtest_count_unk === null && repeaterarray.bloodtest_count === null) {
      result = false;
      repeaterarray.bloodTestCountEmpty = true;
    } else {
      repeaterarray.bloodTestCountEmpty = false;
    }

    //Q36
    // Validate each child item in bloodTestDetails (Q36)
    for (let child of repeaterarray.bloodTestDetails) {

      // Table 1: Col A - Blooddraw Date
      if (child.blooddraw_date_checked === null && child.blooddraw_date_unk === null) {
        result = false;
        child.q36T1AEmpty = true;
      } else {
        child.q36T1AEmpty = false;
        child.q36T1ADateCheckError = false;
        child.q36T1ADateVenCheckError = false;
        child.q36T1AInvalidDtError = false;
      }

      if (child.blooddraw_date_checked === 1 && (child.blooddraw_date !== null || child.blooddraw_date !== "") && child.blooddrawDate === "InValid Date") {
        result = false;
        child.q36T1AInvalidDtError = true;
      }
      else {
        child.q36T1AInvalidDtError = false;
      }

      if ((child.blooddraw_date === null || child.blooddraw_date === "") && child.blooddraw_date_checked === 1) {
        result = false;
        child.q36T1ADateEmpty = true;
      } else {
        child.q36T1ADateEmpty = false;
        child.q36T1ADateCheckError = false;
        child.q36T1ADateVenCheckError = false;
      }


      // Table 1: Col B - Reason Repeat Test, Hemolyzed values
      if (child.reasonrepeattest === null &&
        (child.confirm_lab_hemolyzed === null || child.post_hemolyzed === null || child.inconclusive_hemolyzed === null)) {
        result = false;
        child.q36T1BEmpty = true;
      } else {
        child.q36T1BEmpty = false;
      }

      if (child.reasonrepeattest === 2 && child.confirm_lab_hemolyzed === null) {
        result = false;
        child.q36T1BSubValueEmpty = true;
      } else {
        child.q36T1BSubValueEmpty = false;
      }

      if (child.reasonrepeattest === 3 && child.post_hemolyzed === null) {
        result = false;
        child.q36T1BSubValueEmpty = true;
      } else {
        child.q36T1BSubValueEmpty = false;
      }

      if (child.reasonrepeattest === 4 && child.inconclusive_hemolyzed === null) {
        result = false;
        child.q36T1BSubValueEmpty = true;
      } else {
        child.q36T1BSubValueEmpty = false;
      }

      // Table 1: Col C - Time of Blood Draw
      if (child.timeofblooddraw === null) {
        result = false;
        child.q36T1CEmpty = true;
      } else {
        child.q36T1CEmpty = false;
      }

      if (child.timeofblooddraw === 4 && (child.blooddraw_oth_time === null || child.blooddraw_oth_time === "")) {
        result = false;
        child.q36T1CTimeEmpty = true;
      } else {
        child.q36T1CTimeEmpty = false;
      }

      // Table 1: Col D - Test Setting
      if (child.testsetting === null) {
        result = false;
        child.q36T1DEmpty = true;
      } else {
        child.q36T1DEmpty = false;
      }

      // Table 1: Col E - Laboratory/Clinical/Neither
      if (child.tls_laboratory === null && child.tls_clinical === null && child.tls_neither === null && child.tls_unk === null) {
        result = false;
        child.q36T1EEmpty = true;
      } else {
        child.q36T1EEmpty = false;
      }

      // Table 1: Col F - Intervention Given
      if (child.intervention_given === null) {
        result = false;
        child.q36T1FEmpty = true;
      } else {
        child.q36T1FEmpty = false;
      }

      if (child.intervention_given === 1 && child.yes_dosehold === null && child.yes_ivhydration === null && child.yes_metabolic === null && child.yes_oth === null) {
        result = false;
        child.q36T1FChildEmpty = true;
      } else {
        child.q36T1FChildEmpty = false;
      }

      if (child.yes_oth === true && (child.yes_oth_txt === null || child.yes_oth_txt === "")) {
        result = false;
        child.q36T1FOtherTxtEmpty = true;
      } else {
        child.q36T1FOtherTxtEmpty = false;
      }

      // Table 2 Col B
      if (child.alc_test_performed === null || child.cal_test_performed === null || child.sc_test_performed === null ||
        child.crcl_test_performed === null || child.ldh_test_performed === null || child.pho_test_performed === null ||
        child.pot_test_performed === null || child.uric_test_performed === null) {
        result = false;
        child.q36T2BAlcEmpty = child.alc_test_performed === null;
        child.q36T2BCalEmpty = child.cal_test_performed === null;
        child.q36T2BSCEmpty = child.sc_test_performed === null;
        child.q36T2BCrclEmpty = child.crcl_test_performed === null;
        child.q36T2BLdhEmpty = child.ldh_test_performed === null;
        child.q36T2BPhoEmpty = child.pho_test_performed === null;
        child.q36T2BPotEmpty = child.pot_test_performed === null;
        child.q36T2BUricEmpty = child.uric_test_performed === null;
      } else {
        child.q36T2BAlcEmpty = false;
        child.q36T2BCalEmpty = false;
        child.q36T2BSCEmpty = false;
        child.q36T2BCrclEmpty = false;
        child.q36T2BLdhEmpty = false;
        child.q36T2BPhoEmpty = false;
        child.q36T2BPotEmpty = false;
        child.q36T2BUricEmpty = false;
        child.q36T2BAllUnkNotTestedEmpty = false;
      }

      if (child.crcl_test_performed === 1) {
        // Table 2 Col C
        if (child.crcl_formula_type === null) {
          result = false;
          child.q36T2CCrclEmpty = true;
        } else {
          child.q36T2CCrclEmpty = false;
        }

        if (child.crcl_formula_type === 2 && (child.crcl_formula_type_oth === null || child.crcl_formula_type_oth === "")) {
          result = false;
          child.q36T2CCrclOthTxtEmpty = true;
        } else {
          child.q36T2CCrclOthTxtEmpty = false;
        }
      }

      // Table 2 Col D
      if ((child.alc_test_result_checked === null && child.alc_result_unk === null) ||
        (child.cal_test_result_checked === null && child.cal_test_result_unk === null) ||
        (child.sc_test_result_checked === null && child.sc_test_result_unk === null) ||
        (child.crcl_test_result_checked === null && child.crcl_test_result === null) ||
        (child.ldh_test_result_checked === null && child.ldh_test_result === null) ||
        (child.pho_test_result_checked === null && child.pho_test_result_unk === null) ||
        (child.pot_test_result_checked === null && child.pot_test_result === null) ||
        (child.uric_test_result_checked === null && child.uric_test_result === null)) {
        result = false;
        child.q36T2DAlcEmpty = child.alc_test_performed === 1 && child.alc_test_result_checked === null;
        child.q36T2DCalEmpty = child.cal_test_performed === 1 && child.cal_test_result_checked === null;
        child.q36T2DSCEmpty = child.sc_test_performed === 1 && child.sc_test_result_checked === null;
        child.q36T2DCrclEmpty = child.crcl_test_performed === 1 && child.crcl_test_result_checked === null;
        child.q36T2DLdhEmpty = child.ldh_test_performed === 1 && child.ldh_test_result_checked === null;
        child.q36T2DPhoEmpty = child.pho_test_performed === 1 && child.pho_test_result_checked === null;
        child.q36T2DBPotEmpty = child.pot_test_performed === 1 && child.pot_test_result_checked === null;
        child.q36T2DUricEmpty = child.uric_test_performed === 1 && child.uric_test_result_checked === null;
      } else {
        child.q36T2DAlcEmpty = false;
        child.q36T2DCalEmpty = false;
        child.q36T2DSCEmpty = false;
        child.q36T2DCrclEmpty = false;
        child.q36T2DLdhEmpty = false;
        child.q36T2DPhoEmpty = false;
        child.q36T2DBPotEmpty = false;
        child.q36T2DUricEmpty = false;
        child.q36T2DAllUnknownEmpty = false;
        child.q36T2DAlcResultRange = false;
        child.q36T2BCalResultRange = false;
        child.q36T2BSCResultRange = false;
        child.q36T2BCrclResultRange = false;
        child.q36T2BLdhResultRange = false;
        child.q36T2BPhoResultRange = false;
        child.q36T2BPotResultRange = false;
        child.q36T2BUricResultRange = false;
      }

      if ((child.alc_test_result_checked === 1 && child.alc_result === null) ||
        (child.cal_test_result_checked === 1 && child.cal_test_result === null) ||
        (child.sc_test_result_checked === 1 && child.sc_test_result === null) ||
        (child.crcl_test_result_checked === 1 && child.crcl_test_resul === null) ||
        (child.ldh_test_result_checked === 1 && child.ldh_test_resul === null) ||
        (child.pho_test_result_checked === 1 && child.pho_test_result === null) ||
        (child.pot_test_result_checked === 1 && child.pot_test_result === null) ||
        (child.uric_test_result_checked === 1 && child.uric_test_result === null)) {
        result = false;
        child.q36T2DAlcResultEmpty = child.alc_test_performed === 1 && child.alc_test_result_checked === null;
        child.q36T2DCalResultEmpty = child.cal_test_performed === 1 && child.cal_test_result_checked === null;
        child.q36T2DSCResultEmpty = child.sc_test_performed === 1 && child.sc_test_result_checked === null;
        child.q36T2DCrclResultEmpty = child.crcl_test_performed === 1 && child.crcl_test_result_checked === null;
        child.q36T2DLdhResultEmpty = child.ldh_test_performed === 1 && child.ldh_test_result_checked === null;
        child.q36T2DPhoResultEmpty = child.pho_test_performed === 1 && child.pho_test_result_checked === null;
        child.q36T2DBPotResultEmpty = child.pot_test_performed === 1 && child.pot_test_result_checked === null;
        child.q36T2DUricResultEmpty = child.uric_test_performed === 1 && child.uric_test_result_checked === null;
      } else {
        child.q36T2DAlcResultEmpty = false;
        child.q36T2DCalResultEmpty = false;
        child.q36T2DSCResultEmpty = false;
        child.q36T2DCrclResultEmpty = false;
        child.q36T2DLdhResultEmpty = false;
        child.q36T2DPhoResultEmpty = false;
        child.q36T2DBPotResultEmpty = false;
        child.q36T2DUricResultEmpty = false;
        child.q36T2DAllUnknownEmpty = false;
        child.q36T2DAlcResultRange = false;
        child.q36T2BCalResultRange = false;
        child.q36T2BSCResultRange = false;
        child.q36T2BCrclResultRange = false;
        child.q36T2BLdhResultRange = false;
        child.q36T2BPhoResultRange = false;
        child.q36T2BPotResultRange = false;
        child.q36T2BUricResultRange = false;
      }
    }

    return result;
  }


  //Q36
  const HandleQ36RepeaterFieldChange = (e, parentIdx, childIdx) => {
    const { name, id, value } = e.target;
    const value_int = Number(value);
    let updatedArray = [...bloodTestRowData];

    switch (name) {
      //Radio Q35 
      case "bloodTestCompletedCount_checked_" + parentIdx: {
        updatedArray[parentIdx].bloodtest_count_checked = 1;
        updatedArray[parentIdx].bloodtest_count_unk = null;
        updatedArray[parentIdx].bloodTestCompleteEmpty = false;
        updatedArray[parentIdx].bloodTestCountEmpty = false;
      }
        break;
      case "bloodTestCompletedCount_" + parentIdx: {
        updatedArray[parentIdx].bloodtest_count = value_int;
        updatedArray[parentIdx].bloodtest_count_checked = 1;
        updatedArray[parentIdx].bloodtest_count_unk = null;
        updatedArray[parentIdx].bloodTestCompleteEmpty = false;
        updatedArray[parentIdx].bloodTestCountEmpty = false;
        //Set Empty Child list
        const newDetailsArray = Array.from({ length: value_int || 0 }, (_, j) => ({
          // Q36
          tlsbloodtestdetailsbridgeid: null,

          // Col A
          blooddraw_date: null,
          blooddraw_date_checked: null,
          blooddraw_date_unk: null,
          q36T1AEmpty: false,
          q36T1ADateEmpty: false,
          q36T1ADateCheckError: false,
          q36T1ADateVenCheckError: false,
          q36T1AInvalidDtError: false,

          // Col B
          reasonrepeattest: null,
          confirm_lab_hemolyzed: null,
          post_hemolyzed: null,
          inconclusive_hemolyzed: "",
          q36T1BEmpty: false,
          q36T1BSubValueEmpty: false,

          // Col C
          timeofblooddraw: null,
          blooddraw_oth_time: null,
          q36T1CEmpty: false,
          q36T1CTimeEmpty: false,
          q36T1CHrsEmpty: false,
          q36T1CMinsEmpty: false,

          // Col D
          testsetting: null,
          q36T1DEmpty: false,

          // Col E
          tls_laboratory: null,
          tls_clinical: null,
          tls_neither: null,
          tls_unk: null,
          q36T1EEmpty: false,

          // Col F
          intervention_given: null,
          yes_dosehold: null,
          yes_ivhydration: null,
          yes_metabolic: null,
          yes_oth: null,
          yes_oth_txt: null,
          q36T1FEmpty: false,
          q36T1FChildEmpty: false,
          q36T1FOtherTxtEmpty: false,

          // T2 - Alc
          alc_test_performed: null,
          alc_test_result_checked: null,
          alc_formula_type: null,
          alc_formula_type_oth: null,
          alc_test_result: null,
          alc_test_result_unk: null,
          q36T2BAlcEmpty: false,
          q36T2BAlcAllUnkNotTestedEmpty: false,
          q36T2DAlcEmpty: false,
          q36T2DAlcResultEmpty: false,
          q36T2DAlcAllUnknownEmpty: false,
          q36T2DAlcResultRange: false,

          // T2 - Calcium
          cal_test_performed: null,
          cal_test_result_checked: null,
          cal_formula_type: null,
          cal_formula_type_oth: null,
          cal_test_result: null,
          cal_test_result_unk: null,
          q36T2BCalEmpty: false,
          q36T2BCalAllUnkNotTestedEmpty: false,
          q36T2DCalEmpty: false,
          q36T2DCalResultEmpty: false,
          q36T2DCalAllUnknownEmpty: false,
          q36T2DCalResultRange: false,

          // T2 - Serum Creatinine
          sc_test_performed: null,
          sc_test_result_checked: null,
          sc_formula_type: null,
          sc_formula_type_oth: null,
          sc_test_result: null,
          sc_test_result_unk: null,
          q36T2BScEmpty: false,
          q36T2BScAllUnkNotTestedEmpty: false,
          q36T2DScEmpty: false,
          q36T2DScResultEmpty: false,
          q36T2DScAllUnknownEmpty: false,
          q36T2DScResultRange: false,

          // T2 - Creatinine Clearance Level
          crcl_test_performed: null,
          crcl_test_result_checked: null,
          crcl_formula_type: null,
          crcl_formula_type_oth: null,
          crcl_test_result: null,
          crcl_test_result_unk: null,
          q36T2BCrclEmpty: false,
          q36T2CCrclEmpty: false,
          q36T2CCrclOthTxtEmpty: false,
          q36T2DCrclEmpty: false,
          q36T2DCrclResultEmpty: false,
          q36T2DCrclAllUnknownEmpty: false,
          q36T2BCrclAllUnkNotTestedEmpty: false,
          q36T2DCrclResultRange: false,

          // T2 - Serum Lactate Dehydrogenase
          ldh_test_performed: null,
          ldh_test_result_checked: null,
          ldh_formula_type: null,
          ldh_formula_type_oth: null,
          ldh_test_result: null,
          ldh_test_result_unk: null,
          q36T2BLdhEmpty: false,
          q36T2BLdhAllUnkNotTestedEmpty: false,
          q36T2DLdhEmpty: false,
          q36T2DLdhResultEmpty: false,
          q36T2DLdhAllUnknownEmpty: false,
          q36T2DLdhResultRange: false,

          // T2 - Phosphorus
          pho_test_performed: null,
          pho_test_result_checked: null,
          pho_formula_type: null,
          pho_formula_type_oth: null,
          pho_test_result: null,
          pho_test_result_unk: null,
          q36T2BPhoEmpty: false,
          q36T2BPhoAllUnkNotTestedEmpty: false,
          q36T2DPhoEmpty: false,
          q36T2DPhoResultEmpty: false,
          q36T2DPhoAllUnknownEmpty: false,
          q36T2DPhoResultRange: false,

          // T2 - Potassium
          pot_test_performed: null,
          pot_test_result_checked: null,
          pot_formula_type: null,
          pot_formula_type_oth: null,
          pot_test_result: null,
          pot_test_result_unk: null,
          q36T2BPotEmpty: false,
          q36T2BPotAllUnkNotTestedEmpty: false,
          q36T2DPotEmpty: false,
          q36T2DPotResultEmpty: false,
          q36T2DPotAllUnknownEmpty: false,
          q36T2DPotResultRange: false,

          // T2 - Uric Acid
          uric_test_performed: null,
          uric_test_result_checked: null,
          uric_formula_type: null,
          uric_formula_type_oth: null,
          uric_test_result: null,
          uric_test_result_unk: null,
          q36T2BUricEmpty: false,
          q36T2BUricAllUnkNotTestedEmpty: false,
          q36T2DUricEmpty: false,
          q36T2DUricResultEmpty: false,
          q36T2DUricAllUnknownEmpty: false,
          q36T2DUricResultRange: false,
        }));

        // Update the array at the specific parent index
        updatedArray[parentIdx].bloodTestDetails = newDetailsArray;
      }
        break;
      case "bloodTestCompletedCount_Unk_" + parentIdx: {
        updatedArray[parentIdx].bloodtest_count_unk = 99;
        updatedArray[parentIdx].bloodtest_count_checked = null;
        updatedArray[parentIdx].bloodtest_count = null;
        updatedArray[parentIdx].bloodTestCompleteEmpty = false;
        updatedArray[parentIdx].bloodTestCountEmpty = false;
        updatedArray[parentIdx].bloodTestDetails = [];
      }
        break;


      //Radio Q36 colA
      case `blood_draw_date_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_date_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_date_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1AEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1ADateEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1ADateCheckError = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1ADateVenCheckError = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1AInvalidDtError = false;

      }
        break;
      case `blood_draw_date_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_date_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_date_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_date = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1AEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1ADateEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1ADateCheckError = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1ADateVenCheckError = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1AInvalidDtError = false;
      }
        break;

      //Radio Q36 colB
      case `repeat_labtest_1_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_2_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_3_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_4_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].reasonrepeattest = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].confirm_lab_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].post_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].inconclusive_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BSubValueEmpty = false;
      }
        break;
      case `repeat_labtest_labhemmo_1_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_labhemmo_2_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_labhemmo_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].reasonrepeattest = 2;
        updatedArray[parentIdx].bloodTestDetails[childIdx].confirm_lab_hemolyzed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].post_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].inconclusive_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BSubValueEmpty = false;
      }
        break;
      case `repeat_labtest_posthemmo_1_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_posthemmo_2_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_posthemmo_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].reasonrepeattest = 3;
        updatedArray[parentIdx].bloodTestDetails[childIdx].confirm_lab_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].post_hemolyzed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].inconclusive_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BSubValueEmpty = false;
      }
        break;
      case `repeat_labtest_inhemmo_1_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_inhemmo_2_${parentIdx}_of_${childIdx}`:
      case `repeat_labtest_inhemmo_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].reasonrepeattest = 4;
        updatedArray[parentIdx].bloodTestDetails[childIdx].confirm_lab_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].post_hemolyzed = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].inconclusive_hemolyzed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1BSubValueEmpty = false;
      }
        break;

      //Radio Q36 colC
      case `blooddraw_time_1_${parentIdx}_of_${childIdx}`:
      case `blooddraw_time_2_${parentIdx}_of_${childIdx}`:
      case `blooddraw_time_3_${parentIdx}_of_${childIdx}`:
      case `blooddraw_time_4_${parentIdx}_of_${childIdx}`:
      case `blooddraw_time_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].timeofblooddraw = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CTimeEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CHrsEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CMinsEmpty = false;

        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time_hr = "";
        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time_min = ""
      }
        break;

      case `blooddraw_oth_time_hr_${parentIdx}_of_${childIdx}`: {
        // const hoursValue = value === "" ? "" : Math.min(value, 24).toString();

        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time_hr = value > 23 ? "23" : value;;
        updatedArray[parentIdx].bloodTestDetails[childIdx].timeofblooddraw = 4;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CTimeEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CHrsEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CMinsEmpty = false;

        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time_min = value === "23" &&
          updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time_min > "59" ? "59" :
          updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time_min;
      }
        break;

      case `blooddraw_oth_time_min_${parentIdx}_of_${childIdx}`: {
        // const minutesValue = value === "" ? "" : Math.min(value, 59).toString();

        updatedArray[parentIdx].bloodTestDetails[childIdx].blooddraw_oth_time_min = value > 59 ? "59" : value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].timeofblooddraw = 4;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CTimeEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CHrsEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1CMinsEmpty = false;
      }
        break;


      //Radio Q36 colD
      case `tls_settings_1_${parentIdx}_of_${childIdx}`:
      case `tls_settings_2_${parentIdx}_of_${childIdx}`:
      case `tls_settings_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].testsetting = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1DEmpty = false;
      }
        break;

      //Radio Q36 colE
      case `tls_laboratory_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_laboratory = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_neither = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_unk = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1EEmpty = false;
      }
        break;
      case `tls_clinical_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_clinical = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_neither = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_unk = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1EEmpty = false;
      }
        break;
      case `tls_neither_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_neither = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_laboratory = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_clinical = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_unk = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1EEmpty = false;
      }
        break;
      case `tls_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_unk = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_laboratory = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_clinical = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].tls_neither = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1EEmpty = false;
      }
        break;

      //Radio Q36 colF
      case `intervention_given_1_${parentIdx}_of_${childIdx}`:
      case `intervention_given_2_${parentIdx}_of_${childIdx}`:
      case `intervention_given_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].intervention_given = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_dosehold = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_ivhydration = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_metabolic = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth_txt = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FChildEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FOtherTxtEmpty = false;
      }
        break;
      case `yes_dosehold_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].intervention_given = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_dosehold = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_ivhydration = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_metabolic = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth_txt = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FChildEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FOtherTxtEmpty = false;
      }
        break;
      case `yes_ivhydration_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].intervention_given = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_dosehold = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_ivhydration = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_metabolic = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth_txt = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FChildEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FOtherTxtEmpty = false;
      }
        break;
      case `yes_metabolic_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].intervention_given = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_dosehold = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_ivhydration = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_metabolic = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth_txt = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FChildEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FOtherTxtEmpty = false;
      }
        break;
      case `yes_oth_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].intervention_given = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_dosehold = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_ivhydration = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_metabolic = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth_txt = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FChildEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FOtherTxtEmpty = false;
      }
        break;
      case `yes_oth_txt_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].intervention_given = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_dosehold = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_ivhydration = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_metabolic = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth = true;
        updatedArray[parentIdx].bloodTestDetails[childIdx].yes_oth_txt = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FChildEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T1FOtherTxtEmpty = false;
      }
        break;

      //Radio Q36 Table2 ALC
      case `alc_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `alc_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `alc_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BAlcEmpty = false;
      }
        break;
      case `alc_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcEmpty = false;
      }
        break;
      case `alc_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcEmpty = false;
        if (parseFloat(value) < 0 || parseFloat(value) > 50) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', '109 /L');
          setQ36ResultAlcOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcResultRange = false;
        }
      }
        break;
      case `alc_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].alc_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAlcEmpty = false;
      }
        break;

      //Radio Q36 Table2 Calcium
      case `cal_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `cal_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `cal_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BCalEmpty = false;
      }
        break;
      case `cal_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalEmpty = false;
      }
        break;
      case `cal_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalEmpty = false;
        if (parseFloat(value) < 0 || parseFloat(value) > 14) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', 'mg/dL');
          setQ36ResultCalOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalResultRange = false;
        }
      }
        break;
      case `cal_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].cal_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCalEmpty = false;
      }
        break;

      //Radio Q36 Table2 Serum creatinine
      case `sc_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `sc_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `sc_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BScEmpty = false;
      }
        break;
      case `sc_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScEmpty = false;
      }
        break;
      case `sc_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScEmpty = false;

        if (parseFloat(value) < 1 || parseFloat(value) > 1.35) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', 'mg/dL');
          setQ36ResultScOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScResultRange = false;
        }
      }
        break;
      case `sc_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].sc_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DScEmpty = false;
      }
        break;

      //Radio Q36 Table2 Creatinine clearance level
      case `crcl_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `crcl_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `crcl_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BCrclEmpty = false;
      }
        break;
      case `crcl_formula_type_1_${parentIdx}_of_${childIdx}`:
      case `crcl_formula_type_2_${parentIdx}_of_${childIdx}`:
      case `crcl_formula_type_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_formula_type = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_formula_type_oth = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2CCrclEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2CCrclOthTxtEmpty = false;
      }
        break;
      case `crcl_formula_type_oth_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_formula_type = 2;
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_formula_type_oth = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2CCrclEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2CCrclOthTxtEmpty = false;
      }
        break;
      case `crcl_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclEmpty = false;
      }
        break;
      case `crcl_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclEmpty = false;
        if (parseInt(value) < 0 || parseInt(value) > 120) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', 'mL/min');
          setQ36ResultCrclOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclResultRange = false;
        }
      }
        break;
      case `crcl_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].crcl_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DCrclEmpty = false;
      }
        break;

      //Radio Q36 Table2 Serum lactate dehydrogenase 
      case `ldh_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `ldh_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `ldh_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BLdhEmpty = false;
      }
        break;
      case `ldh_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhEmpty = false;
      }
        break;
      case `ldh_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhEmpty = false;
        if (parseInt(value) < 0 || parseInt(value) > 1000) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', 'U/L');
          setQ36ResultLdhOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhResultRange = false;
        }
      }
        break;
      case `ldh_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].ldh_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DLdhEmpty = false;
      }
        break;

      //Radio Q36 Table2 Phosphorus
      case `pho_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `pho_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `pho_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BPhoEmpty = false;
      }
        break;
      case `pho_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoEmpty = false;
      }
        break;
      case `pho_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoEmpty = false;
        if (parseInt(value) < 0 || parseInt(value) > 5) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', 'mg/dL');
          setQ36ResultPhoOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoResultRange = false;
        }
      }
        break;
      case `pho_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pho_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPhoEmpty = false;
      }
        break;

      //Radio Q36 Table2 Potassium
      case `pot_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `pot_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `pot_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BPotEmpty = false;
      }
        break;
      case `pot_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotEmpty = false;
      }
        break;
      case `pot_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotEmpty = false;
        if (parseInt(value) < 0 || parseInt(value) > 10) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', 'mmol/L');
          setQ36ResultPotOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotResultRange = false;
        }
      }
        break;
      case `pot_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].pot_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DPotEmpty = false;
      }
        break;

      //Radio Q36 Table2 Uric acid
      case `uric_test_performed_1_${parentIdx}_of_${childIdx}`:
      case `uric_test_performed_2_${parentIdx}_of_${childIdx}`:
      case `uric_test_performed_99_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_performed = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BUricEmpty = false;
      }
        break;
      case `uric_test_result_checked_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result_checked = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricEmpty = false;
      }
        break;
      case `uric_test_result_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result = value;
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result_unk = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricEmpty = false;
        if (parseInt(value) < 0 || parseInt(value) > 10) {
          let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
          resultRange = resultRange.replace('[value units]', 'mg/dL');
          setQ36ResultUricOutOfRange(resultRange);
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricResultRange = true;
        }
        else {
          updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricResultRange = false;
        }
      }
        break;
      case `uric_test_result_unk_${parentIdx}_of_${childIdx}`: {
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result_checked = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result = null;
        updatedArray[parentIdx].bloodTestDetails[childIdx].uric_test_result_unk = value_int;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DUricEmpty = false;
      }
        break;
      default:
        break;

    }

    //Col B Soft Error
    if (childIdx != null) {
      let loopData = updatedArray[parentIdx].bloodTestDetails[childIdx];
      if ((loopData.alc_test_performed === 2 || loopData.alc_test_performed === 99) &&
        (loopData.cal_test_performed === 2 || loopData.cal_test_performed === 99) &&
        (loopData.sc_test_performed === 2 || loopData.sc_test_performed === 99) &&
        (loopData.crcl_test_performed === 2 || loopData.crcl_test_performed === 99) &&
        (loopData.ldh_test_performed === 2 || loopData.ldh_test_performed === 99) &&
        (loopData.pho_test_performed === 2 || loopData.pho_test_performed === 99) &&
        (loopData.pot_test_performed === 2 || loopData.pot_test_performed === 99) &&
        (loopData.uric_test_performed === 2 || loopData.uric_test_performed === 99)) {
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BAllUnkNotTestedEmpty = true;
      }
      else {
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2BAllUnkNotTestedEmpty = false;
      }

      //Col D Soft Error
      if (loopData.alc_test_result_unk === 99 && loopData.cal_test_result_unk === 99 && loopData.sc_test_result_unk === 99 &&
        loopData.crcl_test_result_unk === 99 && loopData.ldh_test_result_unk === 99 && loopData.pho_test_result_unk === 99 &&
        loopData.pot_test_result_unk === 99 && loopData.uric_test_result_unk === 99) {
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAllUnknownEmpty = true;
      }
      else {
        updatedArray[parentIdx].bloodTestDetails[childIdx].q36T2DAllUnknownEmpty = false;
      }
    }

    setBloodTestRowData([...updatedArray]);
  };

  //Q36
  const HandleQ36RepeaterDateChange = (name, date) => {
    const id = name.split('_').pop();
    const parsedDate = ParseDate(date);

    switch (name) {
      //32 
      case "blood_draw_date_" + id: {
        let updatedArray = [...bloodTestRowData];
        updatedArray[id].blooddraw_date = date;
        updatedArray[id].blooddraw_date_checked = 1;
        updatedArray[id].blooddraw_date_unk = null;
        updatedArray[id].q36T1AEmpty = false;
        updatedArray[id].q36T1ADateEmpty = false;
        updatedArray[id].q36T1AInvalidDtError = updatedArray[id].blooddraw_date === "InValid Date"

        //[If A (date) for N < A (date) for N – 1, then display the warning message (soft)]
        const nMinus1DrawDate = id > 0 ? updatedArray[id - 1].testperformed_dt : null;
        if (nMinus1DrawDate != null) {
          const drawDtLTNMinus1 = IsDateBefore(parsedDate, nMinus1DrawDate);
          if (drawDtLTNMinus1) {
            updatedArray[id].q36T1ADateCheckError = true;
          } else {
            updatedArray[id].q36T1ADateCheckError = false;
          }
        }

        //[If A (date) > Venetoclax End Date OR A (date) < Venetoclax Start Date, then display warning (soft)]
        const drawDtGTVenEDt = IsDateAfter(parsedDate, venetoclaxEndDate);
        if (drawDtGTVenEDt) {
          updatedArray[id].q36T1ADateVenCheckError = true;
        } else {
          updatedArray[id].q36T1ADateVenCheckError = false;
        }

        const drawDtLTVenSDt = IsDateBefore(parsedDate, venetoclaxStartDate);
        if (drawDtLTVenSDt) {
          updatedArray[id].q36T1ADateVenCheckError = true;
        } else {
          updatedArray[id].q36T1ADateVenCheckError = false;
        }

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }

      default:
        break;
    }
  };

  const restrictDecimalPlaces = (e) => {
    const value = e.target.value;

    // Allow numbers with at most one digit after the decimal point
    const regex = /^\d+(\.\d?)?$/;

    // If input is invalid, revert the last entered character
    if (!regex.test(value)) {
      e.target.value = value.slice(0, -1);
    }
  };

  const restrictToWholeNumbers = (e) => {
    const value = e.target.value;
    // Allow only whole numbers (integers)
    if (!/^\d*$/.test(value)) {
      e.target.value = value.slice(0, -1); // Remove the last invalid character
    }
  };

  const preventDecimalInput = (e) => {
    if (e.key === '.' || e.key === ',') {
      e.preventDefault();  // Prevent input of decimal points
    }
  };

  // Handle change in the input field
  const HandleFieldChange = (e) => {
    const { name, id, value } = e.target;
    const value_int = Number(value);

    switch (name) {
      case "radiographic_evaluations": {
        setTlsmonitoringDetails((prev) => ({
          ...prev,
          [name]: value_int,
          radiographic_evaluations_txt_value: "",
        }));

        setHardErrList((prev) => ({
          ...prev,
          radiographic_evaluationsEmptyHard: false,
          radiographic_evaluationsTxtEmptyHard: false,

        }));


        if (!value_int || value_int === 99) {
          setRadiographiceval_lymphDetails([]); // Clear all rows if value is removed
        }
        break;
      }


      case "radiographic_evaluations_txt_value": {

        setTlsmonitoringDetails((prev) => ({
          ...prev,
          [name]: value,
          radiographic_evaluations: 1,
        }));
        setHardErrList((prev) => ({
          ...prev,
          radiographic_evaluationsEmptyHard: false,
          radiographic_evaluationsTxtEmptyHard: false,
        }))

        setSoftErrList((prev) => ({
          ...prev,
          radiographic_evaluationsSoft: value_int > 5
        }))

        if (value_int > 5) {
          setRadiographic_evaluationsRangeError_msg(
            TLSMonitoring_Msgs.InvalidRange + value + TLSMonitoring_Msgs.InvalidRangeEmptyRemining
          );
        } else {
          setRadiographic_evaluationsRangeError_msg("");
        }


        const maxRows = 20;
        const numberOfRows = value_int > maxRows ? maxRows : value_int;

        // Reset radiographic evaluation rows if no value is provided
        if (!value_int) {
          setRadiographiceval_lymphDetails([]); // Reset all rows if value is removed
        } else {
          // If value exists, merge previous data and add new rows
          setRadiographiceval_lymphDetails((prev) => {
            // Copy previous rows up to the number specified
            const existingRows = prev.slice(0, numberOfRows);

            // Create new rows if needed
            const newRows = Array.from({ length: numberOfRows - existingRows.length }, (_, i) => ({
              ...LymphNodeRow,
              id: existingRows.length + i + 1, // Generate a unique ID for new rows
            }));

            return [...existingRows, ...newRows]; // Merge existing and new rows
          });
        }
        break;
      }

      case "testCompletedCount_checked": {
        setTlsmonitoringDetails((prev) => ({
          ...prev,
          [name]: value_int,
          testCompletedCount: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          testCompleteTxtEmptyHard: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          testCompleteEmptyHard: false,
          testCompleteTxtEmpty: false,
        }));

        if (!value_int || value_int === 99) {
          setAlc_CrclDetails([]); // Clear all rows if value is removed
        }

        break;
      }
      case "testCompletedCount": {
        setTlsmonitoringDetails((prev) => ({
          ...prev,
          [name]: value,
          testCompletedCount_checked: 1,
          testCompletedCount_unk: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          testCompleteEmptyHard: false,
          testCompleteTxtEmpty: false,
        }))

        setSoftErrList((prev) => ({
          ...prev,
          testCompleteTxtEmptyHard: value_int > 20
        }))

        if (value_int > 20) {
          let q33Msg = TLSMonitoring_Msgs.BloodTestCompleteCountEmptyMsg.replace('[value]', value_int);
          q33Msg = q33Msg.replace('[value units]', 'ALC/CrCl tests');
          setQ33ErrorMsg(q33Msg);
          setSoftErrList((prev) => ({
            ...prev,
            testCompleteTxtEmptyHard: true
          }))
        } else {
          setQ33ErrorMsg("");
          setSoftErrList((prev) => ({
            ...prev,
            testCompleteTxtEmptyHard: false
          }))

        }


        const maxRows = 20;
        const numberOfRows = value_int > maxRows ? maxRows : value_int;

        // Dynamically repeat question 34 rows if the value is valid and within range
        if (!value_int) {
          setAlc_CrclDetails([]); // Reset all rows if value is removed
        } else {
          // If value exists, merge previous data and add new rows
          setAlc_CrclDetails((prev) => {
            // Copy previous rows up to the number specified
            const existingRows = prev.slice(0, numberOfRows);

            // Create new rows if needed
            const newRows = Array.from({ length: numberOfRows - existingRows.length }, (_, i) => ({
              ...AlcCrclRow,
              id: existingRows.length + i + 1, // Generate a unique ID for new rows
            }));

            return [...existingRows, ...newRows]; // Merge existing and new rows
          });
        }

        break;
      }

    }
  }


  const HandleRepeaterFieldChange = (e) => {
    const { name, id, value } = e.target;
    const value_int = Number(value);
    switch (name) {


      //Radio Q32 col1
      case "lymphNodetestTypeRd" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].lymphNodetestTypeRd = value_int;
        updatedArray[id].lymphNodetestTypeEmptyHard = false;

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }

      //Radio Q32 col2
      case "testtodetermineRd" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].testtodetermineRd = value_int;
        updatedArray[id].testtodetermineEmptyHard = false;

        if (value_int === 2 || value_int === 99) {

          //col 3
          updatedArray[id].testperformedRd = null;
          updatedArray[id].testperformed_dt = "";
          updatedArray[id].testperformedEmptyHard = false;
          updatedArray[id].testperformedDateEmptyHard = false;

          //col 4
          updatedArray[id].timetestperformedRd = null;
          updatedArray[id].timetestperformedHrsTxt = "";
          updatedArray[id].timetestperformedMinTxt = "";
          updatedArray[id].timetestperformedEmptyHard = false;
          updatedArray[id].timetestperformedHrsEmptyHard = false;
          updatedArray[id].timetestperformedMinEmptyHard = false;

          //col 5
          updatedArray[id].lymphNode_sizeRd = null;
          updatedArray[id].lymphNode_sizeTxt = "";
          updatedArray[id].lymphNode_sizeEmptyHard = false;
          updatedArray[id].lymphNode_sizeTxtEmptyHard = false;

          // col 6
          updatedArray[id].lymphNodegreaterthanfiveRd = null;
          updatedArray[id].lymphNodegreaterthanfiveTxt = "";
          updatedArray[id].lymphNodegreaterthanfiveEmptyHard = false;
          updatedArray[id].lymphNodegreaterthanfiveTxtEmptyHard = false;

          updatedArray[id].lymphNode_sizeTxtSoft = false;
          updatedArray[id].lymphNodegreaterthanfiveTxtSoft = false;
        }

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      //Radio Q32 col3
      case "testperformedRd" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].testperformedRd = value_int;
        updatedArray[id].testperformed_dt = "";
        updatedArray[id].testperformedEmptyHard = false;
        updatedArray[id].testperformedDateEmptyHard = false;
        updatedArray[id].testperformed_dtSoft = false;
        updatedArray[id].testperformed_dtInvalid = false;


        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      //Radio Q32 col4
      case "timetestperformedRd" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].timetestperformedRd = value_int;


        updatedArray[id].timetestperformedHrsTxt = "";
        updatedArray[id].timetestperformedMinTxt = "";
        updatedArray[id].timetestperformedEmptyHard = false;
        updatedArray[id].timetestperformedHrsEmptyHard = false;
        updatedArray[id].timetestperformedMinEmptyHard = false;
        updatedArray[id].timetestperformedHrsMinEmptyHard = false;

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      //Radio Q32 col4 HrsTxt
      case "timetestperformedHrsTxt" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];

        // const hoursValue = Math.min(value, 24).toString();
        // const hoursValue = value === "" ? "" : Math.min(value, 24).toString();

        updatedArray[id].timetestperformedHrsTxt = value > 23 ? "23" : value;

        updatedArray[id].timetestperformedRd = 1;

        updatedArray[id].timetestperformedEmptyHard = false;
        updatedArray[id].timetestperformedHrsEmptyHard = false;
        updatedArray[id].timetestperformedMinEmptyHard = false;
        updatedArray[id].timetestperformedHrsMinEmptyHard = false;

        updatedArray[id].timetestperformedMinTxt = value === "23" && updatedArray[id].timetestperformedMinTxt > "59" ? "59" : updatedArray[id].timetestperformedMinTxt


        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      //Radio Q32 col4 MinTxt
      case "timetestperformedMinTxt" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        // const minutesValue = Math.min(value, 59).toString();;
        // const minutesValue = value === "" ? "" : Math.min(value, 59).toString(); // Set to empty string if input is "0"

        updatedArray[id].timetestperformedMinTxt = value > 59 ? "59" : value;


        updatedArray[id].timetestperformedRd = 1;
        updatedArray[id].timetestperformedEmptyHard = false;
        updatedArray[id].timetestperformedHrsEmptyHard = false;
        updatedArray[id].timetestperformedMinEmptyHard = false;
        updatedArray[id].timetestperformedHrsMinEmptyHard = false;

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      //col5
      case "lymphNode_sizeRd" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].lymphNode_sizeRd = value_int;

        updatedArray[id].lymphNode_sizeTxt = "";
        updatedArray[id].lymphNode_sizeEmptyHard = false;
        updatedArray[id].lymphNode_sizeTxtEmptyHard = false;

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }

      //col5 txt
      case "lymphNode_sizeTxt" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].lymphNode_sizeTxt = value;
        updatedArray[id].lymphNode_sizeRd = 1;
        updatedArray[id].lymphNode_sizeEmptyHard = false;
        updatedArray[id].lymphNode_sizeTxtEmptyHard = false;

        if (value_int > 20) {
          updatedArray[id].lymphNode_sizeTxtSoft = true;
        } else {
          updatedArray[id].lymphNode_sizeTxtSoft = false;
        }

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      //col6
      case "lymphNodegreaterthanfiveRd" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].lymphNodegreaterthanfiveRd = value_int;

        updatedArray[id].lymphNodegreaterthanfiveTxt = "";
        updatedArray[id].lymphNodegreaterthanfiveEmptyHard = false;
        updatedArray[id].lymphNodegreaterthanfiveTxtEmptyHard = false;

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      //col6 txt
      case "lymphNodegreaterthanfiveTxt" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].lymphNodegreaterthanfiveTxt = value;
        updatedArray[id].lymphNodegreaterthanfiveRd = 1;
        updatedArray[id].lymphNodegreaterthanfiveEmptyHard = false;
        updatedArray[id].lymphNodegreaterthanfiveTxtEmptyHard = false;



        if (value_int > 5) {
          updatedArray[id].lymphNodegreaterthanfiveTxtSoft = true;
        } else {
          updatedArray[id].lymphNodegreaterthanfiveTxtSoft = false;
        }

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }


      // 2nd loop section AlcCrcl

      //ALC 
      case "alc_testtodetermineVCRamp_up" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].alc_testtodetermineVCRamp_up = value_int;
        updatedArray[id].alctesttodetermineVCRampEmpty = false;

        if (value_int === 2 || value_int === 99) {

          updatedArray[id].alc_datetestperformed_dtRd = null;
          updatedArray[id].alc_datetestperformed_dt = "";
          updatedArray[id].alc_datetestperformed_dtSoft = false;

          updatedArray[id].alc_timetestperformedRd = null;
          updatedArray[id].alc_timetestHrsTxt = "";
          updatedArray[id].alc_timetestMinsTxt = "";

          updatedArray[id].alcTestperformedTime = null;

          updatedArray[id].alc_testresultRd = null;
          updatedArray[id].alctestresultTxt = "";
          updatedArray[id].alctestresultSoft = false;


          updatedArray[id].alctesttodetermineVCRampEmpty = false;
          updatedArray[id].alc_datetestperformed_dtRdEmpty = false;
          updatedArray[id].alc_datetestperformed_dtEmpty = false;

          updatedArray[id].alc_timetestperformedRdEmpty = false;

          updatedArray[id].alc_timetestperformedHrsEmpty = false;
          updatedArray[id].alc_timetestperformedMinsEmpty = false;
          updatedArray[id].alc_timetestperformedMinsHrsEmpty = false;
          updatedArray[id].alc_testresultRdEmpty = false;
          updatedArray[id].alc_testresultTxtEmpty = false;
        }

        setAlc_CrclDetails(updatedArray);
        break;
      }

      case "alc_datetestperformed_dtRd" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].alc_datetestperformed_dtRd = value_int;
        updatedArray[id].alc_datetestperformed_dt = "";
        updatedArray[id].alc_datetestperformed_dtSoft = false;
        updatedArray[id].alc_datetestperformed_dtRdEmpty = false;
        updatedArray[id].alc_datetestperformed_dtEmpty = false;
        updatedArray[id].alc_datetestperformedInvalid = false;

        setAlc_CrclDetails(updatedArray);
        break;
      }

      case "alc_timetestperformedRd" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].alc_timetestperformedRd = value_int;
        updatedArray[id].alc_timetestHrsTxt = ""
        updatedArray[id].alc_timetestMinsTxt = ""

        updatedArray[id].alc_timetestperformedRdEmpty = false;

        updatedArray[id].alc_timetestperformedHrsEmpty = false;
        updatedArray[id].alc_timetestperformedMinsEmpty = false;
        updatedArray[id].alc_timetestperformedMinsHrsEmpty = false;


        setAlc_CrclDetails(updatedArray);
        break;
      }





      case "alc_timetestHrsTxt" + id: {
        let updatedArray = [...alc_crclDetails];


        // const hoursValue = value === "" ? "" : Math.min(value, 24).toString();
        updatedArray[id].alc_timetestHrsTxt = value > 23 ? "23" : value;

        updatedArray[id].alc_timetestperformedRd = 1;

        updatedArray[id].alc_timetestperformedRdEmpty = false;

        updatedArray[id].alc_timetestperformedHrsEmpty = false;
        updatedArray[id].alc_timetestperformedMinsEmpty = false;
        updatedArray[id].alc_timetestperformedMinsHrsEmpty = false;

        updatedArray[id].alc_timetestMinsTxt = value === "23" && updatedArray[id].alc_timetestMinsTxt > "59" ? "59" : updatedArray[id].alc_timetestMinsTxt


        setAlc_CrclDetails(updatedArray);
        break;
      }



      case "alc_timetestMinsTxt" + id: {
        let updatedArray = [...alc_crclDetails];
        // const minutesValue = value === "" ? "" : Math.min(value, 59).toString(); // Set to empty string if input is "0"

        updatedArray[id].alc_timetestMinsTxt = value > 59 ? "59" : value;


        updatedArray[id].alc_timetestperformedRd = 1;
        updatedArray[id].alc_timetestperformedRdEmpty = false;

        updatedArray[id].alc_timetestperformedHrsEmpty = false;
        updatedArray[id].alc_timetestperformedMinsEmpty = false;
        updatedArray[id].alc_timetestperformedMinsHrsEmpty = false;

        setAlc_CrclDetails(updatedArray);
        break;
      }


      case "alc_testresultRd" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].alc_testresultRd = value_int;
        updatedArray[id].alctestresultTxt = "";
        updatedArray[id].alctestresultSoft = false;

        updatedArray[id].alc_testresultRdEmpty = false;
        updatedArray[id].alc_testresultTxtEmpty = false;

        setAlc_CrclDetails(updatedArray);
        break;
      }

      case "alctestresultTxt" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].alctestresultTxt = value;
        updatedArray[id].alc_testresultRd = 1;
        updatedArray[id].alctestresultSoft = value_int > 50 ? true : false;

        updatedArray[id].alc_testresultRdEmpty = false;
        updatedArray[id].alc_testresultTxtEmpty = false;
        setAlc_CrclDetails(updatedArray);
        break;
      }



      //  Crcl
      case "crcl_testtodetermineVCRamp_up" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].crcl_testtodetermineVCRamp_up = value_int;
        updatedArray[id].crcltesttodetermineVCRampEmpty = false;



        if (value_int === 2 || value_int === 99) {

          updatedArray[id].crcl_datetestperformed_dtRd = null;
          updatedArray[id].crcl_datetestperformed_dt = "";
          updatedArray[id].crcl_datetestperformed_dtSoft = false;

          updatedArray[id].crcl_timetestperformedRd = null;
          updatedArray[id].crcl_timetestHrsTxt = "";
          updatedArray[id].crcl_timetestMinsTxt = "";

          updatedArray[id].crclTestperformedTime = null;

          updatedArray[id].crcl_testresultRd = null;
          updatedArray[id].crcl_testresultTxt = "";
          updatedArray[id].crcl_testresultSoft = false;


          updatedArray[id].crcltesttodetermineVCRampEmpty = false;
          updatedArray[id].crcl_datetestperformed_dtRdEmpty = false;
          updatedArray[id].crcl_datetestperformed_dtEmpty = false;

          updatedArray[id].crcl_timetestperformedRdEmpty = false;

          updatedArray[id].crcl_timetestperformedHrsEmpty = false;
          updatedArray[id].crcl_timetestperformedMinsEmpty = false;
          updatedArray[id].crcl_timetestperformedMinsHrsEmpty = false;
          updatedArray[id].crcl_testresultRdEmpty = false;
          updatedArray[id].crcl_testresultTxtEmpty = false;
        }

        setAlc_CrclDetails(updatedArray);
        break;
      }

      case "crcl_datetestperformed_dtRd" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].crcl_datetestperformed_dtRd = value_int;
        updatedArray[id].crcl_datetestperformed_dt = "";
        updatedArray[id].crcl_datetestperformed_dtSoft = false;

        updatedArray[id].crcl_datetestperformed_dtRdEmpty = false;
        updatedArray[id].crcl_datetestperformed_dtEmpty = false;
        updatedArray[id].crcl_datetestperformedInvalid = false;

        setAlc_CrclDetails(updatedArray);
        break;
      }

      case "crcl_timetestperformedRd" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].crcl_timetestperformedRd = value_int;
        updatedArray[id].crcl_timetestHrsTxt = ""
        updatedArray[id].crcl_timetestMinsTxt = ""


        updatedArray[id].crcl_timetestperformedRdEmpty = false;

        updatedArray[id].crcl_timetestperformedHrsEmpty = false;
        updatedArray[id].crcl_timetestperformedMinsEmpty = false;
        updatedArray[id].crcl_timetestperformedMinsHrsEmpty = false;

        setAlc_CrclDetails(updatedArray);
        break;
      }





      case "crcl_timetestHrsTxt" + id: {
        let updatedArray = [...alc_crclDetails];


        // const hoursValue = value === "" ? "" : Math.min(value, 24).toString();
        updatedArray[id].crcl_timetestHrsTxt = value > 23 ? "23" : value;

        updatedArray[id].crcl_timetestperformedRd = 1;

        updatedArray[id].crcl_timetestperformedRdEmpty = false;

        updatedArray[id].crcl_timetestperformedHrsEmpty = false;
        updatedArray[id].crcl_timetestperformedMinsEmpty = false;
        updatedArray[id].crcl_timetestperformedMinsHrsEmpty = false;

        updatedArray[id].crcl_timetestMinsTxt = value === "23" && updatedArray[id].crcl_timetestMinsTxt > "59" ? "59" : updatedArray[id].crcl_timetestMinsTxt


        setAlc_CrclDetails(updatedArray);
        break;
      }



      case "crcl_timetestMinsTxt" + id: {
        let updatedArray = [...alc_crclDetails];
        // const minutesValue = value === "" ? "" : Math.min(value, 59).toString(); // Set to empty string if input is "0"

        updatedArray[id].crcl_timetestMinsTxt = value > 59 ? "59" : value;


        updatedArray[id].crcl_timetestperformedRd = 1;

        updatedArray[id].crcl_timetestperformedRdEmpty = false;

        updatedArray[id].crcl_timetestperformedHrsEmpty = false;
        updatedArray[id].crcl_timetestperformedMinsEmpty = false;
        updatedArray[id].crcl_timetestperformedMinsHrsEmpty = false;

        setAlc_CrclDetails(updatedArray);
        break;
      }


      case "crcl_testresultRd" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].crcl_testresultRd = value_int;
        updatedArray[id].crcl_testresultTxt = "";
        updatedArray[id].crcl_testresultSoft = false;

        updatedArray[id].crcl_testresultRdEmpty = false;
        updatedArray[id].crcl_testresultTxtEmpty = false;

        setAlc_CrclDetails(updatedArray);
        break;
      }

      case "crcl_testresultTxt" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].crcl_testresultTxt = value;
        updatedArray[id].crcl_testresultRd = 1;
        updatedArray[id].crcl_testresultSoft = value_int > 120



        updatedArray[id].crcl_testresultRdEmpty = false;
        updatedArray[id].crcl_testresultTxtEmpty = false;
        setAlc_CrclDetails(updatedArray);
        break;
      }
      default:
        break;
    }
  };

  const HandleRepeaterDateChange = (name, date) => {
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);

    switch (name) {
      //32 
      case "testperformed_dt-" + id: {
        let updatedArray = [...radiographiceval_lymphDetails];
        updatedArray[id].testperformed_dt = date;
        updatedArray[id].testperformedRd = 1;
        updatedArray[id].testperformedEmptyHard = false;
        updatedArray[id].testperformedDateEmptyHard = false;
        updatedArray[id].testperformed_dtInvalid = false;

        if (!IsDateBetween(index_dt_minus_2mo, date, venetoclaxStartDate)) {
          updatedArray[id].testperformed_dtSoft = true;
        } else {
          updatedArray[id].testperformed_dtSoft = false;
        }

        setRadiographiceval_lymphDetails(updatedArray);
        break;
      }

      case "alc_datetestperformed_dt-" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].alc_datetestperformed_dt = date;
        updatedArray[id].alc_datetestperformed_dtEmpty = false;

        updatedArray[id].alc_datetestperformed_dtRd = 1;

        updatedArray[id].alc_datetestperformed_dtRdEmpty = false;
        updatedArray[id].alc_datetestperformedInvalid = false;


        if (!IsDateBetween(index_dt_minus_2mo, date, venetoclaxStartDate)) {
          updatedArray[id].alc_datetestperformed_dtSoft = true;
        } else {
          updatedArray[id].alc_datetestperformed_dtSoft = false;
        }

        setAlc_CrclDetails(updatedArray);
        break;
      }



      case "crcl_datetestperformed_dt-" + id: {
        let updatedArray = [...alc_crclDetails];
        updatedArray[id].crcl_datetestperformed_dt = date;

        updatedArray[id].crcl_datetestperformed_dtRd = 1;

        updatedArray[id].crcl_datetestperformed_dtEmpty = false;
        updatedArray[id].crcl_datetestperformed_dtRdEmpty = false;

        if (!IsDateBetween(index_dt_minus_2mo, date, venetoclaxStartDate)) {
          updatedArray[id].crcl_datetestperformed_dtSoft = true;
        } else {
          updatedArray[id].crcl_datetestperformed_dtSoft = false;
        }

        setAlc_CrclDetails(updatedArray);
        break;
      }
      default:
        break;
    }
  };


  const ValidateForm = () => {

    let isValid = true;

    // Validation for q31
    if (tlsmonitoringDetails.radiographic_evaluations === null) {
      setHardErrList((prev) => ({
        ...prev,
        radiographic_evaluationsEmptyHard: true,
      }));
      isValid = false;
    }



    //Validation for 31 txt
    if (tlsmonitoringDetails.radiographic_evaluations === 1 && tlsmonitoringDetails.radiographic_evaluations_txt_value === "") {
      setHardErrList((prev) => ({
        ...prev,
        radiographic_evaluationsTxtEmptyHard: true,
      }));
      isValid = false;
    }




    if (tlsmonitoringDetails.testCompletedCount_checked === null) {
      setHardErrList((prev) => ({
        ...prev,
        testCompleteEmptyHard: true,
      }));
      isValid = false;
    }

    if (tlsmonitoringDetails.testCompletedCount_checked === 1 && tlsmonitoringDetails.testCompletedCount === "") {
      setHardErrList((prev) => ({
        ...prev,
        testCompleteTxtEmpty: true,
      }));
      isValid = false;
    }



    //repeater section question 32 

    if (radiographiceval_lymphDetails && radiographiceval_lymphDetails.length > 0) {
      const updatedArray = radiographiceval_lymphDetails.map(item => {

        // col 1 
        let lymphNodetestTypeEmptyHard = false;
        if (tlsmonitoringDetails.radiographic_evaluations_txt_value > 0 && item.lymphNodetestTypeRd == null) {
          lymphNodetestTypeEmptyHard = true;
        }

        //  col 2
        let testtodetermineEmptyHard = false;
        if (tlsmonitoringDetails.radiographic_evaluations_txt_value && item.testtodetermineRd == null) {
          testtodetermineEmptyHard = true;
        }

        // col 3 Radio
        let testperformedEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.testperformedRd === null) {
          testperformedEmptyHard = true;
        }

        // col 3 date 
        let testperformedDateEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.testperformedRd === 1 && item.testperformed_dt === "") {
          testperformedDateEmptyHard = true;
        }


        let testperformed_dtInvalid = false;
        if (item.testtodetermineRd === 1 && item.testperformedRd === 1 && item.testperformed_dt !== "" && item.testperformed_dt === "Invalid Date") {
          testperformed_dtInvalid = true;
        }


        // col 4 radio 
        let timetestperformedEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.timetestperformedRd === null) {
          timetestperformedEmptyHard = true;
        }

        // col 4 hrs  validation 
        let timetestperformedHrsEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.timetestperformedRd === 1 && item.timetestperformedHrsTxt === "" && item.timetestperformedMinTxt !== "") {
          timetestperformedHrsEmptyHard = true;
        }

        // col 4 mins validation 
        let timetestperformedMinEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.timetestperformedRd === 1 && item.timetestperformedMinTxt === "" && item.timetestperformedHrsTxt !== "") {
          timetestperformedMinEmptyHard = true;
        }

        // col 4 hrs and mins empty
        let timetestperformedHrsMinEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.timetestperformedRd === 1 && item.timetestperformedMinTxt === "" && item.timetestperformedHrsTxt === "") {
          timetestperformedHrsMinEmptyHard = true;
        }


        // col 5 
        let lymphNode_sizeEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.lymphNode_sizeRd === null) {
          lymphNode_sizeEmptyHard = true;
        }
        // col 5 size text
        let lymphNode_sizeTxtEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.lymphNode_sizeRd === 1 && item.lymphNode_sizeTxt === "") {
          lymphNode_sizeTxtEmptyHard = true;
        }

        // col 6
        let lymphNodegreaterthanfiveEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.lymphNodegreaterthanfiveRd === null) {
          lymphNodegreaterthanfiveEmptyHard = true;
        }


        // col 6 txt
        let lymphNodegreaterthanfiveTxtEmptyHard = false;
        if (item.testtodetermineRd === 1 && item.lymphNodegreaterthanfiveRd === 1 && item.lymphNodegreaterthanfiveTxt === "") {
          lymphNodegreaterthanfiveTxtEmptyHard = true;
        }




        if (lymphNodetestTypeEmptyHard || testtodetermineEmptyHard || testperformedDateEmptyHard || timetestperformedHrsEmptyHard
          || timetestperformedMinEmptyHard || lymphNode_sizeEmptyHard || lymphNode_sizeTxtEmptyHard || lymphNodegreaterthanfiveEmptyHard || testperformedEmptyHard
          || timetestperformedEmptyHard || lymphNodegreaterthanfiveTxtEmptyHard || timetestperformedHrsMinEmptyHard || testperformed_dtInvalid
        ) {
          isValid = false;
        }
        return {
          ...item,
          lymphNodetestTypeEmptyHard,
          testtodetermineEmptyHard,

          testperformedEmptyHard,
          testperformedDateEmptyHard,

          timetestperformedEmptyHard,
          timetestperformedHrsEmptyHard,
          timetestperformedMinEmptyHard,

          lymphNode_sizeEmptyHard,
          lymphNode_sizeTxtEmptyHard,

          lymphNodegreaterthanfiveEmptyHard,
          lymphNodegreaterthanfiveTxtEmptyHard,
          timetestperformedHrsMinEmptyHard,
          testperformed_dtInvalid
        };
      });
      setRadiographiceval_lymphDetails(updatedArray);
    }

    //#endregion




    //repeater section question 34

    if (alc_crclDetails && alc_crclDetails.length > 0) {
      const updatedArray = alc_crclDetails.map(item => {


        //  col 2
        let alctesttodetermineVCRampEmpty = false;
        if (tlsmonitoringDetails.testCompletedCount > 0 && item.alc_testtodetermineVCRamp_up == null) {
          alctesttodetermineVCRampEmpty = true;
        }

        // col 3 Radio
        let alc_datetestperformed_dtRdEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_datetestperformed_dtRd === null) {
          alc_datetestperformed_dtRdEmpty = true;
        }

        // col 3 date 
        let alc_datetestperformed_dtEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_datetestperformed_dtRd === 1 && item.alc_datetestperformed_dt === "") {
          alc_datetestperformed_dtEmpty = true;
        }


        let alc_datetestperformedInvalid = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_datetestperformed_dtRd === 1 && item.alc_datetestperformed_dt !== ""
          && item.alc_datetestperformed_dt === "Invalid Date") {
          alc_datetestperformedInvalid = true;
        }

        // col 4 radio 
        let alc_timetestperformedRdEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_timetestperformedRd === null) {
          alc_timetestperformedRdEmpty = true;
        }

        // col 4 hrs  validation 
        let alc_timetestperformedHrsEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_timetestperformedRd === 1 && item.alc_timetestHrsTxt === "" && item.alc_timetestMinsTxt !== "") {
          alc_timetestperformedHrsEmpty = true;
        }

        // col 4 mins validation 
        let alc_timetestperformedMinsEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_timetestperformedRd === 1 && item.alc_timetestMinsTxt === "" && item.alc_timetestHrsTxt !== "") {
          alc_timetestperformedMinsEmpty = true;
        }

        // col 4 hrs and mins empty
        let alc_timetestperformedMinsHrsEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_timetestperformedRd === 1 && item.alc_timetestMinsTxt === "" && item.alc_timetestHrsTxt === "") {
          alc_timetestperformedMinsHrsEmpty = true;
        }


        // col 5 
        let alc_testresultRdEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_testresultRd === null) {
          alc_testresultRdEmpty = true;
        }

        // col 5 size text
        let alc_testresultTxtEmpty = false;
        if (item.alc_testtodetermineVCRamp_up === 1 && item.alc_testresultRd === 1 && item.alctestresultTxt === "") {
          alc_testresultTxtEmpty = true;
        }

        // crcl
        //  col 2
        let crcltesttodetermineVCRampEmpty = false;
        if (tlsmonitoringDetails.testCompletedCount > 0 && item.crcl_testtodetermineVCRamp_up == null) {
          crcltesttodetermineVCRampEmpty = true;
        }

        // col 3 Radio
        let crcl_datetestperformed_dtRdEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_datetestperformed_dtRd === null) {
          crcl_datetestperformed_dtRdEmpty = true;
        }

        // col 3 date 
        let crcl_datetestperformed_dtEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_datetestperformed_dtRd === 1 && item.crcl_datetestperformed_dt === "") {
          crcl_datetestperformed_dtEmpty = true;
        }


        let crcl_datetestperformedInvalid = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_datetestperformed_dtRd === 1 && item.crcl_datetestperformed_dt !== "" && item.crcl_datetestperformed_dt === "Invalid Date"
          && item.alc_datetestperformed_dt === "Invalid Date") {
          crcl_datetestperformedInvalid = true;
        }


        // col 4 radio 
        let crcl_timetestperformedRdEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_timetestperformedRd === null) {
          crcl_timetestperformedRdEmpty = true;
        }

        // col 4 hrs  validation 
        let crcl_timetestperformedHrsEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_timetestperformedRd === 1 && item.crcl_timetestHrsTxt === "" && item.crcl_timetestMinsTxt !== "") {
          crcl_timetestperformedHrsEmpty = true;
        }

        // col 4 mins validation 
        let crcl_timetestperformedMinsEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_timetestperformedRd === 1 && item.crcl_timetestMinsTxt === "" && item.crcl_timetestHrsTxt !== "") {
          crcl_timetestperformedMinsEmpty = true;
        }

        // col 4 hrs and mins empty
        let crcl_timetestperformedMinsHrsEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_timetestperformedRd === 1 && item.crcl_timetestMinsTxt === "" && item.crcl_timetestHrsTxt === "") {
          crcl_timetestperformedMinsHrsEmpty = true;
        }


        // col 5 
        let crcl_testresultRdEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_testresultRd === null) {
          crcl_testresultRdEmpty = true;
        }

        // col 5 size text
        let crcl_testresultTxtEmpty = false;
        if (item.crcl_testtodetermineVCRamp_up === 1 && item.crcl_testresultRd === 1 && item.crcl_testresultTxt === "") {
          crcl_testresultTxtEmpty = true;
        }



        if (
          alctesttodetermineVCRampEmpty || alc_datetestperformed_dtRdEmpty || alc_datetestperformed_dtEmpty || alc_timetestperformedRdEmpty || alc_timetestperformedHrsEmpty || alc_timetestperformedMinsEmpty
          || alc_timetestperformedMinsHrsEmpty || alc_testresultRdEmpty || alc_testresultTxtEmpty || alc_datetestperformedInvalid ||

          crcltesttodetermineVCRampEmpty || crcl_datetestperformed_dtRdEmpty || crcl_datetestperformed_dtEmpty || crcl_timetestperformedRdEmpty || crcl_timetestperformedHrsEmpty || crcl_timetestperformedMinsEmpty
          || crcl_timetestperformedMinsHrsEmpty || crcl_testresultRdEmpty || crcl_testresultTxtEmpty || crcl_datetestperformedInvalid
        ) {
          isValid = false;
        }
        return {
          ...item,
          alctesttodetermineVCRampEmpty,
          alc_datetestperformed_dtRdEmpty,
          alc_datetestperformed_dtEmpty,
          alc_timetestperformedRdEmpty,
          alc_timetestperformedHrsEmpty,
          alc_timetestperformedMinsEmpty,
          alc_timetestperformedMinsHrsEmpty,
          alc_testresultRdEmpty,
          alc_testresultTxtEmpty,
          alc_datetestperformedInvalid,

          crcltesttodetermineVCRampEmpty,
          crcl_datetestperformed_dtRdEmpty,
          crcl_datetestperformed_dtEmpty,
          crcl_timetestperformedRdEmpty,
          crcl_timetestperformedHrsEmpty,
          crcl_timetestperformedMinsEmpty,
          crcl_timetestperformedMinsHrsEmpty,
          crcl_testresultRdEmpty,
          crcl_testresultTxtEmpty,
          crcl_datetestperformedInvalid

        };
      });
      setAlc_CrclDetails(updatedArray);
    }
    //#endregion

    return isValid;
  };

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.TlsMonitoring} />

          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>TLS MONITORING</h4>
              </div>


              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">

                    <div className="question-bot-sapce single-dig-quest">
                      <br></br>
                      <div className="question question-weight">
                        <span>31.</span>
                        <span className="quest-text-pad">
                          Please indicate the total number of radiographic
                          evaluations completed for lymph node size between {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {index_dt_minus_2mo} </span> and <span
                              style={{ color: PageDesignConstants.fontColor }}
                            > {venetoclaxStartDate}. </span>
                        </span>
                      </div>
                      <div className="answer-pad-left">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="radiographic_evaluations_1"
                              name="radiographic_evaluations"
                              value={1}
                              checked={
                                tlsmonitoringDetails.radiographic_evaluations === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <input
                              className="input-dash input_custome-space_sm"
                              name="radiographic_evaluations_txt_value"
                              type="text"
                              maxLength={100}
                              value={tlsmonitoringDetails.radiographic_evaluations_txt_value}
                              onChange={HandleFieldChange}
                              onKeyDown={(e) => {
                                // Allow backspace, arrow keys, and delete
                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                  return;
                                }
                                // Prevent non-numeric characters
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                  return;
                                }
                                const value = e.target.value;
                                const selectionStart = e.target.selectionStart;
                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                // Prevent multiple '.'
                                if (e.key === '.' && value.includes('.')) {
                                  e.preventDefault();
                                  return;
                                }
                                // Prevent '.' as the first character
                                if (e.key === '.' && value === '') {
                                  e.preventDefault();
                                  return;
                                }
                                // Allow only one digit after decimal point
                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                  e.preventDefault();
                                  return;
                                }
                              }}
                            />
                            <span className="radio-text-padding">
                              radiographic evaluations
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label className="option-fbox custom-spacing-lbl">
                            <input
                              type="radio"
                              id="radiographic_evaluations_99"
                              name="radiographic_evaluations"
                              checked={
                                tlsmonitoringDetails.radiographic_evaluations === 99
                              }
                              value={99}
                              onChange={HandleFieldChange}
                            />

                            <span htmlFor="total_No_interruptions_99">
                              Unknown
                            </span>
                          </label>
                        </div>
                        {/* <ErrorField show={hardErrList.total_No_interruptionsEmptyHard} message={TLSMonitoring_Msgs.total_No_interruptionsEmpty} /> */}
                        <ErrorField show={softErrList.radiographic_evaluationsSoft} message={radiographic_evaluationsRangeError_msg} />
                        <ErrorField show={hardErrList.radiographic_evaluationsEmptyHard} message={TLSMonitoring_Msgs.Select} />
                        <ErrorField show={hardErrList.radiographic_evaluationsTxtEmptyHard} message={TLSMonitoring_Msgs.TextEmpty} />

                      </div>

                      <br />
                      {tlsmonitoringDetails.radiographic_evaluations_txt_value > 0 && (
                        <>
                          <div className="question question-weight">
                            <span>32.</span>
                            <span className="quest-text-pad">
                              Please indicate the following details for the [
                              {Math.min(tlsmonitoringDetails.radiographic_evaluations_txt_value, 20)},20]
                              radiographic evaluations completed for the patient’s
                              lymph node(s).{" "}
                            </span>
                          </div>
                        </>)}
                      {/* { tlsmonitoringDetails.radiographic_evaluations_txt_value > 0 && radiographiceval_lymphDetails && 
                      radiographiceval_lymphDetails.length > 0 &&
                      radiographiceval_lymphDetails.map((data, idx) => ( */}

                      <div className={tlsmonitoringDetails.radiographic_evaluations_txt_value > 0 ? "dependent-section" : "empty-dependent-section"} >
                        < div className="answer-pad-left grid-overflow">
                          <table className="modal-popup-table-xlarge">
                            {tlsmonitoringDetails.radiographic_evaluations_txt_value > 0 && (
                              <thead className="modal-popup-table-header">
                                <tr>
                                  <th className="modal-popup-table-header-cell">
                                    Test
                                  </th>
                                  <th className="modal-popup-table-header-cell column-custom-width-120">
                                    Test Type
                                  </th>
                                  <th className="modal-popup-table-header-cell">
                                    Was this test used to determine the venetoclax
                                    ramp-up process?{" "}
                                  </th>
                                  <th className="modal-popup-table-header-cell">
                                    Date test was performed
                                  </th>
                                  <th className="modal-popup-table-header-cell column-custom-width-245">
                                    Time test was performed
                                  </th>
                                  <th className="modal-popup-table-header-cell column-custom-width-120">
                                    Size of largest lymph node
                                  </th>
                                  <th className="modal-popup-table-header-cell">
                                    How many lymph nodes &gt; 5 cm were
                                    identified?
                                  </th>
                                </tr>
                              </thead>
                            )}
                            <tbody>
                              {tlsmonitoringDetails.radiographic_evaluations_txt_value > 0 && radiographiceval_lymphDetails &&
                                radiographiceval_lymphDetails.length > 0 &&
                                radiographiceval_lymphDetails.map((data, idx) => (
                                  <tr>
                                    <td className="modal-popup-table-cell">{idx + 1}</td>
                                    <td className="modal-popup-table-cell">
                                      <div className="option-without-input option-fbox">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"lymphNodetestTypeRd" + idx}
                                            checked={data.lymphNodetestTypeRd === 1}
                                            value={1}
                                            onChange={HandleRepeaterFieldChange}
                                          />

                                          <span className="radio-text-padding">CT scan</span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"lymphNodetestTypeRd" + idx}
                                            checked={data.lymphNodetestTypeRd === 2}
                                            value={2}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            MRI
                                          </span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"lymphNodetestTypeRd" + idx}
                                            checked={data.lymphNodetestTypeRd === 3}
                                            value={3}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            PET/CT
                                          </span>
                                        </label>
                                      </div>
                                      <ErrorField show={data.lymphNodetestTypeEmptyHard} message={TLSMonitoring_Msgs.Select} />
                                    </td>
                                    <td className="modal-popup-table-cell">
                                      <div className="option-without-input">
                                        <label>
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"testtodetermineRd" + idx}
                                            checked={data.testtodetermineRd === 1}
                                            value={1}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            Yes
                                          </span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label>
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"testtodetermineRd" + idx}
                                            checked={data.testtodetermineRd === 2}
                                            value={2}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            No
                                          </span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label>
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"testtodetermineRd" + idx}
                                            checked={data.testtodetermineRd === 99}
                                            value={99}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            Unknown
                                          </span>
                                        </label>
                                      </div>
                                      <ErrorField show={data.testtodetermineEmptyHard} message={TLSMonitoring_Msgs.Select} />

                                    </td>
                                    {data.testtodetermineRd === 1 ? (<>
                                      <td className="modal-popup-table-cell">
                                        <div className="date-field-padding option-fbox">
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"testperformedRd" + idx}
                                            value={1}
                                            checked={data.testperformedRd === 1}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          {/* <span className="radio-text-padding"> */}
                                          <DateControl
                                            ctrlId={"testperformed_dt-" + idx}
                                            HandleDateChange={HandleRepeaterDateChange}
                                            date={data.testperformed_dt}
                                            showUnkInfoTxt={false}
                                            locale="en"
                                            maxDate={dayjs()}
                                          />
                                        </div>
                                        <div className="option-without-input">
                                          <label >
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"testperformedRd" + idx}
                                              checked={data.testperformedRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />

                                            <span className="radio-text-padding">
                                              Unknown
                                            </span>
                                          </label>
                                          <ErrorField
                                            show={data.testperformed_dtSoft}
                                            message={TLSMonitoring_Msgs.testperformed_dtSoft.replace("{Index date – 2 months}", index_dt_minus_2mo).replace("{Venetoclax Start Date}", venetoclaxStartDate)}
                                          />

                                          <ErrorField show={data.testperformedEmptyHard} message={TLSMonitoring_Msgs.Select} />
                                          <ErrorField show={data.testperformedDateEmptyHard} message={TLSMonitoring_Msgs.testperformedDateEmpty} />

                                          <ErrorField show={data.testperformed_dtInvalid} message={CommonError_Msgs.DateNotValid} />

                                        </div>
                                      </td>
                                      <td className="modal-popup-table-cell">
                                        <div className="option-without-input option-fbox custom-gap">
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"timetestperformedRd" + idx}
                                            checked={data.timetestperformedRd === 1}
                                            value={1}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <input
                                            className="input-dash input_custome-space_margin"
                                            name={"timetestperformedHrsTxt" + idx}
                                            type="text"
                                            id={idx}
                                            maxLength={100}
                                            value={data.timetestperformedHrsTxt}
                                            // onChange={HandleRepeaterFieldChange}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              const regex = /^\d{0,2}$/;
                                              if (regex.test(value)) {
                                                HandleRepeaterFieldChange(e);
                                              }
                                            }}
                                          // onKeyDown={(e) => {
                                          //   // Allow backspace, arrow keys, and delete
                                          //   if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                          //     return;
                                          //   }
                                          //   // Prevent non-numeric characters
                                          //   if (!/[0-9]/.test(e.key)) {
                                          //     e.preventDefault();
                                          //     return;
                                          //   }
                                          //   const value = e.target.value;
                                          //   const selectionStart = e.target.selectionStart;
                                          //   const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                          //   // Prevent multiple '.'
                                          //   if (e.key === '.' && value.includes('.')) {
                                          //     e.preventDefault();
                                          //     return;
                                          //   }
                                          //   // Prevent '.' as the first character
                                          //   if (e.key === '.' && value === '') {
                                          //     e.preventDefault();
                                          //     return;
                                          //   }
                                          //   // Allow only one digit after decimal point
                                          //   if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                          //     e.preventDefault();
                                          //     return;
                                          //   }
                                          // }}
                                          />{" "}
                                          :
                                          <label>
                                            <input
                                              className="input-dash input_custome-space_margin"
                                              name={"timetestperformedMinTxt" + idx}
                                              type="text"
                                              id={idx}
                                              maxLength={2}
                                              value={data.timetestperformedMinTxt}
                                              // onChange={HandleRepeaterFieldChange}
                                              // onKeyDown={(e) => {
                                              //   // Allow backspace, arrow keys, and delete
                                              //   if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                              //     return;
                                              //   }
                                              //   // Prevent non-numeric characters
                                              //   if (!/[0-9]/.test(e.key)) {
                                              //     e.preventDefault();
                                              //     return;
                                              //   }
                                              //   const value = e.target.value;
                                              //   const selectionStart = e.target.selectionStart;
                                              //   const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                              //   // Prevent multiple '.'
                                              //   if (e.key === '.' && value.includes('.')) {
                                              //     e.preventDefault();
                                              //     return;
                                              //   }
                                              //   // Prevent '.' as the first character
                                              //   if (e.key === '.' && value === '') {
                                              //     e.preventDefault();
                                              //     return;
                                              //   }
                                              //   // Allow only one digit after decimal point
                                              //   if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                              //     e.preventDefault();
                                              //     return;
                                              //   }
                                              // }}

                                              onChange={(e) => {
                                                const value = e.target.value;
                                                const regex = /^\d{0,2}$/;
                                                if (regex.test(value)) {
                                                  HandleRepeaterFieldChange(e);
                                                }
                                              }}
                                              onBlur={(e) => {
                                                const value = parseInt(e.target.value, 10);
                                                // Limit the minute input to 0-59
                                                if (value < 0 || value > 59) {
                                                  e.target.value = 59;
                                                }
                                              }}
                                            // disabled={data.timetestperformedHrsTxt === "23" && data.timetestperformedMinTxt > "59"} // Disable minutes input if hours is 24

                                            />

                                            <span className="">
                                              (hour/min)
                                            </span>
                                          </label>
                                        </div>
                                        <div className="option-without-input option-fbox custom-gap">
                                          <label className="option-fbox custom-gap">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"timetestperformedRd" + idx}
                                              checked={data.timetestperformedRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            {/* <label> */}
                                            <span >
                                              Unknown
                                            </span>
                                          </label>
                                        </div>
                                        <ErrorField show={data.timetestperformedEmptyHard} message={TLSMonitoring_Msgs.Select} />
                                        <ErrorField show={data.timetestperformedHrsEmptyHard} message={TLSMonitoring_Msgs.HrsEmpty} />
                                        <ErrorField show={data.timetestperformedMinEmptyHard} message={TLSMonitoring_Msgs.MinsEmpty} />
                                        <ErrorField show={data.timetestperformedHrsMinEmptyHard} message={TLSMonitoring_Msgs.HrsMinsEmpty} />

                                      </td>
                                      <td className="modal-popup-table-cell">
                                        <div className="option-without-input">
                                          <label >
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"lymphNode_sizeRd" + idx}
                                              checked={data.lymphNode_sizeRd === 1}
                                              value={1}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            <input
                                              className="input-dash input_custome-space_sm"
                                              name={"lymphNode_sizeTxt" + idx}
                                              id={idx}
                                              type="text"
                                              maxLength={100}
                                              value={data.lymphNode_sizeTxt}
                                              onChange={HandleRepeaterFieldChange}
                                              onKeyDown={(e) => {
                                                // Allow backspace, arrow keys, and delete
                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                  return;
                                                }
                                                // Prevent non-numeric characters
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                const value = e.target.value;
                                                const selectionStart = e.target.selectionStart;
                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                // Prevent multiple '.'
                                                if (e.key === '.' && value.includes('.')) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Prevent '.' as the first character
                                                if (e.key === '.' && value === '') {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Allow only one digit after decimal point
                                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                              }}
                                            />

                                            <span className="radio-text-padding">
                                              cm
                                            </span>
                                          </label>
                                        </div>
                                        <div className="option-without-input">
                                          <label className="option-fbox custom-spacing-lbl">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"lymphNode_sizeRd" + idx}
                                              checked={data.lymphNode_sizeRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            {/* <label "> */}
                                            <span >
                                              Unknown
                                            </span>
                                          </label>
                                        </div>
                                        <ErrorField show={data.lymphNode_sizeTxtSoft}
                                          message={TLSMonitoring_Msgs.lymphnodesizeSoft.replace("{value}", data.lymphNode_sizeTxt)} />
                                        <ErrorField show={data.lymphNode_sizeEmptyHard} message={TLSMonitoring_Msgs.Select} />
                                        <ErrorField show={data.lymphNode_sizeTxtEmptyHard} message={TLSMonitoring_Msgs.sizeTxtEmpty} />
                                      </td>
                                      <td className="modal-popup-table-cell">
                                        <div className="option-without-input">
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"lymphNodegreaterthanfiveRd" + idx}
                                            checked={data.lymphNodegreaterthanfiveRd === 1}
                                            value={1}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <input
                                            className="input-dash input_custome-space_sm"
                                            name={"lymphNodegreaterthanfiveTxt" + idx}
                                            type="text"
                                            id={idx}
                                            maxLength={100}
                                            value={data.lymphNodegreaterthanfiveTxt}
                                            onChange={HandleRepeaterFieldChange}
                                            onKeyDown={(e) => {
                                              // Allow backspace, arrow keys, and delete
                                              if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                return;
                                              }
                                              // Prevent non-numeric characters
                                              if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                                return;
                                              }
                                              const value = e.target.value;
                                              const selectionStart = e.target.selectionStart;
                                              const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                              // Prevent multiple '.'
                                              if (e.key === '.' && value.includes('.')) {
                                                e.preventDefault();
                                                return;
                                              }
                                              // Prevent '.' as the first character
                                              if (e.key === '.' && value === '') {
                                                e.preventDefault();
                                                return;
                                              }
                                              // Allow only one digit after decimal point
                                              if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                e.preventDefault();
                                                return;
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="option-without-input">
                                          <label className="option-fbox custom-spacing-lbl">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"lymphNodegreaterthanfiveRd" + idx}
                                              checked={data.lymphNodegreaterthanfiveRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            {/* <label> */}
                                            <span >
                                              Unknown
                                            </span>
                                          </label>
                                        </div>
                                        <ErrorField show={data.lymphNodegreaterthanfiveTxtSoft}
                                          message={TLSMonitoring_Msgs.lymphNodegreaterthanfiveTxtSoft.replace("{value}", data.lymphNodegreaterthanfiveTxt)} />
                                        <ErrorField show={data.lymphNodegreaterthanfiveEmptyHard} message={TLSMonitoring_Msgs.Select} />
                                        <ErrorField show={data.lymphNodegreaterthanfiveTxtEmptyHard} message={TLSMonitoring_Msgs.sizeTxtymphNodegreaterthanfiveTxt} />
                                      </td>
                                    </>
                                    ) :
                                      (
                                        <td
                                          colSpan={4}
                                          style={{
                                            border: "1px solid black",
                                            // alignContent: "flex-start",
                                            textAlign: "center",
                                            padding: "8px",
                                            //textAlign: 'start'
                                          }}
                                        >
                                          <span
                                            style={{ textAlign: "center" }}
                                            className="not-applicable"
                                          >
                                            <b>Not Applicable</b>
                                          </span>
                                        </td>
                                      )

                                    }
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <br />


                      <div className="question question-weight">
                        <span>33.</span>
                        <span className="quest-text-pad">
                          Please indicate the total number of absolute
                          lymphocyte count (ALC) and creatinine clearance
                          level (CrCl) tests completed between {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {index_dt_minus_2mo}  </span>

                          and {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          > {venetoclaxStartDate}. </span>{" "}
                        </span>
                      </div>

                      <div className="answer-pad-left">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="testCompletedCount_checked1"
                              name="testCompletedCount_checked"
                              value={1}
                              checked={
                                tlsmonitoringDetails.testCompletedCount_checked === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <input
                              className="input-dash input_custome-space_sm"
                              name="testCompletedCount"
                              type="text"
                              maxLength={2}
                              value={tlsmonitoringDetails.testCompletedCount}
                              onChange={HandleFieldChange}
                              onKeyDown={(e) => {
                                // Allow backspace, arrow keys, and delete
                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                  return;
                                }
                                // Prevent non-numeric characters
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                  return;
                                }
                                const value = e.target.value;
                                const selectionStart = e.target.selectionStart;
                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                // Prevent multiple '.'
                                if (e.key === '.' && value.includes('.')) {
                                  e.preventDefault();
                                  return;
                                }
                                // Prevent '.' as the first character
                                if (e.key === '.' && value === '') {
                                  e.preventDefault();
                                  return;
                                }
                                // Allow only one digit after decimal point
                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                  e.preventDefault();
                                  return;
                                }
                              }}
                            />
                            <span className="radio-text-padding">
                              ALC/CrCl tests
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label className="option-fbox custom-spacing-lbl">
                            <input
                              type="radio"
                              id="testCompletedCount_unk"
                              name="testCompletedCount_checked"
                              checked={
                                tlsmonitoringDetails.testCompletedCount_checked === 99
                              }
                              value={99}
                              onChange={HandleFieldChange}
                            />
                            {/* <label > */}
                            <span htmlFor="total_No_interruptions_99">
                              Unknown
                            </span>
                          </label>
                        </div>
                        <ErrorField
                          show={hardErrList.testCompleteEmptyHard}
                          message={TLSMonitoring_Msgs.Total_No_interruptionsEmpty}
                        />
                        <ErrorField
                          show={softErrList.testCompleteTxtEmptyHard}
                          message={q33ErrorMsg}
                        />

                        <ErrorField
                          show={hardErrList.testCompleteTxtEmpty}
                          message={TLSMonitoring_Msgs.TextEmpty}
                        />
                      </div>
                      <br />

                      {/* Loop 1 Start */}
                      {tlsmonitoringDetails.testCompletedCount > 0 && alc_crclDetails &&
                        alc_crclDetails.length > 0 &&
                        alc_crclDetails.map((data, idx) => (
                          <div className="dependent-section">
                            <div className="question question-weight">
                              <span>34.</span>
                              <span className="quest-text-pad">
                                Please indicate the following details for the {GetOrdinal(idx + 1)} {""}
                                lab test completed for absolute lymphocyte count
                                (ALC) and creatinine clearance level (CrCl).{" "}
                              </span>
                            </div>
                            <div className="answer-pad-left grid-overflow">
                              <table className="modal-popup-table-xlarge">
                                <thead className="modal-popup-table-header">
                                  <tr>
                                    <th className="modal-popup-table-header-cell">
                                      Test
                                    </th>
                                    <th className="modal-popup-table-header-cell column-custom-width-120">
                                      Was this test used to determine the venetoclax
                                      ramp-up process?
                                    </th>
                                    <th className="modal-popup-table-header-cell column-custom-width-245">
                                      Date test was performed
                                    </th>
                                    <th className="modal-popup-table-header-cell column-custom-width-245">
                                      Time test was performed
                                    </th>
                                    <th className="modal-popup-table-header-cell column-custom-width-170">
                                      Test result
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="modal-popup-table-cell">
                                      Absolute lymphocyte count (ALC)
                                    </td>
                                    <td className="modal-popup-table-cell">
                                      <div className="option-without-input">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"alc_testtodetermineVCRamp_up" + idx}
                                            checked={data.alc_testtodetermineVCRamp_up === 1}
                                            value={1}
                                            onChange={HandleRepeaterFieldChange}
                                          />

                                          <span className="radio-text-padding">
                                            Yes
                                          </span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"alc_testtodetermineVCRamp_up" + idx}
                                            checked={data.alc_testtodetermineVCRamp_up === 2}
                                            value={2}
                                            onChange={HandleRepeaterFieldChange}
                                          />

                                          <span className="radio-text-padding">
                                            No
                                          </span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"alc_testtodetermineVCRamp_up" + idx}
                                            checked={data.alc_testtodetermineVCRamp_up === 99}
                                            value={99}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            Unknown
                                          </span>
                                        </label>
                                        <ErrorField show={data.alctesttodetermineVCRampEmpty} message={TLSMonitoring_Msgs.Select} />

                                      </div>
                                    </td>
                                    {data.alc_testtodetermineVCRamp_up === 1 ? (<>
                                      <td className="modal-popup-table-cell">
                                        <div className="date-field-padding">
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"alc_datetestperformed_dtRd" + idx}
                                            value={1}
                                            checked={data.alc_datetestperformed_dtRd === 1}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            <DateControl
                                              ctrlId={"alc_datetestperformed_dt-" + idx}
                                              HandleDateChange={HandleRepeaterDateChange}
                                              date={data.alc_datetestperformed_dt}
                                              showUnkInfoTxt={false}
                                              maxDate={dayjs()}
                                            />
                                          </span>
                                        </div>
                                        <div className="option-without-input">
                                          <label >
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"alc_datetestperformed_dtRd" + idx}
                                              checked={data.alc_datetestperformed_dtRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            <span className="radio-text-padding">
                                              Unknown
                                            </span>
                                          </label>
                                        </div>
                                        <div className="option-without-input">
                                          <label>
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"alc_datetestperformed_dtRd" + idx}
                                              checked={data.alc_datetestperformed_dtRd === 2}
                                              value={2}
                                              onChange={HandleRepeaterFieldChange}
                                            />

                                            <span className="radio-text-padding">
                                              Test was not performed
                                            </span>
                                          </label>
                                          <ErrorField
                                            show={data.alc_datetestperformed_dtSoft}
                                            message={TLSMonitoring_Msgs.testperformed_dtSoft.replace("{Index date – 2 months}", index_dt_minus_2mo).replace("{Venetoclax Start Date}", venetoclaxStartDate)}
                                          />
                                          <ErrorField show={data.alc_datetestperformed_dtRdEmpty} message={TLSMonitoring_Msgs.Select} />
                                          <ErrorField show={data.alc_datetestperformed_dtEmpty} message={TLSMonitoring_Msgs.alc_datetestperformed_dtEmptyMsg} />

                                          <ErrorField show={data.alc_datetestperformedInvalid} message={CommonError_Msgs.DateNotValid} />

                                        </div>
                                      </td>
                                      <td className="modal-popup-table-cell">
                                        <div className="option-without-input">
                                          <label >
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"alc_timetestperformedRd" + idx}
                                              checked={data.alc_timetestperformedRd === 1}
                                              value={1}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            <input
                                              className="input-dash input_custome-space_sm"
                                              name={"alc_timetestHrsTxt" + idx}
                                              id={idx}
                                              type="text"
                                              maxLength={2}
                                              value={data.alc_timetestHrsTxt}
                                              onChange={HandleRepeaterFieldChange}
                                              onKeyDown={(e) => {
                                                // Allow backspace, arrow keys, and delete
                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                  return;
                                                }
                                                // Prevent non-numeric characters
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                const value = e.target.value;
                                                const selectionStart = e.target.selectionStart;
                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                // Prevent multiple '.'
                                                if (e.key === '.' && value.includes('.')) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Prevent '.' as the first character
                                                if (e.key === '.' && value === '') {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Allow only one digit after decimal point
                                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                              }}
                                            />{" "}
                                            :
                                            <input
                                              className="input-dash input_custome-space_sm"
                                              name={"alc_timetestMinsTxt" + idx}
                                              id={idx}
                                              type="text"
                                              maxLength={2}
                                              value={data.alc_timetestMinsTxt}
                                              onChange={HandleRepeaterFieldChange}
                                              onKeyDown={(e) => {
                                                // Allow backspace, arrow keys, and delete
                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                  return;
                                                }
                                                // Prevent non-numeric characters
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                const value = e.target.value;
                                                const selectionStart = e.target.selectionStart;
                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                // Prevent multiple '.'
                                                if (e.key === '.' && value.includes('.')) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Prevent '.' as the first character
                                                if (e.key === '.' && value === '') {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Allow only one digit after decimal point
                                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                              }}
                                            />

                                            <span className="radio-text-padding">
                                              (hour/min)
                                            </span>
                                          </label>
                                        </div>
                                        <div className="option-without-input">

                                          <label className="option-fbox custom-spacing-lbl">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"alc_timetestperformedRd" + idx}
                                              checked={data.alc_timetestperformedRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            {/* <label> */}
                                            <span >
                                              Unknown
                                            </span>
                                          </label>
                                          <ErrorField show={data.alc_timetestperformedRdEmpty} message={TLSMonitoring_Msgs.Select} />
                                          <ErrorField show={data.alc_timetestperformedHrsEmpty} message={TLSMonitoring_Msgs.HrsEmpty} />
                                          <ErrorField show={data.alc_timetestperformedMinsEmpty} message={TLSMonitoring_Msgs.MinsEmpty} />
                                          <ErrorField show={data.alc_timetestperformedMinsHrsEmpty} message={TLSMonitoring_Msgs.HrsMinsEmpty} />
                                        </div>
                                      </td>
                                      <td className="modal-popup-table-cell">
                                        <div className="option-without-input">
                                          <label >
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"alc_testresultRd" + idx}
                                              checked={data.alc_testresultRd === 1}
                                              value={1}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            <input
                                              className="input-dash input_custome-space_sm"
                                              name={"alctestresultTxt" + idx}
                                              type="text"
                                              id={idx}
                                              maxLength={2}
                                              value={data.alctestresultTxt}
                                              onChange={HandleRepeaterFieldChange}
                                              onKeyDown={(e) => {
                                                // Allow backspace, arrow keys, and delete
                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                  return;
                                                }
                                                // Prevent non-numeric characters
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                const value = e.target.value;
                                                const selectionStart = e.target.selectionStart;
                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                // Prevent multiple '.'
                                                if (e.key === '.' && value.includes('.')) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Prevent '.' as the first character
                                                if (e.key === '.' && value === '') {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Allow only one digit after decimal point
                                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                              }}
                                            />

                                            <span className="radio-text-padding">
                                              10<sup>9</sup>/L
                                            </span>
                                          </label>
                                        </div>
                                        <div className="option-without-input">
                                          <label className="option-fbox custom-spacing-lbl">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"alc_testresultRd" + idx}
                                              checked={data.alc_testresultRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            {/* <label > */}
                                            <span >
                                              Unknown
                                            </span>
                                          </label>
                                          <ErrorField
                                            show={data.alctestresultSoft}
                                            message={TLSMonitoring_Msgs.testresultSoft
                                              .replace("{value}", data.alctestresultTxt)
                                              .replace("{units}", "10\u2079 /L")}
                                          />

                                          <ErrorField show={data.alc_testresultRdEmpty} message={TLSMonitoring_Msgs.Select} />
                                          <ErrorField show={data.alc_testresultTxtEmpty} message={TLSMonitoring_Msgs.alc_testresultTxtEmptyMsg} />


                                        </div>
                                      </td>
                                    </>
                                    ) :
                                      (
                                        <td
                                          colSpan={4}
                                          style={{
                                            border: "1px solid black",
                                            // alignContent: "flex-start",
                                            textAlign: "center",
                                            padding: "8px",
                                            //textAlign: 'start'
                                          }}
                                        >
                                          <span
                                            style={{ textAlign: "center" }}
                                            className="not-applicable"
                                          >
                                            <b>Not Applicable</b>
                                          </span>
                                        </td>
                                      )

                                    }
                                  </tr>

                                  <tr>
                                    <td className="modal-popup-table-cell">
                                      Creatinine clearance level (CrCl)
                                    </td>
                                    <td className="modal-popup-table-cell">
                                      <div className="option-without-input">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"crcl_testtodetermineVCRamp_up" + idx}
                                            checked={data.crcl_testtodetermineVCRamp_up === 1}
                                            value={1}
                                            onChange={HandleRepeaterFieldChange}
                                          />

                                          <span className="radio-text-padding">
                                            Yes
                                          </span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label>
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"crcl_testtodetermineVCRamp_up" + idx}
                                            checked={data.crcl_testtodetermineVCRamp_up === 2}
                                            value={2}
                                            onChange={HandleRepeaterFieldChange}
                                          />

                                          <span className="radio-text-padding">
                                            No
                                          </span>
                                        </label>
                                      </div>
                                      <div className="option-without-input">
                                        <label >
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"crcl_testtodetermineVCRamp_up" + idx}
                                            checked={data.crcl_testtodetermineVCRamp_up === 99}
                                            value={99}
                                            onChange={HandleRepeaterFieldChange}
                                          />

                                          <span className="radio-text-padding">
                                            Unknown
                                          </span>
                                        </label>
                                      </div>
                                      <ErrorField show={data.crcltesttodetermineVCRampEmpty} message={TLSMonitoring_Msgs.Select} />

                                    </td>

                                    {data.crcl_testtodetermineVCRamp_up === 1 ? (<>
                                      <td className="modal-popup-table-cell">
                                        <div className="date-field-padding">
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"crcl_datetestperformed_dtRd" + idx}
                                            value={1}
                                            checked={data.crcl_datetestperformed_dtRd === 1}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            <DateControl
                                              ctrlId={"crcl_datetestperformed_dt-" + idx}
                                              HandleDateChange={HandleRepeaterDateChange}
                                              date={data.crcl_datetestperformed_dt}
                                              showUnkInfoTxt={false}
                                              maxDate={dayjs()}
                                            />
                                          </span>
                                        </div>
                                        <div className="option-without-input">
                                          <label>
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"crcl_datetestperformed_dtRd" + idx}
                                              checked={data.crcl_datetestperformed_dtRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />

                                            <span className="radio-text-padding">
                                              Unknown
                                            </span>
                                          </label>
                                        </div>
                                        <div className="option-without-input">
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"crcl_datetestperformed_dtRd" + idx}
                                            checked={data.crcl_datetestperformed_dtRd === 2}
                                            value={2}
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          <label htmlFor="veneto_RampUp_Dose_1">
                                            <span className="radio-text-padding">
                                              Test was not performed
                                            </span>
                                          </label>
                                          <ErrorField
                                            show={data.crcl_datetestperformed_dtSoft}
                                            message={TLSMonitoring_Msgs.testperformed_dtSoft.replace("{Index date – 2 months}", index_dt_minus_2mo).replace("{Venetoclax Start Date}", venetoclaxStartDate)}
                                          />
                                          <ErrorField show={data.crcl_datetestperformed_dtRdEmpty} message={TLSMonitoring_Msgs.Select} />
                                          <ErrorField show={data.crcl_datetestperformed_dtEmpty} message={TLSMonitoring_Msgs.crcl_datetestperformed_dtEmptyMsg} />
                                          <ErrorField show={data.crcl_datetestperformedInvalid} message={CommonError_Msgs.DateNotValid} />

                                        </div>
                                      </td>
                                      <td className="modal-popup-table-cell">
                                        <div className="option-without-input">
                                          <label>
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"crcl_timetestperformedRd" + idx}
                                              checked={data.crcl_timetestperformedRd === 1}
                                              value={1}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            <input
                                              className="input-dash input_custome-space_sm"
                                              name={"crcl_timetestHrsTxt" + idx}
                                              type="text"
                                              id={idx}
                                              maxLength={2}
                                              value={data.crcl_timetestHrsTxt}
                                              onChange={HandleRepeaterFieldChange}
                                            />{" "}
                                            :
                                            <input
                                              className="input-dash input_custome-space_sm"
                                              name={"crcl_timetestMinsTxt" + idx}
                                              type="text"
                                              maxLength={2}
                                              id={idx}
                                              value={data.crcl_timetestMinsTxt}
                                              onChange={HandleRepeaterFieldChange}
                                              onKeyDown={(e) => {
                                                // Allow backspace, arrow keys, and delete
                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                  return;
                                                }
                                                // Prevent non-numeric characters
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                const value = e.target.value;
                                                const selectionStart = e.target.selectionStart;
                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                // Prevent multiple '.'
                                                if (e.key === '.' && value.includes('.')) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Prevent '.' as the first character
                                                if (e.key === '.' && value === '') {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Allow only one digit after decimal point
                                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                              }}
                                            />

                                            <span className="radio-text-padding">
                                              (hour/min)
                                            </span>
                                          </label>
                                        </div>
                                        <div className="option-without-input">
                                          <label className="option-fbox custom-spacing-lbl">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"crcl_timetestperformedRd" + idx}
                                              checked={data.crcl_timetestperformedRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            {/* <label > */}
                                            <span >
                                              Unknown
                                            </span>
                                          </label>
                                          <ErrorField show={data.crcl_timetestperformedRdEmpty} message={TLSMonitoring_Msgs.Select} />
                                          <ErrorField show={data.crcl_timetestperformedHrsEmpty} message={TLSMonitoring_Msgs.HrsEmpty} />
                                          <ErrorField show={data.crcl_timetestperformedMinsEmpty} message={TLSMonitoring_Msgs.MinsEmpty} />
                                          <ErrorField show={data.crcl_timetestperformedMinsHrsEmpty} message={TLSMonitoring_Msgs.HrsMinsEmpty} />
                                        </div>
                                      </td>
                                      <td className="modal-popup-table-cell">
                                        <div className="option-without-input">
                                          <label >
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"crcl_testresultRd" + idx}
                                              checked={data.crcl_testresultRd === 1}
                                              value={1}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            <input
                                              className="input-dash input_custome-space_sm"
                                              name={"crcl_testresultTxt" + idx}
                                              type="text"
                                              id={idx}
                                              maxLength={6}
                                              value={data.crcl_testresultTxt}
                                              onChange={HandleRepeaterFieldChange}
                                              onKeyDown={(e) => {
                                                // Allow backspace, arrow keys, and delete
                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                  return;
                                                }
                                                // Prevent non-numeric characters
                                                if (!/[0-9.]/.test(e.key)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                const value = e.target.value;
                                                const selectionStart = e.target.selectionStart;
                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                // Prevent multiple '.'
                                                if (e.key === '.' && value.includes('.')) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Prevent '.' as the first character
                                                if (e.key === '.' && value === '') {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                // Allow only one digit after decimal point
                                                if (newValue.includes('.') && newValue.split('.')[1].length > 1) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                              }}
                                            />

                                            <span className="radio-text-padding">
                                              mL/min
                                            </span>
                                          </label>

                                        </div>
                                        <div className="option-without-input">
                                          <label className="option-fbox custom-spacing-lbl">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"crcl_testresultRd" + idx}
                                              checked={data.crcl_testresultRd === 99}
                                              value={99}
                                              onChange={HandleRepeaterFieldChange}
                                            />
                                            {/* <label > */}
                                            <span >
                                              Unknown
                                            </span>
                                          </label>
                                          <ErrorField show={data.crcl_testresultSoft} message={TLSMonitoring_Msgs.testresultSoft.replace("{value}", data.crcl_testresultTxt)} />

                                          <ErrorField show={data.crcl_testresultRdEmpty} message={TLSMonitoring_Msgs.Select} />
                                          <ErrorField show={data.crcl_testresultTxtEmpty} message={TLSMonitoring_Msgs.crcl_testresultTxtEmptyMsg} />
                                        </div>
                                      </td>
                                    </>
                                    ) :
                                      (
                                        <td
                                          colSpan={4}
                                          style={{
                                            border: "1px solid black",
                                            // alignContent: "flex-start",
                                            textAlign: "center",
                                            padding: "8px",
                                            //textAlign: 'start'
                                          }}
                                        >
                                          <span
                                            style={{ textAlign: "center" }}
                                            className="not-applicable"
                                          >
                                            <b>Not Applicable</b>
                                          </span>
                                        </td>
                                      )

                                    }
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>))}
                      <br />


                      {/* Loop 2 Start */}


                      {bloodTestRowData &&
                        bloodTestRowData.length > 0 &&
                        bloodTestRowData.some(item => item.tlsmonitoringweekbridgeid > 0) && bloodTestRowData?.map((itemParent, itemParentidx) => (
                          <div className="dependent-section" key={itemParentidx}>
                            <div>
                              <div className="question question-weight">
                                <span>35.</span>
                                <span className="quest-text-pad">
                                  Please indicate the total number of blood tests
                                  completed where at least one of the
                                  {" "}
                                  <span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideModalPopup(true)
                                    }
                                  >
                                    following tests
                                  </span>
                                  {" "}
                                  were performed on or after the {getOrdinalSuffix(itemParentidx + 1)}{" "} week of ramp-up
                                  initiated on {itemParent.rampUpInitiationDate}
                                  but before the {getOrdinalSuffix(itemParentidx + 2)}{" "} week of ramp-up initiated on
                                  {itemParent.rampUpNextInitiationDate}.{" "}
                                </span>
                              </div>
                              <div className="answer-pad-left">
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="radio"
                                      id={"bloodTestCompletedCount_checked_" + itemParentidx}
                                      name={"bloodTestCompletedCount_checked_" + itemParentidx}
                                      value={1}
                                      checked={
                                        itemParent.bloodtest_count_checked === 1
                                      }
                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, null)}
                                    />
                                    <input
                                      className="input-dash input_custome-space_sm"
                                      name={"bloodTestCompletedCount_" + itemParentidx}
                                      type="number"
                                      maxLength={2}
                                      value={itemParent.bloodtest_count}
                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, null)}
                                      onKeyDown={(e) => {
                                        // Allow backspace, arrow keys, and delete
                                        if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                          return;
                                        }
                                        // Prevent non-numeric characters
                                        if (!/[0-9]/.test(e.key)) {
                                          e.preventDefault();
                                          return;
                                        }
                                        const value = e.target.value;
                                        const selectionStart = e.target.selectionStart;
                                        const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                        // Prevent multiple '.'
                                        if (e.key === '.' && value.includes('.')) {
                                          e.preventDefault();
                                          return;
                                        }
                                        // Prevent '.' as the first character
                                        if (e.key === '.' && value === '') {
                                          e.preventDefault();
                                          return;
                                        }
                                        // Allow only one digit after decimal point
                                        if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                          e.preventDefault();
                                          return;
                                        }
                                      }}
                                    />
                                    <span className="radio-text-padding">
                                      blood tests
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label className="option-fbox custom-spacing-lbl">
                                    <input
                                      type="radio"
                                      id={"bloodTestCompletedCount_Unk_" + itemParentidx}
                                      name={"bloodTestCompletedCount_Unk_" + itemParentidx}
                                      checked={
                                        itemParent.bloodtest_count_unk === 99
                                      }
                                      value={99}
                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, null)}
                                    />
                                    {/* <label> */}
                                    <span htmlFor="total_No_interruptions_99">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                                <ErrorField
                                  show={itemParent.bloodTestCompleteEmpty}
                                  message={TLSMonitoring_Msgs.Total_No_interruptionsEmpty}
                                />
                                <ErrorField
                                  show={itemParent.bloodTestCountEmpty}
                                  message={q35ErrorMsg}
                                />
                              </div>

                            </div>

                            {/* Sub-Loop 2.1 Start of Q36. Table 1 */}
                            {/* {bloodTestRowData[itemParentidx].bloodTestDetails?.map((item, itemidx) => ( */}
                            {Array.isArray(bloodTestRowData[itemParentidx].bloodTestDetails) && bloodTestRowData[itemParentidx].bloodTestDetails.map((item, itemidx) => (
                              <div className="dependent-section" key={itemidx}>
                                <div className="question question-weight">
                                  <span>36.</span>
                                  <span className="quest-text-pad">
                                    Please indicate the following details for the {getOrdinalSuffix(itemidx + 1)}{" "}
                                    blood test completed.{" "}
                                  </span>
                                </div>
                                <div className="answer-pad-left">
                                  <div className="grid-overflow">
                                    <table className="modal-popup-table-xlarge">
                                      <thead className="modal-popup-table-header">
                                        <tr>
                                          <th className="modal-popup-table-header-cell">
                                            Date of blood draw{" "}
                                            <br />
                                            <br />
                                            <i>
                                              Please report tests in chronological order{" "}
                                            </i>
                                          </th>
                                          <th className="modal-popup-table-header-cell column-custom-width-245">
                                            Please indicate the reason for the repeat lab
                                            test
                                          </th>
                                          <th className="modal-popup-table-header-cell">
                                            Time of blood draw
                                          </th>
                                          <th className="modal-popup-table-header-cell">
                                            Test setting
                                          </th>
                                          <th className="modal-popup-table-header-cell column-custom-width-245">
                                            Please indicate whether clinical or laboratory
                                            TLS as per the{" "}
                                            <span
                                              data-tooltip-id="encounterTip"
                                              className="modal-popup"
                                              onClick={() =>
                                                setShowHideModalPopupLink(true)
                                              }
                                            >
                                              Howard criteria
                                            </span>
                                            {" "}
                                            was identified during this
                                            assessment.
                                          </th>
                                          <th className="modal-popup-table-header-cell column-custom-width-400">
                                            Was an intervention given following this lab
                                            result?
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            <div className="date-field-padding option-fbox">
                                              <input
                                                type="radio"
                                                id={"blood_draw_date_checked_" + itemParentidx + "_of_" + itemidx}
                                                name={"blood_draw_date_checked_" + itemParentidx + "_of_" + itemidx}
                                                value={1}
                                                checked={item.blooddraw_date_checked === 1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <span className="">
                                                <DateControl
                                                  ctrlId={"blood_draw_date_" + itemidx}
                                                  HandleDateChange={HandleQ36RepeaterDateChange}
                                                  date={item.blooddraw_date}
                                                  showUnkInfoTxt={false}
                                                />
                                              </span>
                                            </div>
                                            <div className="option-without-input">
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={"blood_draw_date_unk_" + itemParentidx + "_of_" + itemidx}
                                                  name={"blood_draw_date_unk_" + itemParentidx + "_of_" + itemidx}
                                                  checked={item.blooddraw_date_unk === 99}
                                                  value={99}
                                                  onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                />
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T1AEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T1ADateEmpty}
                                              message={TLSMonitoring_Msgs.q36T1ADateEmptyMsg}
                                            />
                                            <ErrorField
                                              show={item.q36T1ADateCheckError}
                                              message={TLSMonitoring_Msgs.q36T1ADateCheckErrorMsg}
                                            />
                                            <ErrorField
                                              show={item.q36T1ADateVenCheckError}
                                              message={TLSMonitoring_Msgs.q36T1ADateVenCheckErrorMsg}
                                            />
                                            <ErrorField
                                              show={item.q36T1AInvalidDtError}
                                              message={CommonError_Msgs.DateNotValid}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {itemidx > 0 && (
                                              <div>
                                                <div className="option-without-input option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={"repeat_labtest_1_" + itemParentidx + "_of_" + itemidx}
                                                    name={"repeat_labtest_1_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.reasonrepeattest === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <label htmlFor={"repeat_labtest_1_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      Per-label TLS assessment
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={"repeat_labtest_2_" + itemParentidx + "_of_" + itemidx}
                                                    name={"repeat_labtest_2_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.reasonrepeattest === 2}
                                                    value={2}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <label htmlFor={"repeat_labtest_2_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      Yes, to confirm lab abnormality
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input question-20-c-sub-option-custom">
                                                  <div className="option-without-input">
                                                    <span>Due to hemolyzed sample  </span>
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_labhemmo_1_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_labhemmo_1_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.confirm_lab_hemolyzed === 1}
                                                        value={1}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        Yes
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="option-without-input">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_labhemmo_2_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_labhemmo_2_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.confirm_lab_hemolyzed === 2}
                                                        value={2}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        No
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="option-without-input">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_labhemmo_99_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_labhemmo_99_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.confirm_lab_hemolyzed === 99}
                                                        value={99}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        Unknown
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={"repeat_labtest_3_" + itemParentidx + "_of_" + itemidx}
                                                    name={"repeat_labtest_3_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.reasonrepeattest === 3}
                                                    value={3}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <label htmlFor={"repeat_labtest_3_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      Yes, for post-intervention assessment
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input question-20-c-sub-option-custom">
                                                  <div className="option-without-input">
                                                    <span>Due to hemolyzed sample  </span>
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_posthemmo_1_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_posthemmo_1_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.post_hemolyzed === 1}
                                                        value={1}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        Yes
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="option-without-input">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_posthemmo_2_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_posthemmo_2_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.post_hemolyzed === 2}
                                                        value={2}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        No
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="option-without-input">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_posthemmo_99_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_posthemmo_99_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.post_hemolyzed === 99}
                                                        value={99}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        Unknown
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={"repeat_labtest_4_" + itemParentidx + "_of_" + itemidx}
                                                    name={"repeat_labtest_4_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.reasonrepeattest === 4}
                                                    value={4}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <label htmlFor={"repeat_labtest_4_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      Yes, due to inconclusive prior lab
                                                      result{" "}
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input question-20-c-sub-option-custom">
                                                  <div className="option-without-input">
                                                    <span>Due to hemolyzed sample  </span>
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_inhemmo_1_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_inhemmo_1_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.inconclusive_hemolyzed === 1}
                                                        value={1}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        Yes
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="option-without-input">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_inhemmo_2_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_inhemmo_2_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.inconclusive_hemolyzed === 2}
                                                        value={2}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        No
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="option-without-input">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id={"repeat_labtest_inhemmo_99_" + itemParentidx + "_of_" + itemidx}
                                                        name={"repeat_labtest_inhemmo_99_" + itemParentidx + "_of_" + itemidx}
                                                        checked={item.inconclusive_hemolyzed === 99}
                                                        value={99}
                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                      />

                                                      <span className="radio-text-padding">
                                                        Unknown
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T1BEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T1BSubValueEmpty}
                                                  message={TLSMonitoring_Msgs.q36T1BSubValueEmptyMsg}
                                                />
                                              </div>)}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"blooddraw_time_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"blooddraw_time_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.timeofblooddraw === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"blooddraw_time_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Pre-dose
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"blooddraw_time_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"blooddraw_time_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.timeofblooddraw === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"blooddraw_time_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  6-8 hours post-dose
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"blooddraw_time_3_" + itemParentidx + "_of_" + itemidx}
                                                name={"blooddraw_time_3_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.timeofblooddraw === 3}
                                                value={3}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"blooddraw_time_3_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  24 hours post-dose
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox custom-gap">
                                              <input
                                                type="radio"
                                                id={"blooddraw_time_4_" + itemParentidx + "_of_" + itemidx}
                                                name={"blooddraw_time_4_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.timeofblooddraw === 4}
                                                value={4}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"blooddraw_time_4_" + itemParentidx + "_of_" + itemidx} style={{ paddingLeft: "9px" }}>
                                                <span className="">
                                                  Other{" "}
                                                </span>
                                              </label>
                                              <input
                                                className="input-dash input_custome-space_margin"
                                                name={"blooddraw_oth_time_hr_" + itemParentidx + "_of_" + itemidx}
                                                type="text"
                                                id={"blooddraw_oth_time_hr_" + itemParentidx + "_of_" + itemidx}
                                                maxLength={2}
                                                value={item.blooddraw_oth_time_hr}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                onKeyDown={(e) => {
                                                  // Allow backspace, arrow keys, and delete
                                                  if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                    return;
                                                  }
                                                  // Prevent non-numeric characters
                                                  if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  const value = e.target.value;
                                                  const selectionStart = e.target.selectionStart;
                                                  const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                  // Prevent multiple '.'
                                                  if (e.key === '.' && value.includes('.')) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  // Prevent '.' as the first character
                                                  if (e.key === '.' && value === '') {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  // Allow only one digit after decimal point
                                                  if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                }}
                                              />
                                              :
                                              <input
                                                className="input-dash input_custome-space_margin"
                                                name={"blooddraw_oth_time_min_" + itemParentidx + "_of_" + itemidx}
                                                id={"blooddraw_oth_time_min_" + itemParentidx + "_of_" + itemidx}
                                                type="text"
                                                maxLength={2}
                                                value={item.blooddraw_oth_time_min}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                onKeyDown={(e) => {
                                                  // Allow backspace, arrow keys, and delete
                                                  if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                    return;
                                                  }
                                                  // Prevent non-numeric characters
                                                  if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  const value = e.target.value;
                                                  const selectionStart = e.target.selectionStart;
                                                  const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                  // Prevent multiple '.'
                                                  if (e.key === '.' && value.includes('.')) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  // Prevent '.' as the first character
                                                  if (e.key === '.' && value === '') {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  // Allow only one digit after decimal point
                                                  if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                }}
                                              />
                                              <label htmlFor="veneto_RampUp_Dose_1">
                                                <span className="">
                                                  (hour/min)
                                                </span>
                                              </label>
                                            </div>

                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"blooddraw_time_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"blooddraw_time_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.timeofblooddraw === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"blooddraw_time_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T1CEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T1CTimeEmpty}
                                              message={TLSMonitoring_Msgs.q36T1CTimeEmptyMsg}
                                            />
                                            <ErrorField
                                              show={item.q36T1CHrsEmpty}
                                              message={TLSMonitoring_Msgs.q36T1CHrsEmptyMsg}
                                            />
                                            <ErrorField
                                              show={item.q36T1CMinsEmpty}
                                              message={TLSMonitoring_Msgs.q36T1CMinsEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"tls_settings_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"tls_settings_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.testsetting === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"tls_settings_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Onsite
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"tls_settings_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"tls_settings_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.testsetting === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"tls_settings_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Offsite
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"tls_settings_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"tls_settings_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.testsetting === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"tls_settings_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T1DEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input">
                                              <label className="option-fbox">
                                                <input className="option-input-margin"
                                                  type="checkbox"
                                                  id={"tls_laboratory_" + itemParentidx + "_of_" + itemidx}
                                                  name={"tls_laboratory_" + itemParentidx + "_of_" + itemidx}
                                                  checked={item.tls_laboratory}
                                                  onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                />
                                                <span className="">
                                                  Laboratory TLS only per Howard Criteria
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input">
                                              <label className="option-fbox">
                                                <input className="option-input-margin"
                                                  type="checkbox"
                                                  id={"tls_clinical_" + itemParentidx + "_of_" + itemidx}
                                                  name={"tls_clinical_" + itemParentidx + "_of_" + itemidx}
                                                  checked={item.tls_clinical}
                                                  onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                />
                                                <span className="">
                                                  Clinical TLS per Howard Criteria
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"tls_neither_" + itemParentidx + "_of_" + itemidx}
                                                name={"tls_neither_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.tls_neither}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"tls_neither_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Neither
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"tls_unk_" + itemParentidx + "_of_" + itemidx}
                                                name={"tls_unk_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.tls_unk}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"tls_unk_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T1EEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"intervention_given_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"intervention_given_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.intervention_given === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"intervention_given_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input question-20-c-sub-option-custom">
                                              <div className="option-without-input">

                                                <label className="option-fbox">
                                                  <input className="option-input-margin"
                                                    type="checkbox"
                                                    id={"yes_dosehold_" + itemParentidx + "_of_" + itemidx}
                                                    name={"yes_dosehold_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.yes_dosehold}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />

                                                  <span className="">
                                                    Treatment interruption/dose hold
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="option_without_input">

                                                <label className="option-fbox">
                                                  <input className="option-input-margin"
                                                    type="checkbox"
                                                    id={"yes_ivhydration_" + itemParentidx + "_of_" + itemidx}
                                                    name={"yes_ivhydration_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.yes_ivhydration}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />

                                                  <span className="">
                                                    IV hydration
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="option-without-input">

                                                <label className="option-fbox">
                                                  <input className="option-input-margin"
                                                    type="checkbox"
                                                    id={"yes_metabolic_" + itemParentidx + "_of_" + itemidx}
                                                    name={"yes_metabolic_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.yes_metabolic}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />

                                                  <span className="">
                                                    Correction of metabolic abnormalities
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="option-without-input">

                                                <label className="option-fbox">
                                                  <input className="option-input-margin"
                                                    type="checkbox"
                                                    id={"yes_oth_" + itemParentidx + "_of_" + itemidx}
                                                    name={"yes_oth_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.yes_oth}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />

                                                  <div>
                                                    <span className="">
                                                      Other, specify:
                                                    </span>
                                                    <input
                                                      className="input-dash input_custome-space"
                                                      name={"yes_oth_txt_" + itemParentidx + "_of_" + itemidx}
                                                      type="text"
                                                      maxLength={100}
                                                      value={item.yes_oth_txt}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                  </div>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"intervention_given_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"intervention_given_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.intervention_given === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"intervention_given_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  No
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input">
                                              <input
                                                type="radio"
                                                id={"intervention_given_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"intervention_given_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.intervention_given === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"intervention_given_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="radio-text-padding">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T1FEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T1FChildEmpty}
                                              message={TLSMonitoring_Msgs.q36T1FChildEmptyMsg}
                                            />
                                            <ErrorField
                                              show={item.q36T1FOtherTxtEmpty}
                                              message={TLSMonitoring_Msgs.q36T1FOtherTxtEmptyMsg}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <br />
                                  <i>Please note that the the test results in the table below should correspond with the test dates indicated above </i>
                                  <br />
                                  <div className="grid-overflow">
                                    {/* Sub-Loop 2.1 Start of Q36. Table 2 */}
                                    <table className="modal-popup-table-xlarge">
                                      <thead className="modal-popup-table-header">
                                        <tr>
                                          <th className="modal-popup-table-header-cell">
                                            Test{" "}
                                          </th>
                                          <th className="modal-popup-table-header-cell">
                                            Test performed
                                          </th>
                                          <th className="modal-popup-table-header-cell">
                                            Formula type
                                          </th>
                                          <th className="modal-popup-table-header-cell">
                                            Test result
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Absolute lymphocyte count (ALC)
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"alc_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"alc_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.alc_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"alc_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"alc_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"alc_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.alc_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"alc_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"alc_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"alc_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.alc_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"alc_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BAlcEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BAlcAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.alc_test_performed === 1 && (
                                              <div>NA</div>
                                            )}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.alc_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"alc_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"alc_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.alc_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"alc_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={2}
                                                    value={item.alc_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onInput={(e) => restrictDecimalPlaces(e)}
                                                  />
                                                  <label htmlFor={"alc_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      10<sup>9</sup>/L
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"alc_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"alc_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.alc_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor={"alc_test_result_unk_" + itemParentidx + "_of_" + itemidx}>
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DAlcEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DAlcResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DAlcAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DAlcResultRange}
                                                  message={q36ResultAlcOutOfRange}
                                                />
                                              </div>)}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Calcium
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"cal_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"cal_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.cal_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"cal_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"cal_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"cal_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.cal_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"cal_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"cal_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"cal_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.cal_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"cal_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BCalEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BCalAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.cal_test_performed === 1 && (
                                              <div>NA</div>
                                            )}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.cal_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"cal_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"cal_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.cal_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"cal_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={100}
                                                    value={item.cal_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onInput={(e) => restrictDecimalPlaces(e)}
                                                  />
                                                  <label htmlFor={"cal_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      mg/dL
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"cal_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"cal_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.cal_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor={"cal_test_result_unk_" + itemParentidx + "_of_" + itemidx}>
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DCalEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DCalResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DCalAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DCalResultRange}
                                                  message={q36ResultCalOutOfRange}
                                                />
                                              </div>)}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Serum creatinine
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"sc_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"sc_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.sc_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"sc_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"sc_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"sc_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.sc_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"sc_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"sc_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"sc_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.sc_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"sc_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BScEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BScAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.sc_test_performed === 1 && (
                                              <div>NA</div>
                                            )}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.sc_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"sc_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"sc_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.sc_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"sc_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={100}
                                                    value={item.sc_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onKeyDown={(e) => preventDecimalInput(e)}
                                                    onInput={(e) => restrictToWholeNumbers(e)}
                                                  />
                                                  <label htmlFor={"sc_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      mg/dL
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"sc_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"sc_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.sc_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor="creatinine_test_result_99">
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DScEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DScResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DScAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DScResultRange}
                                                  message={q36ResultScOutOfRange}
                                                />
                                              </div>)}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Creatinine clearance level (CrCl)
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"crcl_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"crcl_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.crcl_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"crcl_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"crcl_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"crcl_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.crcl_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"crcl_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"crcl_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"crcl_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.crcl_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"crcl_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BCrclEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BCrclAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.crcl_test_performed === 1 && (
                                              <div>
                                                Formula type:
                                                <div className="option-without-input option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={"crcl_formula_type_1_" + itemParentidx + "_of_" + itemidx}
                                                    name={"crcl_formula_type_1_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.crcl_formula_type === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <label htmlFor={"crcl_formula_type_1_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      Cockcroft-Gault
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={"crcl_formula_type_2_" + itemParentidx + "_of_" + itemidx}
                                                    name={"crcl_formula_type_2_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.crcl_formula_type === 2}
                                                    value={2}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <label htmlFor="crcl_formula_type_2">
                                                    <span className="">
                                                      Other, specify
                                                    </span>
                                                  </label>
                                                  <input
                                                    className="input-dash input_custome-space"
                                                    name={"crcl_formula_type_oth_" + itemParentidx + "_of_" + itemidx}
                                                    type="text"
                                                    maxLength={500}
                                                    value={item.crcl_formula_type_oth}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={"crcl_formula_type_99_" + itemParentidx + "_of_" + itemidx}
                                                    name={"crcl_formula_type_99_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.crcl_formula_type === 99}
                                                    value={99}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <label htmlFor={"crcl_formula_type_99_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2CCrclEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2CCrclOthTxtEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2COthTxtEmptyMsg}
                                                />
                                              </div>)}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.crcl_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"crcl_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"crcl_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.crcl_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"crcl_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={5}
                                                    value={item.crcl_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onKeyDown={(e) => preventDecimalInput(e)}
                                                    onInput={(e) => restrictToWholeNumbers(e)}
                                                  />
                                                  <label htmlFor={"crcl_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      mL/min
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"crcl_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"crcl_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.crcl_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor={"crcl_test_result_unk_" + itemParentidx + "_of_" + itemidx}>
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DCrclEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DCrclResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DCrclAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DCrclResultRange}
                                                  message={q36ResultCrclOutOfRange}
                                                />
                                              </div>)}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Serum lactate dehydrogenase (LDH)
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"ldh_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"ldh_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.ldh_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"ldh_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"ldh_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"ldh_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.ldh_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"ldh_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"ldh_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"ldh_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.ldh_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"ldh_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BLdhEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BLdhAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.ldh_test_performed === 1 && (
                                              <div>NA</div>
                                            )}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.ldh_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"ldh_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"ldh_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.ldh_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"ldh_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={5}
                                                    value={item.ldh_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onKeyDown={(e) => preventDecimalInput(e)}
                                                    onInput={(e) => restrictToWholeNumbers(e)}
                                                  />
                                                  <label htmlFor={"ldh_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      U/L
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"ldh_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"ldh_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.ldh_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor="ldh_test_result_unk_">
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DLdhEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DLdhResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DLdhAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DLdhResultRange}
                                                  message={q36ResultLdhOutOfRange}
                                                />
                                              </div>)}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Phosphorus
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"pho_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"pho_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.pho_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"pho_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"pho_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"pho_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.pho_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"pho_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"pho_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"pho_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.pho_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"pho_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BPhoEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BPhoAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.pho_test_performed === 1 && (
                                              <div>NA</div>
                                            )}
                                          </td>
                                          <td className="modal-popup-table-cell">

                                            {item.pot_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"pho_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"pho_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.pho_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"pho_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={5}
                                                    value={item.pho_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onKeyDown={(e) => preventDecimalInput(e)}
                                                    onInput={(e) => restrictToWholeNumbers(e)}
                                                  />
                                                  <label htmlFor={"pho_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      mg/dL
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"pho_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"pho_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.pho_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor="phosphorus_test_result_99">
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DPhoEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DPhoResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DPhoAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DPhoResultRange}
                                                  message={q36ResultPhoOutOfRange}
                                                />
                                              </div>
                                            )}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Potassium
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"pot_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"pot_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.pot_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"pot_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"pot_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"pot_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.pot_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"pot_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"pot_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"pot_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.pot_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"pot_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BPotEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BPotAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.pot_test_performed === 1 && (
                                              <div>NA</div>
                                            )}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.pot_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"pot_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"pot_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.pot_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"pot_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={100}
                                                    value={item.pot_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onKeyDown={(e) => preventDecimalInput(e)}
                                                    onInput={(e) => restrictToWholeNumbers(e)}
                                                  />
                                                  <label htmlFor={"pot_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      mmol/L
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"pot_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"pot_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.pot_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor={"pot_test_result_unk_" + itemParentidx + "_of_" + itemidx}>
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DPotEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DPotResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DPotAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DPotResultRange}
                                                  message={q36ResultPotOutOfRange}
                                                />
                                              </div>)}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="modal-popup-table-cell">
                                            Uric acid
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"uric_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                name={"uric_test_performed_1_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.uric_test_performed === 1}
                                                value={1}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"uric_test_performed_1_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"uric_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                name={"uric_test_performed_2_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.uric_test_performed === 2}
                                                value={2}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"uric_test_performed_2_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Not tested
                                                </span>
                                              </label>
                                            </div>
                                            <div className="option-without-input option-fbox">
                                              <input
                                                type="radio"
                                                id={"uric_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                name={"uric_test_performed_99_" + itemParentidx + "_of_" + itemidx}
                                                checked={item.uric_test_performed === 99}
                                                value={99}
                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                              />
                                              <label htmlFor={"uric_test_performed_99_" + itemParentidx + "_of_" + itemidx}>
                                                <span className="">
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={item.q36T2BUricEmpty}
                                              message={TLSMonitoring_Msgs.Radio}
                                            />
                                            <ErrorField
                                              show={item.q36T2BUricAllUnkNotTestedEmpty}
                                              message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                            />
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.uric_test_performed === 1 && (
                                              <div>NA</div>
                                            )}
                                          </td>
                                          <td className="modal-popup-table-cell">
                                            {item.uric_test_performed === 1 && (
                                              <div>
                                                <div className="option-without-input">
                                                  <input
                                                    type="radio"
                                                    id={"uric_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    name={"uric_test_result_checked_" + itemParentidx + "_of_" + itemidx}
                                                    checked={item.uric_test_result_checked === 1}
                                                    value={1}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                  />
                                                  <input
                                                    className="input-dash input_custome-space_sm"
                                                    name={"uric_test_result_" + itemParentidx + "_of_" + itemidx}
                                                    type="number"
                                                    maxLength={100}
                                                    value={item.uric_test_result}
                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    onKeyDown={(e) => preventDecimalInput(e)}
                                                    onInput={(e) => restrictToWholeNumbers(e)}
                                                  />
                                                  <label htmlFor={"uric_test_result_checked_" + itemParentidx + "_of_" + itemidx}>
                                                    <span className="">
                                                      mg/dL
                                                    </span>
                                                  </label>
                                                </div>
                                                <div className="option-without-input option-fbox">
                                                  <label className="option-fbox custom-spacing-lbl">
                                                    <input
                                                      type="radio"
                                                      id={"uric_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      name={"uric_test_result_unk_" + itemParentidx + "_of_" + itemidx}
                                                      checked={item.uric_test_result_unk === 99}
                                                      value={99}
                                                      onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, itemidx)}
                                                    />
                                                    <span htmlFor={"uric_test_result_unk_" + itemParentidx + "_of_" + itemidx}>
                                                      Unknown
                                                    </span>
                                                  </label>
                                                </div>
                                                <ErrorField
                                                  show={item.q36T2DUricEmpty}
                                                  message={TLSMonitoring_Msgs.Radio}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DUricResultEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DUricAllUnknownEmpty}
                                                  message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                />
                                                <ErrorField
                                                  show={item.q36T2DUricResultRange}
                                                  message={q36ResultUricOutOfRange}
                                                />
                                              </div>)}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <br />
                              </div>))}
                            <br />

                          </div>
                        ))}

                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showHideModalPopup && (
        <InfoPopUp
          description={RelevantBloodTests}
          OkClick={() => setShowHideModalPopup(false)}
        />
      )}
      {showHideModalPopupLink && (
        <InfoPopUp
          description={TLS_Table}
          OkClick={() => setShowHideModalPopupLink(false)}
        />
      )}
    </>
  );
};

export default TlsMonitoring;

