 /* <summary>
      date: 18-09-2024
      Name: ST
      description: Introduction Static Page
      <summary>*/

import React, { useState } from "react";
import { SideMenuItems } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import InfoPopUp from "../../Components/InfoPopUp";
import Appendix from "../../Components/Appendix";
import { useNavigate } from "react-router-dom";
const Introduction = () => {
  const navigatePaths = {
    prevPage: "",
    currPage: "/Introduction",
    nextPage: "/EligibilityCriteria",
  };

//   const [showHideModalPopup, setShowHideModalPopup] = useState(false);

  const navigate = useNavigate();
  const handleLabelClick = () => {
    // navigate("/Appendix"); 
    window.open('/Appendix#venetoclax-dosing', '_blank', 'noopener,noreferrer');
  };

  const SavePage = async () => {
    return true;
  };
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.Introduction} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>INTRODUCTION</h4>
              </div>

              <div className="survey-introduction-section">
                <div className="survey-introduction-content">
                  <div className="instructions">
                    <div>
                      <p>
                        Welcome and thank you for participating in this study
                        that will aim to answer several key research questions
                        to characterize venetoclax ramp-up processes (i.e.,
                        dosing schedule, settings, and labs collected) among
                        patients receiving venetoclax + obinutuzumab (VO) in 1L outside of clinical trials
                        in real-world settings among patients with CLL or small
                        lymphocytic lymphoma (SLL).
                        <br />
                        <br /> As a reminder, to be eligible for the study, the
                        selected patient(s) MUST meet all criteria listed below.{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    className="note-border-box"
                    style={{ border: "1px solid #000" }}
                  >
                    <div className="important-note">
                      <p>
                        <b>STUDY ELIGIBILITY CRITERIA - </b>
                        Please note that a patient does NOT have to be currently
                        alive to be eligible for the study.
                      </p>
                      <h6>
                        <b>
                          <u>Inclusion criteria:</u>
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Patient received a diagnosis for CLL/SLL (ICD-9
                          204.1x/ICD-10 C91.1x; ICD-9 200.8x/ICD-10 C83.0x)
                        </li>
                        <li>Patient was ≥ 18 years old at CLL/SLL diagnosis</li>
                        <li>
                          Patient initiated VO as 1L therapy for CLL/SLL on or
                          after May 15, 2019 (i.e., approval date for VO in 1L)
                          OR patient-initiated VO as 2L therapy but in their 1L
                          received a targeted agent for &lt; 30 days
                        </li>
                        <li>
                          Patient had a ramp-up period as per the Food and Drug
                          Administration (FDA){" "}
                          <span
                            className="modal-popup"
                            data-tooltip-id="encounterTip"
                            // onClick={() => setShowHideModalPopup(true)}
                            onClick={handleLabelClick}
                            // target="_blank"
                            // rel="noopener noreferrer"
                          >
                            label
                          </span>{" "}
                          as part of their treatment with VO
                        </li>
                        <li>
                          Patient received anti-hyperuricemics per-label (i.e.,
                          allopurinol or other xanthine oxidase inhibitors)
                          prior to the initiation of venetoclax
                        </li>
                        <li>
                          Patient had a low or medium tumor burden with CrCl ≥
                          80 mL/min at time of initiation of venetoclax as part
                          of VO therapy for CLL/SLL
                        </li>
                        <li>
                          The patient received CLL/SLL-related care exclusively
                          at the participating institution for VO therapy, and
                          the abstractor has access to complete information on
                          their clinical characteristics, mortality status,
                          treatment duration, Food and Drug Administration
                          (FDA)-required monitoring for venetoclax therapy
                          (i.e., for Tumor Lysis Syndrome (TLS)) including prophylactic measures
                          (hydration, anti-hyperuricemics) and treatment
                          management, up to the end of follow-up
                        </li>
                      </ul>

                      <br />

                      <h6>
                        <b>
                          <u>Exclusion criteria</u>
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Patient received VO therapy as part of a clinical
                          trial
                        </li>
                        <li>
                          Patient received strong CYP3A inhibitors or rasburicase during the 3 days prior to the initiation of venetoclax until the end of ramp-up
                        </li>
                        <li>
                          Patient had a medium tumor burden with CrCl &lt; 80
                          mL/min or high tumor burden at time of initiation of
                          venetoclax as part of VO therapy for CLL/SLL
                        </li>
                        <li>
                          Complete information is not available for the patient
                          receiving CLL/SLL-related care from 1L therapy onward
                          for the following: clinical characteristics, treatment
                          response, treatment duration and reason(s) for
                          treatment change
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                  <br />
                  <span style={{ color: "red" }}>
                    <b>
                      Disclaimer: <br />
                      Please note that the data collection form will not collect
                      any Protected Health Information (PHI). Because dates of
                      events can be considered as PHI, at the time of data
                      entry, a de-identification technique will be applied so
                      that all dates will be anonymized without complexifying
                      the data entry process. Through the data collection, you
                      will be provided with a tool in which you will be asked to
                      enter the date of a specific event as it appears in the
                      patient's chart, but no dates will be recorded. All dates
                      will be reset and only stored as a number of days relative
                      to a reference date; here, the date of the first-line
                      therapy for CLL/SLL which will become Day 0.
                    </b>
                  </span>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {showHideModalPopup && (
      <InfoPopUp
        description={<Appendix />}
        OkClick={() => setShowHideModalPopup(false)}
      />
      )} */}
    </>
  );
};

export default Introduction;
