import React, { useContext, useEffect, useState } from 'react';
import { SideMenuItems, SideMenuPageList, HTTPResponse, ToastMessageType } from '../Helpers/Enums';
import { useNavigate } from 'react-router';
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, isStringEmpty } from '../Helpers/Utilities';
import { PatientService } from "../WebApiServices/Patient.service";
import { AppContext } from '../Contexts/AppContextProvider';
import { disable } from 'workbox-navigation-preload';


const SideBarMenu = (props) => {

    const [incompletePageIds, setIncompletePageIds] = useState([]);
    const activePageId = props.activePageId;
    const patientID = GetLocalStorageData("patientId") ?? 0;
    const navigate = useNavigate();
    const { ShowToast, ToggleLoader, HandleSessionTimeout, appState, SetVenRampWeekCnt } = useContext(AppContext);
    //const { isSubmitted, formIncomplete } = useContext(AppContext);
    let patientData = GetLocalStorageData("patient");
    patientData = patientData && JSON.parse(patientData);

    const submit = patientData?.submit ?? 0;
    const locked = patientData?.locked ?? false;
    // const pid = patientData?.patientId ?? 0;
    // const userId = GetLocalStorageData("userId") ?? 0;

    const [pageList, setPageList] = useState(SideMenuPageList);

    const loadConfig = GetLocalStorageData("loadConfig") ?? false;
    const [eligible, setEligible] = useState(false);
    const [loading, setLoading] = useState(true);//https://ideawharf.sifterapp.com/issues/11480


    useEffect(() => {
        const FetchData = async () => {

            try {
                AddLocalStorageData("loadConfig", false);

                //ToggleLoader(true);
                const response1 = await PatientService.IsEligiblePatient(patientID);
                const isEligible = response1?.data ?? false;
                setEligible(isEligible)
                SetVenRampWeekCnt(0);
                if (response1?.status === HTTPResponse.OK) {

                    const response2 = await PatientService.GetPatientConfigData(patientID);
                    if (response2?.status === HTTPResponse.OK) {
                        if (response2 != null) {
                            const { data } = response2;
                            if (data != null) {
                                const { item1, item2 } = data;
                                //item1 => patient config static pages data 
                                //item2 => ven ramp-up week config data

                                let pglistClone = [...SideMenuPageList];

                                let staticPgs = [];
                                staticPgs = pglistClone.filter(a => a.id !== SideMenuItems.VenetoclaxRampupWeek).map(obj => {
                                    // Find the corresponding object in item1 by id
                                    let matchObj = item1.find(a => a.pageId === obj.id);
                                    //update incomplete prop
                                    if (matchObj) {
                                        return {
                                            ...obj,
                                            incomplete: matchObj.incomplete
                                        };
                                    }
                                    //no match
                                    return obj;
                                });

                                let listVenRampWeeks = [];
                                if (item2 != null && item2.length > 0) {
                                    SetVenRampWeekCnt(item2.length);
                                    item2.map((a, i) => {
                                        const wkItm = {
                                            id: a.pageId,
                                            title: `Week ${i + 1}`,
                                            path: "/VenetoclaxRampUpWeek",
                                            weekNum: i + 1,
                                            hasParent: true,
                                            incomplete: a.incomplete,
                                            disabled: a.disabled
                                        };
                                        listVenRampWeeks.push(wkItm);
                                    })
                                }

                                const finalList = [
                                    ...(staticPgs.filter(a => a.id < SideMenuItems.VenetoclaxRampupWeek)),
                                    ...listVenRampWeeks,
                                    ...(staticPgs.filter(a => a.id > SideMenuItems.VenetoclaxRampupWeek)),
                                ];

                                setPageList(finalList);
                            }
                        }
                        //ToggleLoader(false);
                    }
                    else if (response1?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    }
                }
                else if (response1?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            } catch (e) {
                //ToggleLoader(false);
            }
            finally {
                setLoading(false);
            }
        }

        FetchData();
    }, [loadConfig, appState.showCartPage])//, appState.showCartPage 


    /* <summary>
    date: 04-11-2023
    Name: AP
    description: Handle Navigation with Highlight on Validate/Submit Click.
      Edited the function. Removed highlighting of incomplete forms 
        inside a useEffect since it wasn't showing up on first click.
    <summary>*/
    const HandleNavigate = async (pageUrl, weekNum, pageId) => {
        try {
            if (pageId === SideMenuItems.ValidateSubmit) {
                const pid = GetLocalStorageData("patientId") ?? 0;
                const payload = {
                    pid: pid,
                    userId: GetLoggedInUserID(),
                };
                ToggleLoader(true)
                const response = await PatientService.SetPatientSubmitValue(payload);

                if (response?.status === HTTPResponse.OK) {
                    // Set Patient Submit Value in LS
                    const ptArr = { ...patientData };
                    ptArr.submit = response?.data;

                    AddLocalStorageData("patient", JSON.stringify(ptArr));
                }
            }

            if (patientID > 0) {
                //ToggleLoader(true);
                const response = await PatientService.IsEligiblePatient(patientID);
                const isEligible = response?.data ?? false;

                const ptArr = { ...patientData }
                AddLocalStorageData("patient", JSON.stringify(ptArr))

                if (isEligible) {
                    if (pageUrl === "/VenetoclaxRampUpWeek") {
                        navigate(pageUrl + "/" + weekNum)
                    }
                    else {
                        navigate(pageUrl);
                    }
                }
                else {
                    //Below code later remove when side bar locks are added
                    if (pageUrl === "/VenetoclaxRampUpWeek") {
                        navigate(pageUrl + "/" + weekNum)
                    }
                    //if (pageUrl.trim().includes("PatientScreening") || pageUrl.trim().includes("EligibilityCriteria")) {
                    else {
                        navigate(pageUrl);
                    }//}
                }
                //ToggleLoader(false)
            }
            else {
                navigate(pageUrl);
            }
            
            ToggleLoader(false);
        } catch {

        }
    }

    return (
        <div className="col-md-3 col-pad-right">
            <div className="tab tab-custom">
                <div className="activity-link-tab">
                    {!loading && (
                        pageList?.map((item) => (
                            <button key={item.id}
                                onClick={() => { HandleNavigate(item.path, item.weekNum, item.id) }}
                                className={`tablinks ${activePageId == item.id && "active"}`}
                                style={{ color: item.incomplete ? "red " : "white" }}
                                //disabled={item.notClickable || (((patientID === 0 || !eligible) && item.id > 2))}
                                disabled={item.notClickable ||
                                    (
                                        item.disabled ||
                                        //New Patient lock all except 1st page
                                        (patientID === 0 && item.id > SideMenuItems.EligibilityCriteria)
                                        ||
                                        //patient is eligible(metINclusionCriteria)
                                        (!eligible && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                                    )}
                            >
                                <span className={`${item.hasParent && "child"} ${item.hasGrandParent && "grandchild"}`}
                                >
                                    {
                                        //Chk if its a clickable tab
                                        !item.notClickable && (
                                            item.disabled ||
                                            //New Patient lock all except 1st page
                                            (patientID === 0 && item.id > SideMenuItems.EligibilityCriteria)
                                            ||
                                            //patient is eligible(metINclusionCriteria)
                                            (!eligible && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                                        )
                                        && <img className="lock-icon" src="../Assets/images/locked.png" alt="" />
                                    }

                                    {item.id !== SideMenuItems.ValidateSubmit
                                        ? <span>{item.title}</span>
                                        : (<span >
                                            {locked ? "SUBMITTED"
                                                // : submit === 1 && incompletePageIds.length === 0
                                                //   ? "Submit Patient" : item.title
                                                : item.title
                                            }
                                        </span>)
                                    }
                                </span>
                            </button>
                        ))
                    )}
                </div>
            </div>
        </div >
    )
}

export default SideBarMenu