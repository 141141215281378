import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessages, ToastMessageType } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import DateControl from "../../Components/DateControl";
import { GetLocalStorageData, GetLoggedInUserID, GetOrdinal, isStringEmpty, IsDateBefore, IsDateAfter, IsDtValid } from "../../Helpers/Utilities";
import { CommonError_Msgs, HealthCareVisit_Msgs } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import ErrorField from "../../Components/ErrorField";
import { HealthCareVisitsService } from "../../WebApiServices/HealthCareVisits.service";
import dayjs from "dayjs";

const HealthCareVisits = () => {
    const navigatePaths = {
        prevPage: "/TreatmentInterruptions",
        currPage: "/HealthCareVisits",
        nextPage: "/ValidateSubmit",
    };

    const pid = GetLocalStorageData("patientId") ?? 0;


    const {
        ShowToast,
        ToggleLoader,
        HandleSessionTimeout,
    } = useContext(AppContext);

    //SYJ 01/10/2024 Initialization
    const [ptOBVenetoInvalidRangeMsg, setPtOBVenetoInvalidRangeMsg] = useState("");
    const [ptVenetoInitInvalidRangeMsg, ssetPtVenetoInitInvalidRangeMsg] = useState("");
    const [showTopErrMsg, setShowTopErrMsg] = useState(false)
    const [isLoad, setISLoad] = useState(true);
    // Date used for validation
    const [indexdate, setindexdate] = useState("");
    const [venetoStartDate, setVenetoStartDate] = useState("");
    const [venetoEndDate, setVenetoEndDate] = useState("");
    const [venetoInitDate, setVenetoInitDate] = useState("");
    const [venetoLastDoseDate, setVenetoLastDoseDate] = useState("x");

    const [ptHRUMain, setPtHRUMain] = useState({
        userId: GetLoggedInUserID(),
        healthcarevisitid: 0,
        holds: null,
        coverageCommercial: false,
        coverageMedicare: false,
        coverageMedicaid: false,
        coverageMilitary: false,
        coverageOth: false,
        coverageOthTxt: "",
        insuranceBenifits: null,
        visitsIoivCount: "",
        visitsIoivCountUnk: null,
        visitsIvcvCount: "",
        visitIvcvCountUnk: null
    });


    const [ptHRUOV, setPtHRUOV] = useState([{
        userId: GetLoggedInUserID(),
        healthcarevisitbridgeid: 0,
        treatmentid: 1,
        categoryEmergencyVisi: false,
        categoryInoutVisit: false,
        categoryIcuVisit: false,
        categoryOth: false,
        categoryUnk: null,
        reasonTls: false,
        reasonNontls: false,
        tlsUnk: null,
        visitdate: null,
        visitdateUnk: null,
        visitdays: "",
        visitdaysTreatmentRestarted: null,
        hruCategoryEmpty: false,
        hruOtherValueEmpty: false,
        hruReasonsEmpty: false,
        hruVisitDateEmpty: false,
        hruNoVisitDateEmpty: false,
        hruInOutDaysEmpty: false,
        ptVenetoInvalidRangeValue: false,
        ptVenetoInvalidRangeMsg: "",
        inValidDate: false,
        venetoInitRamUpDateInvalid: false,
        venetoInitRamUpDateInvalidMsg: "",
        venetoRestartedInvalid: false,
        venetoRestartedInvalidDaysMsg: "",
    }]);

    const [ptHRUVeneto, setPtHRUVeneto] = useState([{
        userId: GetLoggedInUserID(),
        healthcarevisitbridgeid: 0,
        treatmentid: 2,
        categoryEmergencyVisi: false,
        categoryInoutVisit: false,
        categoryIcuVisit: false,
        categoryOth: false,
        categoryUnk: null,
        reasonTls: false,
        reasonNontls: false,
        tlsUnk: null,
        visitdate: null,
        visitdateUnk: null,
        visitdays: "",
        visitdaysTreatmentRestarted: null,
        hruCategoryEmpty: false,
        hruOtherValueEmpty: false,
        hruReasonsEmpty: false,
        hruVisitDateEmpty: false,
        hruNoVisitDateEmpty: false,
        hruInOutDaysEmpty: false,
        ptVenetoInvalidRangeValue: false,
        ptVenetoInvalidRangeMsg: "",
        inValidDate: false,
        venetoInitRamUpDateInvalid: false,
        venetoInitRamUpDateInvalidMsg: "",
        venetoRestartedInvalid: false,
        venetoRestartedInvalidDaysMsg: "",
    }]);


    const [hardErrList, setHardErrList] = useState({
        qHealthCoverageEmpty: false,
        qHealthCoverageOtherTextEmpty: false,
        qInsuranceEmpty: false,
        qLoopOVVentoEmpty: false,
        qLoopOVVentoTextEmpty: false,
        qLoopOVVentoInvalidRangeEmpty: false,
        qLoopVentoEmpty: false,
        qLoopVentoTextEmpty: false,
        qLoopVentoInvalidRangeEmpty: false,
    })


    useEffect(() => {
        GetHealthCareVisitData();
    }, []);


    const GetHealthCareVisitData = async () => {
        try {
            if (pid > 0) {
                ToggleLoader(true);
                setISLoad(true);
                setPtOBVenetoInvalidRangeMsg(false);
                ssetPtVenetoInitInvalidRangeMsg(false);
                setHardErrList({
                    qHealthCoverageEmpty: false,
                    qHealthCoverageOtherTextEmpty: false,
                    qInsuranceEmpty: false,
                    qLoopOVVentoEmpty: false,
                    qLoopOVVentoTextEmpty: false,
                    qLoopOVVentoInvalidRangeEmpty: false,
                    qLoopVentoEmpty: false,
                    qLoopVentoTextEmpty: false,
                    qLoopVentoInvalidRangeEmpty: false,
                });
                const response = await HealthCareVisitsService.GetHealthCareVisit(pid);

                if (response.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    } else throw response.error;
                } else {
                    if (response.data !== null && response.data !== undefined) {
                        setindexdate(response.data.indexDate);
                        setVenetoStartDate(response.data.venetoclaxStartDate);
                        setVenetoEndDate(response.data.venetoclaxEndDate);
                        setVenetoInitDate(response.data.venetoclaxInitiationDate);
                        setVenetoLastDoseDate(response.data.venetoclaxLastDoseDate);
                    }

                    if (response.data.healthCareVisitDetails != null) {
                        setPtHRUMain({
                            ...ptHRUMain,
                            healthcarevisitid:
                                response.data.healthCareVisitDetails.healthcarevisitid != null
                                    ? response.data.healthCareVisitDetails.healthcarevisitid
                                    : 0,
                            holds:
                                response.data.healthCareVisitDetails.holds != null
                                    ? response.data.healthCareVisitDetails.holds
                                    : null,
                            coverageCommercial: response.data.healthCareVisitDetails.coverageCommercial === true ? true : false,
                            coverageMedicare: response.data.healthCareVisitDetails.coverageMedicare === true ? true : false,
                            coverageMedicaid: response.data.healthCareVisitDetails.coverageMedicaid === true ? true : false,
                            coverageMilitary: response.data.healthCareVisitDetails.coverageMilitary === true ? true : false,
                            coverageOth: response.data.healthCareVisitDetails.coverageOth === true ? true : false,
                            coverageOthTxt: response.data.healthCareVisitDetails.coverageOthTxt != null
                                ? response.data.healthCareVisitDetails.coverageOthTxt
                                : "",
                            insuranceBenifits:
                                response.data.healthCareVisitDetails.insuranceBenifits != null
                                    ? response.data.healthCareVisitDetails.insuranceBenifits
                                    : 0,
                            visitsIoivCount:
                                response.data.healthCareVisitDetails.visitsIoivCount != null
                                    ? response.data.healthCareVisitDetails.visitsIoivCount
                                    : "",
                            visitsIoivCountUnk: response.data.healthCareVisitDetails.visitsIoivCount != null ? 1 :
                                response.data.healthCareVisitDetails.visitsIoivCountUnk != null && response.data.healthCareVisitDetails.visitsIoivCountUnk === true
                                    ? 99
                                    : 0,
                            visitsIvcvCount:
                                response.data.healthCareVisitDetails.visitsIvcvCount != null
                                    ? response.data.healthCareVisitDetails.visitsIvcvCount
                                    : "",
                            visitIvcvCountUnk: response.data.healthCareVisitDetails.visitsIvcvCount != null ? 1 :
                                response.data.healthCareVisitDetails.visitIvcvCountUnk != null && response.data.healthCareVisitDetails.visitIvcvCountUnk === true
                                    ? 99
                                    : 0,
                        });
                    }

                    if (
                        response.data.healthCareOBVenDetails != null &&
                        response.data.healthCareOBVenDetails.length > 0
                    ) {

                        var obVenetoArray = [];
                        for (let i = 0; i < response.data.healthCareOBVenDetails.length; i++) {
                            var obVenetocomponent = {
                                healthcarevisitbridgeid:
                                    response.data.healthCareOBVenDetails[i].healthcarevisitbridgeid != null
                                        ? response.data.healthCareOBVenDetails[i].healthcarevisitbridgeid
                                        : 0,
                                treatmentid: response.data.healthCareOBVenDetails[i].treatmentid != null
                                    ? response.data.healthCareOBVenDetails[i].treatmentid
                                    : 0,

                                categoryEmergencyVisi: response.data.healthCareOBVenDetails[i].categoryEmergencyVisi === true ? true : false,
                                categoryInoutVisit: response.data.healthCareOBVenDetails[i].categoryInoutVisit === true ? true : false,
                                categoryIcuVisit: response.data.healthCareOBVenDetails[i].categoryIcuVisit === true ? true : false,
                                categoryOth: response.data.healthCareOBVenDetails[i].categoryOth === true ? true : false,
                                categoryUnk: response.data.healthCareOBVenDetails[i].categoryUnk === true ? 99 : null,

                                reasonTls: response.data.healthCareOBVenDetails[i].reasonTls === true ? true : false,
                                reasonNontls: response.data.healthCareOBVenDetails[i].reasonNontls === true ? true : false,

                                tlsUnk: response.data.healthCareOBVenDetails[i].reasonUnk === true ? 99 : null,

                                visitdate: response.data.healthCareOBVenDetails[i].visitdate != null
                                    ? response.data.healthCareOBVenDetails[i].visitdate
                                    : null,
                                visitdateUnk: response.data.healthCareOBVenDetails[i].visitdateUnk === true ? 99 : response.data.healthCareOBVenDetails[i].visitdate != null && response.data.healthCareOBVenDetails[i].visitdate != "" && response.data.healthCareOBVenDetails[i].visitdate != undefined ? 1 : null,

                                visitdays: response.data.healthCareOBVenDetails[i].visitdays != null && response.data.healthCareOBVenDetails[i].visitdays != undefined && response.data.healthCareOBVenDetails[i].visitdays != ""
                                    ? response.data.healthCareOBVenDetails[i].visitdays
                                    : "",
                                visitdaysTreatmentRestarted: response.data.healthCareOBVenDetails[i].visitdays != null && response.data.healthCareOBVenDetails[i].visitdays != undefined
                                    && response.data.healthCareOBVenDetails[i].visitdays != "" ? 1 : response.data.healthCareOBVenDetails[i].visitdaysTreatmentRestarted != null ?
                                    response.data.healthCareOBVenDetails[i].visitdaysTreatmentRestarted : null,
                                hruCategoryEmpty: false,
                                hruOtherValueEmpty: false,
                                hruReasonsEmpty: false,
                                hruVisitDateEmpty: false,
                                hruNoVisitDateEmpty: false,
                                hruInOutDaysEmpty: false,
                                ptVenetoInvalidRangeValue: false,
                                ptVenetoInvalidRangeMsg: "",
                                venetoInitRamUpDateInvalid: false,
                                venetoInitRamUpDateInvalidMsg: "",
                                venetoRestartedInvalid: false,
                                venetoRestartedInvalidDaysMsg: "",
                                inValidDate: false,
                            };
                            obVenetoArray.push(obVenetocomponent);
                        }
                        setPtHRUOV(obVenetoArray);
                    }
                    if (
                        response.data.healthCareVenDetails != null &&
                        response.data.healthCareVenDetails.length > 0
                    ) {

                        var venetoArray = [];
                        for (let i = 0; i < response.data.healthCareVenDetails.length; i++) {
                            var venetocomponent = {
                                healthcarevisitbridgeid:
                                    response.data.healthCareVenDetails[i].healthcarevisitbridgeid !== null
                                        ? response.data.healthCareVenDetails[i].healthcarevisitbridgeid
                                        : 0,
                                treatmentid: response.data.healthCareVenDetails[i].treatmentid != null
                                    ? response.data.healthCareVenDetails[i].treatmentid
                                    : 0,

                                categoryEmergencyVisi: response.data.healthCareVenDetails[i].categoryEmergencyVisi === true ? true : false,
                                categoryInoutVisit: response.data.healthCareVenDetails[i].categoryInoutVisit === true ? true : false,
                                categoryIcuVisit: response.data.healthCareVenDetails[i].categoryIcuVisit === true ? true : false,
                                categoryOth: response.data.healthCareVenDetails[i].categoryOth === true ? true : false,
                                categoryUnk: response.data.healthCareVenDetails[i].categoryUnk === true ? 99 : null,

                                reasonTls: response.data.healthCareVenDetails[i].reasonTls === true ? true : false,
                                reasonNontls: response.data.healthCareVenDetails[i].reasonNontls === true ? true : false,

                                tlsUnk: response.data.healthCareVenDetails[i].reasonUnk === true ? 99 : null,

                                visitdate: response.data.healthCareVenDetails[i].visitdate != null && response.data.healthCareVenDetails[i].visitdate != ""
                                    && response.data.healthCareVenDetails[i].visitdate != undefined
                                    ? response.data.healthCareVenDetails[i].visitdate
                                    : null,
                                visitdateUnk: response.data.healthCareVenDetails[i].visitdateUnk === true ? 99 : response.data.healthCareVenDetails[i].visitdate != null && response.data.healthCareVenDetails[i].visitdate != "" && response.data.healthCareVenDetails[i].visitdate != undefined ? 1 : null,

                                visitdays: response.data.healthCareVenDetails[i].visitdays != null && response.data.healthCareVenDetails[i].visitdays != undefined && response.data.healthCareVenDetails[i].visitdays != ""
                                    && response.data.healthCareVenDetails[i].visitdays != ""
                                    ? response.data.healthCareVenDetails[i].visitdays
                                    : "",
                                visitdaysTreatmentRestarted: response.data.healthCareVenDetails[i].visitdays != null && response.data.healthCareVenDetails[i].visitdays != undefined
                                    && response.data.healthCareVenDetails[i].visitdays != "" ? 1
                                    : response.data.healthCareVenDetails[i].visitdaysTreatmentRestarted != null ?
                                        response.data.healthCareVenDetails[i].visitdaysTreatmentRestarted : null,
                                hruCategoryEmpty: false,
                                hruOtherValueEmpty: false,
                                hruReasonsEmpty: false,
                                hruVisitDateEmpty: false,
                                hruNoVisitDateEmpty: false,
                                hruInOutDaysEmpty: false,
                                ptVenetoInvalidRangeValue: false,
                                ptVenetoInvalidRangeMsg: "",
                                venetoInitRamUpDateInvalid: false,
                                venetoInitRamUpDateInvalidMsg: "",
                                venetoRestartedInvalid: false,
                                venetoRestartedInvalidDaysMsg: "",
                                inValidDate: false,
                            };
                            venetoArray.push(venetocomponent);
                        }
                        setPtHRUVeneto(venetoArray);
                    }
                }
            }
            ToggleLoader(false);
        }
        catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }

    const SavePage = async (validate) => {
        try {
            ToggleLoader(true);
            let isValid = validate ? ValidateForm() : true;

            if (isValid) {
                setShowTopErrMsg(false);
                var hruDataToSave = {
                    HealthCareVisitModel: {
                        HealthCareVisitDetails: {
                            Holds: ptHRUMain.holds,
                            CoverageCommercial: ptHRUMain.coverageCommercial,
                            CoverageMedicare: ptHRUMain.coverageMedicare,
                            CoverageMedicaid: ptHRUMain.coverageMedicaid,
                            CoverageMilitary: ptHRUMain.coverageMilitary,
                            CoverageOth: ptHRUMain.coverageOth,
                            CoverageOthTxt: ptHRUMain.coverageOthTxt,
                            InsuranceBenifits: ptHRUMain.insuranceBenifits,
                            VisitsIoivCount: ptHRUMain.visitsIoivCount !== null && ptHRUMain.visitsIoivCount !== undefined && ptHRUMain.visitsIoivCount !== "" ? parseInt(ptHRUMain.visitsIoivCount) : null,
                            VisitsIoivCountUnk: ptHRUMain.visitsIoivCount > 0 || ptHRUMain.visitsIoivCountUnk === 1 ? false : ptHRUMain.visitsIoivCountUnk,
                            VisitsIvcvCount: ptHRUMain.visitsIvcvCount !== null && ptHRUMain.visitsIvcvCount !== undefined && ptHRUMain.visitsIvcvCount !== "" ? parseInt(ptHRUMain.visitsIvcvCount) : null,
                            VisitIvcvCountUnk: ptHRUMain.visitsIvcvCount > 0 || ptHRUMain.visitIvcvCountUnk === 1 ? false : ptHRUMain.visitIvcvCountUnk,
                            loggedInUserID: GetLoggedInUserID(),
                        },
                        HealthCareOBVenDetails: [],
                        HealthCareVenDetails: [],
                        patientid: pid ?? 0,
                        indexDate: "",
                        venetoclaxStartDate: "",
                        venetoclaxEndDate: "",
                        VenetoclaxInitiationDate: "",
                        VenetoclaxLastDoseDate: "",
                        loggedInUserID: GetLoggedInUserID(),
                    }
                }
                if (ptHRUOV.length > 0 && ptHRUMain.visitsIoivCount > 0) {
                    for (var i = 0; i < ptHRUOV.length; i++) {
                        var hruOBVeneto = {
                            Healthcarevisitbridgeid: ptHRUOV[i].healthcarevisitbridgeid,
                            Treatmentid: 1,
                            CategoryEmergencyVisi: ptHRUOV[i].categoryEmergencyVisi,
                            CategoryInoutVisit: ptHRUOV[i].categoryInoutVisit,
                            CategoryIcuVisit: ptHRUOV[i].categoryIcuVisit,
                            CategoryOth: ptHRUOV[i].categoryOth,
                            CategoryUnk: ptHRUOV[i].categoryUnk === 99 ? true : false,
                            ReasonTls: ptHRUOV[i].reasonTls,
                            ReasonNontls: ptHRUOV[i].reasonNontls,
                            ReasonUnk: ptHRUOV[i].tlsUnk,
                            Visitdate: ptHRUOV[i].visitdate !== "Invalid Date" && ptHRUOV[i].visitdate !== "" && ptHRUOV[i].visitdate !== null && ptHRUOV[i].visitdate !== undefined ? ptHRUOV[i].visitdate : null,
                            VisitdateUnk: ptHRUOV[i].visitdate !== "" && ptHRUOV[i].visitdate !== null && ptHRUOV[i].visitdate !== undefined ? "" : ptHRUOV[i].visitdateUnk,
                            Visitdays: ptHRUOV[i].visitdays !== "" && ptHRUOV[i].visitdays !== null && ptHRUOV[i].visitdays !== undefined ? parseInt(ptHRUOV[i].visitdays) : null,
                            VisitdaysTreatmentRestarted: ptHRUOV[i].visitdaysTreatmentRestarted === 1 ? null : ptHRUOV[i].visitdaysTreatmentRestarted,
                        };
                        hruDataToSave.HealthCareVisitModel.HealthCareOBVenDetails.push(hruOBVeneto);
                    }
                }

                if (ptHRUVeneto.length > 0 && ptHRUMain.visitsIvcvCount > 0) {
                    for (var i = 0; i < ptHRUVeneto.length; i++) {
                        var hruVeneto = {
                            Healthcarevisitbridgeid: ptHRUVeneto[i].healthcarevisitbridgeid,
                            Treatmentid: 2,
                            CategoryEmergencyVisi: ptHRUVeneto[i].categoryEmergencyVisi,
                            CategoryInoutVisit: ptHRUVeneto[i].categoryInoutVisit,
                            CategoryIcuVisit: ptHRUVeneto[i].categoryIcuVisit,
                            CategoryOth: ptHRUVeneto[i].categoryOth,
                            CategoryUnk: ptHRUVeneto[i].categoryUnk === 99 ? true : false,
                            ReasonTls: ptHRUVeneto[i].reasonTls,
                            ReasonNontls: ptHRUVeneto[i].reasonNontls,
                            ReasonUnk: ptHRUVeneto[i].tlsUnk,
                            Visitdate: ptHRUVeneto[i].visitdate !== "Invalid Date" && ptHRUVeneto[i].visitdate !== "" && ptHRUVeneto[i].visitdate !== null && ptHRUVeneto[i].visitdate !== undefined ? ptHRUVeneto[i].visitdate : null,
                            VisitdateUnk: ptHRUVeneto[i].visitdate !== "" && ptHRUVeneto[i].visitdate !== null && ptHRUVeneto[i].visitdate !== undefined ? "" : ptHRUVeneto[i].visitdateUnk,
                            Visitdays: ptHRUVeneto[i].visitdays !== "" && ptHRUVeneto[i].visitdays !== null && ptHRUVeneto[i].visitdays !== undefined ? parseInt(ptHRUVeneto[i].visitdays) : null,
                            VisitdaysTreatmentRestarted: ptHRUVeneto[i].visitdaysTreatmentRestarted === 1 ? null : ptHRUVeneto[i].visitdaysTreatmentRestarted,
                        };
                        hruDataToSave.HealthCareVisitModel.HealthCareVenDetails.push(hruVeneto);
                    }
                }

                if (pid > 0) {
                    var response = await HealthCareVisitsService.SaveHealthCareVisit(hruDataToSave.HealthCareVisitModel);
                    ToggleLoader(false);

                    if (response.status !== HTTPResponse.OK) {
                        if (response?.status === HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();
                        } else throw response.error;
                    } else {
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                        await GetHealthCareVisitData();
                    }
                }
                else {
                    ToggleLoader(false);
                    ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
                }
                return true
            } else {
                ToggleLoader(false);
                setShowTopErrMsg(true);
                return false;
            }
        }
        catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    };

    const HandleFieldChange = (e) => {

        const { name, id, value } = e.target;
        switch (name) {
            case "coverageCommercial": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: !prev.coverageCommercial,
                    holds: null,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qHealthCoverageEmpty: false,
                }))
                break;
            }
            case "coverageMedicare": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: !prev.coverageMedicare,
                    holds: null,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qHealthCoverageEmpty: false,
                }))
                break;
            }
            case "coverageMedicaid": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: !prev.coverageMedicaid,
                    holds: null,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qHealthCoverageEmpty: false,
                }))
                break;
            }
            case "coverageMilitary": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: !prev.coverageMilitary,
                    holds: null,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qHealthCoverageEmpty: false,
                }))
                break;
            }
            case "coverageOth": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: !prev.coverageOth,
                    holds: null,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qHealthCoverageEmpty: false,
                }))

                if (!isStringEmpty(value) && value === "on") {
                    setPtHRUMain((prev) => ({
                        ...prev,
                        coverageOthTxt: ""
                    }))
                }

                break;
            }
            case "coverageOthTxt": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: value,
                    coverageOth: true,
                    holds: null,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qHealthCoverageEmpty: false,
                    qHealthCoverageOtherTextEmpty: false
                }))
                break;
            }
            case "holds": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: parseInt(value),
                    coverageCommercial: false,
                    coverageMedicare: false,
                    coverageMedicaid: false,
                    coverageMilitary: false,
                    coverageOth: false,
                    coverageOthTxt: "",
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qHealthCoverageEmpty: false,
                    qHealthCoverageOtherTextEmpty: false,
                }))
                break;
            }
            case "insuranceBenifits": {
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: parseInt(value),
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qInsuranceEmpty: false,
                }))
                break;
            }
            case "visitsIoivCount": {
                const isInvalidRange = parseInt(value) < 0 || parseInt(value) > 10;
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: value,
                    visitsIoivCountUnk: 1,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qLoopOVVentoEmpty: false,
                    qLoopOVVentoInvalidRangeEmpty: isInvalidRange,
                    qLoopOVVentoTextEmpty: false,
                }))

                if (isInvalidRange) {
                    let msg = HealthCareVisit_Msgs.hruInOutOBVenetoInvalidRange.replace('[value]', value);
                    msg = msg.replace('[value units]', 'healthcare visits');

                    setPtOBVenetoInvalidRangeMsg(msg);
                } else {
                    setHardErrList((prev) => ({
                        ...prev,
                        qLoopOVVentoEmpty: false,
                        qLoopOVVentoInvalidRangeEmpty: isInvalidRange,
                    }))
                    setPtOBVenetoInvalidRangeMsg("");
                }
                setISLoad(false);
                break;
            }
            case "visitsIoivCountUnk": {
                if (id === "in_Out_Unk") {
                    setPtHRUMain((prev) => ({
                        ...prev,
                        [name]: parseInt(value),
                        visitsIoivCount: "",
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        qLoopOVVentoEmpty: false,
                        qLoopOVVentoInvalidRangeEmpty: false,
                        qLoopOVVentoTextEmpty: false,
                    }))
                    setPtHRUOV([]);
                }
                else {
                    setPtHRUMain((prev) => ({
                        ...prev,
                        [name]: parseInt(value),
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        qLoopOVVentoEmpty: false
                    }))
                }

                break;
            }
            case "visitsIvcvCount": {
                const isInvalidRange = parseInt(value) < 0 || parseInt(value) > 10;
                setPtHRUMain((prev) => ({
                    ...prev,
                    [name]: value,
                    visitIvcvCountUnk: 1,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    qLoopVentoEmpty: false,
                    qLoopVentoInvalidRangeEmpty: isInvalidRange,
                    qLoopVentoTextEmpty: false,
                }));

                if (isInvalidRange) {
                    let msg = HealthCareVisit_Msgs.hruInOutOBVenetoInvalidRange.replace('[value]', value);
                    msg = msg.replace('[value units]', 'healthcare visits');

                    ssetPtVenetoInitInvalidRangeMsg(msg);
                } else {
                    setHardErrList((prev) => ({
                        ...prev,
                        qLoopVentoEmpty: false,
                        qLoopVentoInvalidRangeEmpty: isInvalidRange
                    }));

                    ssetPtVenetoInitInvalidRangeMsg("");
                }
                setISLoad(false);

                break;
            }
            case "visitIvcvCountUnk": {
                if (id === "veneto_HRU_Visit_99") {
                    setPtHRUMain((prev) => ({
                        ...prev,
                        [name]: parseInt(value),
                        visitsIvcvCount: "",
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        qLoopVentoEmpty: false,
                        qLoopVentoInvalidRangeEmpty: false,
                        qLoopVentoTextEmpty: false,
                    }));

                    setPtHRUVeneto([]);
                }
                else {
                    setPtHRUMain((prev) => ({
                        ...prev,
                        [name]: parseInt(value),
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        qLoopVentoEmpty: false
                    }));
                }

                break;
            }
            default:
                break;
        }
    }

    //SYJ Looping Handle Change for Venetoclax
    const HandleObinutuzumabVenCategoryRbtnChange = (e) => {
        const { name, value } = e.target;
        const lastIndex = name.lastIndexOf("_");
        const idx = name.slice(lastIndex + 1);
        const optionName = name;

        switch (optionName) {
            case "categoryUnk_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].categoryEmergencyVisi = false;
                    Qarray[idx].categoryInoutVisit = false;
                    Qarray[idx].categoryIcuVisit = false;
                    Qarray[idx].categoryOth = false;
                    Qarray[idx].categoryUnk = parseInt(value);
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;

            case "tlsUnk_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].reasonTls = false;
                    Qarray[idx].reasonNontls = false;
                    Qarray[idx].tlsUnk = parseInt(value);
                    Qarray[idx].hruReasonsEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;
            default:
                break;
        }
    }

    const HandleObinutuzumabVenCategoryChkChange = (e) => {
        const { name, checked } = e.target;
        const lastIndex = name.lastIndexOf("_");
        const idx = name.slice(lastIndex + 1);
        const optionName = name;

        switch (optionName) {
            case "categoryEmergencyVisi_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].categoryEmergencyVisi = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;

            case "categoryInoutVisit_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].categoryInoutVisit = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;

            case "categoryIcuVisit_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].categoryIcuVisit = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;

            case "categoryOth_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].categoryOth = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;


            case "reasonTls_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].reasonTls = checked;
                    Qarray[idx].tlsUnk = null;
                    Qarray[idx].hruReasonsEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;

            case "reasonNontls_" + idx:
                {
                    let Qarray = [...ptHRUOV];
                    Qarray[idx].reasonNontls = checked;
                    Qarray[idx].tlsUnk = null;
                    Qarray[idx].hruReasonsEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;

            default:
                break;
        }
    };

    const HandleObinutuzumabTreatmentRestartedTextChange = (e) => {
        const { id, value } = e.target;
        const isInvalidRange = parseInt(value) < 0 || parseInt(value) > 14;

        let Qarray = [...ptHRUOV];
        let visitDate = new Date(Qarray[id].visitdate); //Q45 (date)
        let additionalDays = parseInt(value); // Q46 (days)
        let venetoStartDt = venetoStartDate ? new Date(venetoStartDate) : null; // Venetoclax Start Date

        Qarray[id].visitdays = value;
        Qarray[id].visitdaysTreatmentRestarted = 1;
        Qarray[id].hruInOutDaysEmpty = false;

        if (visitDate && additionalDays !== null && additionalDays != "" && venetoStartDate) {
            // Add Q46 (days) to Q45 (visit date)
            let updatedVisitDate = new Date(visitDate);
            updatedVisitDate.setDate(updatedVisitDate.getDate() + parseInt(additionalDays));

            // Check if the updated visit date exceeds Venetoclax Start Date
            if (IsDateAfter(updatedVisitDate, venetoStartDt)) {
                let msg = HealthCareVisit_Msgs.venetoRestartedInvalidDays.replace('[insert Venetoclax Start Date]', venetoStartDate);

                Qarray[id].venetoRestartedInvalid = true;
                Qarray[id].venetoRestartedInvalidDaysMsg = msg;
            } else {
                Qarray[id].venetoRestartedInvalid = false;
                Qarray[id].venetoRestartedInvalidDaysMsg = "";
            }
        } else {
            Qarray[id].venetoRestartedInvalid = false;
            Qarray[id].venetoRestartedInvalidDaysMsg = "";
        }

        if (isInvalidRange) {
            let msg = HealthCareVisit_Msgs.hruInitVenetoInvalidRange.replace('[value]', value);
            msg = msg.replace('[value units]', 'days');

            Qarray[id].ptVenetoInvalidRangeValue = true;
            Qarray[id].ptVenetoInvalidRangeMsg = msg;

        } else {
            Qarray[id].ptVenetoInvalidRangeValue = false;
            Qarray[id].ptVenetoInvalidRangeMsg = "";
        }

        setPtHRUOV(Qarray);
    };

    const HandleTreatmentRestartedObinutuzumabVenLoop = (e) => {
        const { id, value } = e.target;
        let Qarray = [...ptHRUOV];
        Qarray[id].visitdays = "";
        Qarray[id].visitdaysTreatmentRestarted = parseInt(value);
        Qarray[id].hruInOutDaysEmpty = false;
        Qarray[id].ptVenetoInvalidRangeValue = false;
        Qarray[id].venetoRestartedInvalid = false;
        Qarray[id].venetoRestartedInvalidDaysMsg = "";
        setPtHRUOV(Qarray);
    };

    const HandleObinutuzumabVenetoInOutDateChange = (e) => {
        const { id, value } = e.target;
        let Qarray = [...ptHRUOV];
        Qarray[id].visitdate = null;
        Qarray[id].visitdateUnk = parseInt(value);
        Qarray[id].hruVisitDateEmpty = false;
        Qarray[id].hruNoVisitDateEmpty = false;
        Qarray[id].venetoInitRamUpDateInvalid = false;
        Qarray[id].venetoInitRamUpDateInvalidMsg = "";
        Qarray[id].venetoRestartedInvalid = false;
        Qarray[id].venetoRestartedInvalidDaysMsg = "";
        Qarray[id].inValidDate = false;
        setPtHRUOV(Qarray);
    };

    const HandleObinutuzumabDateChange = (name, date, optionId, index) => {
        const lastIndex = name.lastIndexOf("_");
        const idx = name.slice(lastIndex + 1);

        // Parse the date strings into JavaScript Date objects
        let indexDt = indexdate ? new Date(indexdate) : null; // Index Date
        let venetoStartDt = venetoStartDate ? new Date(venetoStartDate) : null; // Venetoclax Start Date

        switch (optionId) {
            case "visitdate":
                {

                    let Qarray = [...ptHRUOV];
                    let visitDate = new Date(date);

                    if (!IsDtValid(visitDate)) {
                        Qarray[idx].inValidDate = true;
                    }
                    else {
                        Qarray[idx].inValidDate = false;
                        if (IsDateBefore(visitDate, indexDt) || IsDateAfter(visitDate, venetoStartDt)) {
                            let msg = HealthCareVisit_Msgs.venetoInitRamUpInvalidDate.replace('[insert Index Date]', indexdate);
                            msg = msg.replace('[insert Venetoclax Start Date]', venetoStartDate);

                            Qarray[idx].venetoInitRamUpDateInvalid = true;
                            Qarray[idx].venetoInitRamUpDateInvalidMsg = msg;
                        } else {
                            Qarray[idx].venetoInitRamUpDateInvalid = false;
                            Qarray[idx].venetoInitRamUpDateInvalidMsg = "";
                        }
                    }


                    Qarray[idx][optionId] = date;
                    Qarray[idx].visitdateUnk = 1;
                    Qarray[idx].hruVisitDateEmpty = false;
                    Qarray[idx].hruNoVisitDateEmpty = false;
                    setPtHRUOV(Qarray);
                }
                break;

            default:
                break;
        }
    };


    //SYJ Looping Handle Change for Venetoclax
    const HandleVenCategoryRbtnChange = (e) => {
        const { name, value } = e.target;
        const lastIndex = name.lastIndexOf("_");
        const idx = name.slice(lastIndex + 1);
        const optionName = name;

        switch (optionName) {
            case "categoryUnkown_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].categoryEmergencyVisi = false;
                    Qarray[idx].categoryInoutVisit = false;
                    Qarray[idx].categoryIcuVisit = false;
                    Qarray[idx].categoryOth = false;
                    Qarray[idx].categoryUnk = parseInt(value);
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;

            case "tlsUnkown_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].reasonTls = false;
                    Qarray[idx].reasonNontls = false;
                    Qarray[idx].tlsUnk = parseInt(value);
                    Qarray[idx].hruReasonsEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;
            default:
                break;
        }
    }

    const HandleVenCategoryChkChange = (e) => {
        const { name, checked } = e.target;
        const lastIndex = name.lastIndexOf("_");
        const idx = name.slice(lastIndex + 1);
        const optionName = name;

        switch (optionName) {
            case "categoryEmergencyVisi_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].categoryEmergencyVisi = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;

            case "categoryInoutVisit_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].categoryInoutVisit = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;

            case "categoryIcuVisit_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].categoryIcuVisit = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;

            case "categoryOth_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].categoryOth = checked;
                    Qarray[idx].categoryUnk = null;
                    Qarray[idx].hruCategoryEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;


            case "reasonTls_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].reasonTls = checked;
                    Qarray[idx].tlsUnk = null;
                    Qarray[idx].hruReasonsEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;

            case "reasonNontls_" + idx:
                {
                    let Qarray = [...ptHRUVeneto];
                    Qarray[idx].reasonNontls = checked;
                    Qarray[idx].tlsUnk = null;
                    Qarray[idx].hruReasonsEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;

            default:
                break;
        }
    };

    const HandleTreatmentRestartedVenLoop = (e) => {
        const { id, value } = e.target;
        let Qarray = [...ptHRUVeneto];
        Qarray[id].visitdays = "";
        Qarray[id].visitdaysTreatmentRestarted = parseInt(value);
        Qarray[id].hruInOutDaysEmpty = false;
        Qarray[id].ptVenetoInvalidRangeValue = false;
        Qarray[id].venetoRestartedInvalid = false;
        Qarray[id].venetoRestartedInvalidDaysMsg = "";
        setPtHRUVeneto(Qarray);
    };


    const HandleTreatmentRestartedTextChange = (e) => {
        const { id, value } = e.target;
        const isInvalidRange = parseInt(value) < 0 || parseInt(value) > 14;
        let Qarray = [...ptHRUVeneto];
        Qarray[id].visitdays = parseInt(value);
        Qarray[id].visitdaysTreatmentRestarted = 1;
        Qarray[id].hruInOutDaysEmpty = false;

        let visitDate = new Date(Qarray[id].visitdate); //Q45 (date)
        let additionalDays = parseInt(value); // Q46 (days)
        let venetoEndDt = venetoEndDate ? new Date(venetoEndDate) : null; // Venetoclax Start Date


        if (visitDate && additionalDays !== null && additionalDays != "" && venetoEndDt) {
            // Add Q46 (days) to Q45 (visit date)
            let updatedVisitDate = new Date(visitDate);
            updatedVisitDate.setDate(updatedVisitDate.getDate() + parseInt(additionalDays));

            // Check if the updated visit date exceeds Venetoclax Start Date
            if (IsDateAfter(updatedVisitDate, venetoEndDt)) {
                let msg = HealthCareVisit_Msgs.venetoRestartedCompleteInvalidDays.replace('[insert Venetoclax End Date]', venetoEndDate);

                Qarray[id].venetoRestartedInvalid = true;
                Qarray[id].venetoRestartedInvalidDaysMsg = msg;
            } else {
                Qarray[id].venetoRestartedInvalid = false;
                Qarray[id].venetoRestartedInvalidDaysMsg = "";
            }
        } else {
            Qarray[id].venetoRestartedInvalid = false;
            Qarray[id].venetoRestartedInvalidDaysMsg = "";
        }

        if (isInvalidRange) {
            let msg = HealthCareVisit_Msgs.hruInitVenetoInvalidRange.replace('[value]', value);
            msg = msg.replace('[value units]', 'days');

            Qarray[id].ptVenetoInvalidRangeValue = true;
            Qarray[id].ptVenetoInvalidRangeMsg = msg;

        } else {
            Qarray[id].ptVenetoInvalidRangeValue = false;
            Qarray[id].ptVenetoInvalidRangeMsg = "";
        }

        setPtHRUVeneto(Qarray);
    };

    const HandleVenetoInOutDateChange = (e) => {
        const { id, value } = e.target;
        let Qarray = [...ptHRUVeneto];
        Qarray[id].visitdate = null;
        Qarray[id].visitdateUnk = parseInt(value);
        Qarray[id].hruVisitDateEmpty = false;
        Qarray[id].hruNoVisitDateEmpty = false;
        Qarray[id].venetoInitRamUpDateInvalid = false;
        Qarray[id].venetoInitRamUpDateInvalidMsg = "";
        Qarray[id].venetoRestartedInvalid = false;
        Qarray[id].venetoRestartedInvalidDaysMsg = "";
        Qarray[id].inValidDate = false;
        setPtHRUVeneto(Qarray);
    };

    const HandleDateChange = (name, date, optionId, index) => {
        const lastIndex = name.lastIndexOf("_");
        const idx = name.slice(lastIndex + 1);

        let venetoStartDt = venetoStartDate ? new Date(venetoStartDate) : null; // Venetoclax Start Date
        let venetoEndDt = venetoEndDate ? new Date(venetoEndDate) : null; // Venetoclax End Date

        switch (optionId) {
            case "visitdate":
                {
                    let Qarray = [...ptHRUVeneto];

                    let visitDate = new Date(date);
                    if (!IsDtValid(visitDate)) {
                        Qarray[idx].inValidDate = true;
                    }
                    else {
                        Qarray[idx].inValidDate = false;
                        if (IsDateBefore(visitDate, venetoStartDt) || IsDateAfter(visitDate, venetoEndDt)) {
                            let msg = HealthCareVisit_Msgs.venetoInitRamUpCompleteInvalidDate.replace('[insert Venetoclax Start Date]', venetoStartDate);
                            msg = msg.replace('[insert Venetoclax End Date]', venetoEndDate);

                            Qarray[idx].venetoInitRamUpDateInvalid = true;
                            Qarray[idx].venetoInitRamUpDateInvalidMsg = msg;
                        } else {
                            Qarray[idx].venetoInitRamUpDateInvalid = false;
                            Qarray[idx].venetoInitRamUpDateInvalidMsg = "";
                        }
                    }
                    Qarray[idx][optionId] = date;
                    Qarray[idx].visitdateUnk = 1;
                    Qarray[idx].hruVisitDateEmpty = false;
                    Qarray[idx].hruNoVisitDateEmpty = false;
                    setPtHRUVeneto(Qarray);
                }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (!isLoad) {
            let visitCount = parseInt(ptHRUMain.visitsIoivCount, 10);
            visitCount = visitCount > 10 ? 10 : visitCount;

            // If visitCount is 0, null, or empty, reset to an empty array
            if (visitCount === 0 || visitCount === null || ptHRUMain.visitsIoivCount === "") {
                setPtHRUOV([]); // Reset to empty array
            }
            // If visitCount is greater than 0, update the visits as needed
            else if (visitCount > 0) {
                setPtHRUOV(prevVisits => {
                    const currentCount = prevVisits.length;

                    if (visitCount < currentCount) {
                        return prevVisits.slice(0, visitCount);
                    }

                    const remainingToAdd = Math.min(visitCount - currentCount, 10 - currentCount);

                    if (remainingToAdd <= 0) return prevVisits;

                    // Create the new visits array
                    const newVisits = Array.from({ length: remainingToAdd }, () => ({
                        userId: GetLoggedInUserID(),
                        healthcarevisitbridgeid: 0,
                        treatmentid: 1,
                        categoryEmergencyVisi: false,
                        categoryInoutVisit: false,
                        categoryIcuVisit: false,
                        categoryOth: false,
                        categoryUnk: null,
                        reasonTls: false,
                        reasonNontls: false,
                        tlsUnk: null,
                        visitdate: null,
                        visitdateUnk: null,
                        visitdays: "",
                        visitdaysTreatmentRestarted: null,
                        hruCategoryEmpty: false,
                        hruOtherValueEmpty: false,
                        hruReasonsEmpty: false,
                        hruVisitDateEmpty: false,
                        hruNoVisitDateEmpty: false,
                        hruInOutDaysEmpty: false,
                        inValidDate: false,
                        ptVenetoInvalidRangeMsg: "",
                        venetoInitRamUpDateInvalid: false,
                        venetoInitRamUpDateInvalidMsg: "",
                    }));

                    // Return the updated visits array with new visits appended
                    return [...prevVisits, ...newVisits];
                });
            }
        }
    }, [ptHRUMain.visitsIoivCount, isLoad]);

    useEffect(() => {
        if (!isLoad) {
            let visitCount = parseInt(ptHRUMain.visitsIvcvCount, 10);

            visitCount = visitCount > 10 ? 10 : visitCount;

            if (visitCount === 0 || visitCount === null || ptHRUMain.visitsIvcvCount === "") {
                setPtHRUVeneto([]); // Reset to empty array
            }
            else if (visitCount > 0) {
                setPtHRUVeneto(prevVisits => {
                    const currentCount = prevVisits.length;

                    if (visitCount < currentCount) {
                        return prevVisits.slice(0, visitCount);
                    }

                    const remainingToAdd = Math.min(visitCount - currentCount, 10 - currentCount);

                    if (remainingToAdd <= 0) return prevVisits;

                    // Create the new visits array
                    const newVisits = Array.from({ length: remainingToAdd }, () => ({
                        userId: GetLoggedInUserID(),
                        healthcarevisitbridgeid: 0,
                        treatmentid: 1,
                        categoryEmergencyVisi: false,
                        categoryInoutVisit: false,
                        categoryIcuVisit: false,
                        categoryOth: false,
                        categoryUnk: null,
                        reasonTls: false,
                        reasonNontls: false,
                        tlsUnk: null,
                        visitdate: null,
                        visitdateUnk: null,
                        visitdays: "",
                        visitdaysTreatmentRestarted: null,
                        hruCategoryEmpty: false,
                        hruOtherValueEmpty: false,
                        hruReasonsEmpty: false,
                        hruVisitDateEmpty: false,
                        hruNoVisitDateEmpty: false,
                        hruInOutDaysEmpty: false,
                        inValidDate: false,
                        ptVenetoInvalidRangeMsg: "",
                        venetoInitRamUpDateInvalid: false,
                        venetoInitRamUpDateInvalidMsg: "",
                    }));

                    // Return the updated visits array with new visits appended
                    return [...prevVisits, ...newVisits];
                });
            }
        }
    }, [ptHRUMain.visitsIvcvCount, isLoad]);


    function formatInput(e) {
        const invalidChars = /[^0-9\s]/;
        const allowedKeys = ["Backspace", "Delete", " ", "ArrowLeft", "ArrowRight", "Tab"];

        if (e.key !== undefined) {
            if (allowedKeys.includes(e.key)) {
                return;
            }

            if (invalidChars.test(e.key)) {
                e.preventDefault();
            }
        } else if (e.keyCode !== undefined) {
            if (
                e.keyCode !== 8 &&  // Backspace
                e.keyCode !== 46 && // Delete
                e.keyCode !== 32 && // Space
                (e.keyCode < 48 || e.keyCode > 57) // Numbers 0-9
            ) {
                e.preventDefault();
            }
        }
    }

    const ValidateForm = () => {
        let isValid = true;

        // Checkbox validation (at least one checkbox should be selected)
        const coverageSelected =
            ptHRUMain.coverageCommercial ||
            ptHRUMain.coverageMedicare ||
            ptHRUMain.coverageMedicaid ||
            ptHRUMain.coverageMilitary ||
            ptHRUMain.coverageOth ||
            ptHRUMain.holds;
        if (!coverageSelected) {
            setHardErrList((prev) => ({
                ...prev,
                qHealthCoverageEmpty: true,
            }));
            isValid = false;
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                qHealthCoverageEmpty: false,
            }));
        }

        // Validate 'Other' text input if 'Other' checkbox is checked
        if (ptHRUMain.coverageOth && !ptHRUMain.coverageOthTxt.trim()) {
            setHardErrList((prev) => ({
                ...prev,
                qHealthCoverageOtherTextEmpty: true,
            }));
            isValid = false;
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                qHealthCoverageOtherTextEmpty: false,
            }));
        }


        // Radio button validation for insurance benefits
        if (!ptHRUMain.insuranceBenifits) {
            setHardErrList((prev) => ({
                ...prev,
                qInsuranceEmpty: true,
            }));
            isValid = false;
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                qInsuranceEmpty: false,
            }));
        }

        // Radio button and numeric input validation for healthcare visits
        if (!ptHRUMain.visitsIoivCountUnk) {
            setHardErrList((prev) => ({
                ...prev,
                qLoopOVVentoEmpty: true,
            }));
            isValid = false;
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                qLoopOVVentoEmpty: false,
            }));
        }

        if (ptHRUMain.visitsIoivCountUnk === 1 && (ptHRUMain.visitsIoivCount === "" || ptHRUMain.visitsIoivCount === null)) {
            setHardErrList((prev) => ({
                ...prev,
                qLoopOVVentoTextEmpty: true,
            }));
            isValid = false;
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                qLoopOVVentoTextEmpty: false,
            }));
        }


        // Radio button and numeric input validation for healthcare visits
        if (!ptHRUMain.visitIvcvCountUnk) {
            setHardErrList((prev) => ({
                ...prev,
                qLoopVentoEmpty: true,
            }));
            isValid = false;
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                qLoopVentoEmpty: false,
            }));
        }

        if (ptHRUMain.visitIvcvCountUnk === 1 && (ptHRUMain.visitsIvcvCount === "" || ptHRUMain.visitsIvcvCount === null)) {
            setHardErrList((prev) => ({
                ...prev,
                qLoopVentoTextEmpty: true,
            }));
            isValid = false;
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                qLoopVentoTextEmpty: false,
            }));
        }


        //Loop 1 validation
        // Map over the current state to check for errors
        if (ptHRUMain.visitsIoivCount > 0) {

            const updatedPtHRUOV = ptHRUOV.map((data, index) => {
                // Initialize validation flags for each entry
                let hasErrors = false;


                if (!IsDtValid(data.visitdate)) {
                    data.inValidDate = true;
                    hasErrors = true;
                }
                else {
                    hasErrors = false;
                    data.inValidDate = false;
                }



                // Validate "Please indicate the category" section
                if (!data.categoryEmergencyVisi && !data.categoryInoutVisit && !data.categoryIcuVisit && !data.categoryOth && data.categoryUnk !== 99) {
                    data.hruCategoryEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruCategoryEmpty = false;
                }

                // Validate "Please specify the reason" section
                if (!data.reasonTls && !data.reasonNontls && data.tlsUnk !== 99) {
                    data.hruReasonsEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruReasonsEmpty = false;
                }

                // Validate "What was the date of the healthcare visit" section
                if (IsDtValid(data.visitdate) && !data.visitdate && data.visitdateUnk !== 99 && data.visitdateUnk !== 1) {
                    data.hruVisitDateEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruVisitDateEmpty = false;
                }

                if (IsDtValid(data.visitdate) && !data.visitdate && data.visitdateUnk === 1) {
                    data.hruNoVisitDateEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruNoVisitDateEmpty = false;
                }

                // Validate "How many days after the healthcare visit the treatment was restarted" section
                if (!data.visitdaysTreatmentRestarted && !data.visitdays) {
                    data.hruInOutDaysEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruInOutDaysEmpty = false;
                }

                if (hasErrors) {
                    isValid = false;
                }

                // Return the updated data object with flags
                return { ...data };
            });

            // Update the state with any validation errors
            setPtHRUOV(updatedPtHRUOV);
        }

        if (ptHRUMain.visitsIvcvCount > 0) {
            //Loop 2 validation
            const updatedPtHRUVeneto = ptHRUVeneto.map((data, index) => {
                // Initialize validation flags for each entry
                let hasErrors = false;


                if (!IsDtValid(data.visitdate)) {
                    hasErrors = true;
                    data.inValidDate = true;
                }
                else {
                    hasErrors = false;
                    data.inValidDate = false;
                }

                // Validate "Please indicate the category" section
                if (!data.categoryEmergencyVisi && !data.categoryInoutVisit && !data.categoryIcuVisit && !data.categoryOth && data.categoryUnk !== 99) {
                    data.hruCategoryEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruCategoryEmpty = false;
                }

                // Validate "Please specify the reason" section
                if (!data.reasonTls && !data.reasonNontls && data.tlsUnk !== 99) {
                    data.hruReasonsEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruReasonsEmpty = false;
                }

                // Validate "What was the date of the healthcare visit" section
                if (IsDtValid(data.visitdate) && !data.visitdate && data.visitdateUnk !== 99 && data.visitdateUnk !== 1) {
                    data.hruVisitDateEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruVisitDateEmpty = false;
                }

                if (IsDtValid(data.visitdate) && !data.visitdate && data.visitdateUnk === 1) {
                    data.hruNoVisitDateEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruNoVisitDateEmpty = false;
                }

                // Validate "How many days after the healthcare visit the treatment was restarted" section
                if (!data.visitdaysTreatmentRestarted && !data.visitdays) {
                    data.hruInOutDaysEmpty = true;
                    hasErrors = true;
                    isValid = false;
                } else {
                    data.hruInOutDaysEmpty = false;
                }

                if (hasErrors) {
                    isValid = false;
                }

                // Return the updated data object with flags
                return { ...data };
            });

            setPtHRUVeneto(updatedPtHRUVeneto);
        }

        return isValid;
    };

    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>

            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.HRU} />

                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>HEALTHCARE VISITS</h4>
                            </div>
                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>}
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">
                                        <div className="question-bot-sapce single-dig-quest">

                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>40.</span>
                                                    <span className="quest-text-pad">
                                                        What type(s) of healthcare coverage did the patient have at the time of initiation of VO therapy?
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="coverageCommercial"
                                                                name="coverageCommercial"
                                                                checked={
                                                                    ptHRUMain.coverageCommercial
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Commercial/private insurance
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="coverageMedicare"
                                                                name="coverageMedicare"
                                                                checked={
                                                                    ptHRUMain.coverageMedicare
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Medicare
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="coverageMedicaid"
                                                                name="coverageMedicaid"
                                                                checked={
                                                                    ptHRUMain.coverageMedicaid
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Medicaid
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="coverageMilitary"
                                                                name="coverageMilitary"
                                                                checked={
                                                                    ptHRUMain.coverageMilitary
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Military insurance (Veterans Affairs, active military or Tricare)
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="coverageOth"
                                                                name="coverageOth"
                                                                checked={
                                                                    ptHRUMain.coverageOth
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Other, specify
                                                                <input
                                                                    className="input-dash"
                                                                    name="coverageOthTxt"
                                                                    type="text"
                                                                    maxLength={500}
                                                                    value={ptHRUMain.coverageOthTxt}
                                                                    onChange={HandleFieldChange}
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="holds_1"
                                                            name="holds"
                                                            checked={ptHRUMain.holds === 1}
                                                            value={1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="holds_1">
                                                            <span className="radio-text-padding">No insurance</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="holds_99"
                                                            name="holds"
                                                            checked={ptHRUMain.holds === 99}
                                                            value={99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="holds_99">
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <ErrorField show={hardErrList.qHealthCoverageEmpty} message={HealthCareVisit_Msgs.healthCoverage} />
                                                <ErrorField show={hardErrList.qHealthCoverageOtherTextEmpty} message={HealthCareVisit_Msgs.healthCoverageOther} />
                                            </div>

                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>41.</span>
                                                    <span className="quest-text-pad">
                                                        Did the patient’s insurance include pharmacy benefits (i.e., drug coverage) at the time of initiation of VO therapy?
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="insurance_Yes_1"
                                                            name="insuranceBenifits"
                                                            checked={ptHRUMain.insuranceBenifits === 1}
                                                            value={1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="insurance_Yes_1">
                                                            <span className="radio-text-padding">Yes</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="insurance_No_2"
                                                            name="insuranceBenifits"
                                                            checked={ptHRUMain.insuranceBenifits === 2}
                                                            value={2}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="insurance_No_2">
                                                            <span className="radio-text-padding">No</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="insurance_Unk_99"
                                                            name="insuranceBenifits"
                                                            checked={ptHRUMain.insuranceBenifits === 99}
                                                            value={99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="insurance_Unk_99">
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.qInsuranceEmpty} message={HealthCareVisit_Msgs.insurance} />
                                            </div>

                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>42.</span>
                                                    <span className="quest-text-pad">
                                                        How many healthcare visits (inpatient or outpatient) did the patient have between initiation of obinutuzumab and initiation of venetoclax ramp-up (
                                                        <span style={{
                                                            color: PageDesignConstants.fontColor,
                                                        }}>{indexdate}</span> and <span style={{
                                                            color: PageDesignConstants.fontColor,
                                                        }}>{venetoStartDate}</span>)? Please exclude any planned admissions, such as those that are a part of the treatment regimen, or any other planned admissions
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="in_Out_rbtn"
                                                                name="visitsIoivCountUnk"
                                                                value={1}
                                                                checked={ptHRUMain.visitsIoivCountUnk === 1}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <input
                                                                className="input-dash input_custome-space_sm"
                                                                name="visitsIoivCount"
                                                                type="text"
                                                                maxLength={100}
                                                                value={ptHRUMain.visitsIoivCount}
                                                                // onChange={HandleFieldChange}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const regex = /^\d{0,2}$/;
                                                                    if (regex.test(value)) {
                                                                        HandleFieldChange(e);
                                                                    }
                                                                }}
                                                                onKeyDown={formatInput}
                                                            />
                                                            <span className="radio-text-padding">
                                                                healthcare visits{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label className="option-fbox custom-spacing-lbl">
                                                            <input
                                                                type="radio"
                                                                id="in_Out_Unk"
                                                                name="visitsIoivCountUnk"
                                                                value={99}
                                                                checked={ptHRUMain.visitsIoivCountUnk === 99}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span>
                                                                Unknown{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.qLoopOVVentoEmpty} message={HealthCareVisit_Msgs.hruInOutOBVeneto} />
                                                <ErrorField show={hardErrList.qLoopOVVentoTextEmpty} message={HealthCareVisit_Msgs.hruInOutOBVenetoValue} />
                                                <ErrorField show={hardErrList.qLoopOVVentoInvalidRangeEmpty} message={ptOBVenetoInvalidRangeMsg} />
                                            </div>

                                            {/* LOOP STARTS HERE
                                            [If Q42 (value) > 0 then display the following loop (i.e., Q43 to Q46)]

                                            
                                            LOOP THROUGH HEALTHCARE VISITS DURING OBINUTUZUMAB WHERE N = 1 to Q42 (VALUE) */}
                                            {ptHRUMain.visitsIoivCount !== "" && ptHRUMain.visitsIoivCount > 0 &&
                                                ptHRUOV.length > 0 &&
                                                ptHRUOV.map((data, index) =>
                                                    <div className="custom-loop-back" key={index}>
                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>43.</span>
                                                                <span className="quest-text-pad">
                                                                    Please indicate the category that best describes the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient). <i>Please select all that apply.</i>
                                                                </span>
                                                            </div>
                                                            <div className="double-dig-answer">
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryEmergencyVisi_" + index}
                                                                            // value={data.categoryEmergencyVisi}
                                                                            checked={data.categoryEmergencyVisi}
                                                                            onChange={HandleObinutuzumabVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Emergency visit</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryInoutVisit_" + index}
                                                                            // value={data.categoryInoutVisit === true ? true : false}
                                                                            checked={data.categoryInoutVisit}
                                                                            onChange={HandleObinutuzumabVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Inpatient or outpatient visit</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryIcuVisit_" + index}
                                                                            // value={data.categoryIcuVisit}
                                                                            checked={data.categoryIcuVisit}
                                                                            onChange={HandleObinutuzumabVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">ICU visit</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryOth_" + index}
                                                                            // value={data.categoryOth}
                                                                            checked={data.categoryOth}
                                                                            onChange={HandleObinutuzumabVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Other</span>
                                                                    </label>
                                                                </div>

                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"categoryUnk_" + index}
                                                                            value={99}
                                                                            checked={data.categoryUnk === 99}
                                                                            onChange={HandleObinutuzumabVenCategoryRbtnChange}
                                                                        />
                                                                        <span className="radio-text-padding">
                                                                            Unknown{" "}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={data.hruCategoryEmpty} message={HealthCareVisit_Msgs.categoryEmpty} />
                                                        </div>

                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>44.</span>
                                                                <span className="quest-text-pad">
                                                                    Please specify the reason(s) for the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient).
                                                                </span>
                                                            </div>

                                                            <div className="double-dig-answer">
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"reasonTls_" + index}
                                                                            checked={data.reasonTls}
                                                                            onChange={HandleObinutuzumabVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">TLS-related</span>
                                                                    </label>

                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"reasonNontls_" + index}
                                                                            checked={data.reasonNontls}
                                                                            onChange={HandleObinutuzumabVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Non-TLS-related</span>
                                                                    </label>

                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"tlsUnk_" + index}
                                                                            value={99}
                                                                            checked={data.tlsUnk}
                                                                            onChange={HandleObinutuzumabVenCategoryRbtnChange}
                                                                        />
                                                                        <span className="radio-text-padding">Unknown</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={data.hruReasonsEmpty} message={HealthCareVisit_Msgs.reasonsEmpty} />
                                                        </div>

                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>45.</span>
                                                                <span className="quest-text-pad">
                                                                    What was the date of the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient)?
                                                                </span>
                                                            </div>
                                                            <div className="double-dig-answer">
                                                                <div className="date-field-padding">
                                                                    <input
                                                                        type="radio"
                                                                        id={index}
                                                                        name={"hru_In_Out_visit_Date_" + index}
                                                                        value={1}
                                                                        checked={data.visitdateUnk === 1}
                                                                        onChange={HandleObinutuzumabVenetoInOutDateChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        <DateControl
                                                                            ctrlId={"hru_In_Out_visit_Date_Value_" + index}
                                                                            optionId={"visitdate"}
                                                                            HandleDateChange={HandleObinutuzumabDateChange}
                                                                            date={data.visitdate}
                                                                            showUnkInfoTxt={false}
                                                                            minDate={dayjs("01-01-1920")}
                                                                            maxDate={dayjs()}
                                                                            locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                                                        />
                                                                        {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                                                                    </span>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label >
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"hru_In_Out_visit_Date_" + index}
                                                                            value={99}
                                                                            checked={data.visitdateUnk === 99}
                                                                            onChange={HandleObinutuzumabVenetoInOutDateChange}
                                                                        />

                                                                        <span className="radio-text-padding">Unknown</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={data.hruVisitDateEmpty} message={HealthCareVisit_Msgs.hruVisitDateEmpty} />
                                                            <ErrorField show={data.hruNoVisitDateEmpty} message={HealthCareVisit_Msgs.hruVisitDateValueEmpty} />
                                                            <ErrorField show={data.venetoInitRamUpDateInvalid} message={data.venetoInitRamUpDateInvalidMsg} />
                                                            <ErrorField show={data.inValidDate} message={HealthCareVisit_Msgs.invalidDate} />

                                                        </div>

                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>46.</span>
                                                                <span className="quest-text-pad">
                                                                    Please specify how many days after the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient) the treatment was restarted.
                                                                </span>
                                                            </div>
                                                            <div className="double-dig-answer">
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"days_after_HRU_Visit_" + index}
                                                                            value={1}
                                                                            checked={
                                                                                data.visitdaysTreatmentRestarted === 1
                                                                            }
                                                                            onChange={HandleTreatmentRestartedObinutuzumabVenLoop}
                                                                        />
                                                                        <input
                                                                            className="input-dash input_custome-space_sm"
                                                                            name={"days_after_HRU_Visit_txt_" + index}
                                                                            type="text"
                                                                            maxLength={100}
                                                                            id={index}
                                                                            value={data.visitdays}
                                                                            // onChange={HandleObinutuzumabTreatmentRestartedTextChange}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                const regex = /^\d{0,2}$/;
                                                                                if (regex.test(value)) {
                                                                                    HandleObinutuzumabTreatmentRestartedTextChange(e);
                                                                                }
                                                                            }}
                                                                            onKeyDown={formatInput}
                                                                        /> days
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"days_after_HRU_Visit_" + index}
                                                                            value={2}
                                                                            checked={
                                                                                data.visitdaysTreatmentRestarted === 2
                                                                            }
                                                                            onChange={HandleTreatmentRestartedObinutuzumabVenLoop}
                                                                        />
                                                                        <span>
                                                                            Not applicable (e.g., the patient continued treatment){" "}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"days_after_HRU_Visit_" + index}
                                                                            value={99}
                                                                            checked={
                                                                                data.visitdaysTreatmentRestarted === 99
                                                                            }
                                                                            onChange={HandleTreatmentRestartedObinutuzumabVenLoop}
                                                                        />
                                                                        <span>
                                                                            Unknown{" "}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={data.hruInOutDaysEmpty} message={HealthCareVisit_Msgs.hruTreatmmentRestartEmpty} />
                                                            <ErrorField show={data.ptVenetoInvalidRangeValue} message={data.ptVenetoInvalidRangeMsg} />
                                                            <ErrorField show={data.venetoRestartedInvalid} message={data.venetoRestartedInvalidDaysMsg} />
                                                        </div>
                                                        <div className="question-bot-sapce custom-loop-back-white"></div>
                                                    </div>
                                                )}
                                            {/* End of LOOP */}

                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>47.</span>
                                                    <span className="quest-text-pad">
                                                        How many healthcare visits (inpatient or outpatient)s did the patient have between initiation and completion of venetoclax ramp-up (<span style={{
                                                            color: PageDesignConstants.fontColor,
                                                        }}>{venetoInitDate}</span> and <span style={{
                                                            color: PageDesignConstants.fontColor,
                                                        }}>{venetoLastDoseDate}</span>)? Please exclude any planned admissions, such as those that are a part of the treatment regimen, or any other planned admissions.
                                                    </span>
                                                </div>

                                                <div className="double-dig-answer">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="veneto_HRU_Visit_1"
                                                                name="visitIvcvCountUnk"
                                                                value={1}
                                                                checked={ptHRUMain.visitIvcvCountUnk === 1}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <input
                                                                className="input-dash input_custome-space_sm"
                                                                name="visitsIvcvCount"
                                                                type="text"
                                                                maxLength={100}
                                                                value={ptHRUMain.visitsIvcvCount}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const regex = /^\d{0,2}$/;
                                                                    if (regex.test(value)) {
                                                                        HandleFieldChange(e);
                                                                    }
                                                                }}
                                                                onKeyDown={formatInput}
                                                            /> healthcare visits

                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label className="option-fbox custom-spacing-lbl">
                                                            <input
                                                                type="radio"
                                                                id="veneto_HRU_Visit_99"
                                                                name="visitIvcvCountUnk"
                                                                value={99}
                                                                checked={ptHRUMain.visitIvcvCountUnk === 99}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span>
                                                                Unknown
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.qLoopVentoEmpty} message={HealthCareVisit_Msgs.hruInOutInitVeneto} />
                                                <ErrorField show={hardErrList.qLoopVentoTextEmpty} message={HealthCareVisit_Msgs.hruInOutInitVenetoValue} />
                                                <ErrorField show={hardErrList.qLoopVentoInvalidRangeEmpty} message={ptVenetoInitInvalidRangeMsg} />
                                            </div>

                                            {/* Start of 2nd LOOP */}
                                            {ptHRUMain.visitsIvcvCount !== "" && ptHRUMain.visitsIvcvCount > 0 &&
                                                ptHRUVeneto.map((data, index) =>
                                                    <div className="custom-loop-back" key={index}>
                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>48.</span>
                                                                <span className="quest-text-pad">
                                                                    Please indicate the category that best describes the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient).
                                                                </span>
                                                            </div>
                                                            <div className="double-dig-answer">
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryEmergencyVisi_" + index}
                                                                            checked={data.categoryEmergencyVisi}
                                                                            onChange={HandleVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Emergency visit</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryInoutVisit_" + index}
                                                                            checked={data.categoryInoutVisit}
                                                                            onChange={HandleVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Inpatient or outpatient visit</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryIcuVisit_" + index}
                                                                            checked={data.categoryIcuVisit}
                                                                            onChange={HandleVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">ICU visit</span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"categoryOth_" + index}
                                                                            checked={data.categoryOth}
                                                                            onChange={HandleVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Other</span>
                                                                    </label>
                                                                </div>

                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"categoryUnkown_" + index}
                                                                            value={99}
                                                                            checked={data.categoryUnk === 99}
                                                                            onChange={HandleVenCategoryRbtnChange}
                                                                        />
                                                                        <span className="radio-text-padding">
                                                                            Unknown{" "}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={data.hruCategoryEmpty} message={HealthCareVisit_Msgs.categoryEmpty} />
                                                        </div>

                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>49.</span>
                                                                <span className="quest-text-pad">
                                                                    Please specify the reason for the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient).
                                                                </span>
                                                            </div>
                                                            <div className="double-dig-answer">
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"reasonTls_" + index}
                                                                            checked={data.reasonTls}
                                                                            onChange={HandleVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">TLS-related</span>
                                                                    </label>

                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={index}
                                                                            name={"reasonNontls_" + index}
                                                                            checked={data.reasonNontls}
                                                                            onChange={HandleVenCategoryChkChange}
                                                                        />
                                                                        <span className="radio-text-padding">Non-TLS-related</span>
                                                                    </label>

                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"tlsUnkown_" + index}
                                                                            value={99}
                                                                            checked={data.tlsUnk === 99}
                                                                            onChange={HandleVenCategoryRbtnChange}
                                                                        />
                                                                        <span className="radio-text-padding">Unknown</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={data.hruReasonsEmpty} message={HealthCareVisit_Msgs.reasonsEmpty} />
                                                        </div>

                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>50.</span>
                                                                <span className="quest-text-pad">
                                                                    What was the date of the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient)?
                                                                </span>
                                                            </div>
                                                            <div className="double-dig-answer">
                                                                <div className="date-field-padding">
                                                                    <input
                                                                        type="radio"
                                                                        id={index}
                                                                        name={"hru_In_Out_visit_Veneto_Date_" + index}
                                                                        value={1}
                                                                        checked={data.visitdateUnk === 1}
                                                                        onChange={HandleVenetoInOutDateChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        <DateControl
                                                                            ctrlId={"hru_In_Out_visit_Veneto_Date_Value_" + index}
                                                                            optionId={"visitdate"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            // id={index}
                                                                            date={data.visitdate}
                                                                            showUnkInfoTxt={false}
                                                                            minDate={dayjs("01-01-1920")}
                                                                            maxDate={dayjs()}
                                                                            locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                                                        />
                                                                        {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                                                                    </span>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label >
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"hru_In_Out_visit_Veneto_Date_" + index}
                                                                            value={99}
                                                                            checked={data.visitdateUnk === 99}
                                                                            onChange={HandleVenetoInOutDateChange}
                                                                        />

                                                                        <span className="radio-text-padding">Unknown</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField show={data.hruVisitDateEmpty} message={HealthCareVisit_Msgs.hruVisitDateEmpty} />
                                                            <ErrorField show={data.hruNoVisitDateEmpty} message={HealthCareVisit_Msgs.hruVisitDateValueEmpty} />
                                                            <ErrorField show={data.venetoInitRamUpDateInvalid} message={data.venetoInitRamUpDateInvalidMsg} />
                                                            <ErrorField show={data.inValidDate} message={HealthCareVisit_Msgs.invalidDate} />
                                                        </div>

                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>51.</span>
                                                                <span className="quest-text-pad">
                                                                    Please specify how many days after the {GetOrdinal(index + 1)} healthcare visit (inpatient or outpatient) the treatment was restarted.
                                                                </span>
                                                            </div>
                                                            <div className="double-dig-answer">
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"days_after_HRU_Veneto_Visit_" + index}
                                                                            value={1}
                                                                            checked={data.visitdaysTreatmentRestarted === 1}
                                                                            onChange={HandleTreatmentRestartedVenLoop}
                                                                        />
                                                                        <input
                                                                            className="input-dash input_custome-space_sm"
                                                                            name={"days_after_HRU_Veneto_Visit_txt" + index}
                                                                            type="number"
                                                                            id={index}
                                                                            maxLength={100}
                                                                            value={data.visitdays}
                                                                            // onChange={HandleTreatmentRestartedTextChange}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                const regex = /^\d{0,2}$/;
                                                                                if (regex.test(value)) {
                                                                                    HandleTreatmentRestartedTextChange(e);
                                                                                }
                                                                            }}
                                                                            onKeyDown={formatInput}
                                                                        /> days
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"days_after_HRU_Veneto_Visit_" + index}
                                                                            value={2}
                                                                            checked={data.visitdaysTreatmentRestarted === 2}
                                                                            onChange={HandleTreatmentRestartedVenLoop}
                                                                        />
                                                                        <span>
                                                                            Not applicable (e.g., the patient continued treatment){" "}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                        <input
                                                                            type="radio"
                                                                            id={index}
                                                                            name={"days_after_HRU_Veneto_Visit_" + index}
                                                                            value={99}
                                                                            checked={data.visitdaysTreatmentRestarted === 99}
                                                                            onChange={HandleTreatmentRestartedVenLoop}
                                                                        />
                                                                        <span>
                                                                            Unknown{" "}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <ErrorField show={data.hruInOutDaysEmpty} message={HealthCareVisit_Msgs.hruTreatmmentRestartEmpty} />
                                                                <ErrorField show={data.ptVenetoInvalidRangeValue} message={data.ptVenetoInvalidRangeMsg} />
                                                                <ErrorField show={data.venetoRestartedInvalid} message={data.venetoRestartedInvalidDaysMsg} />
                                                            </div>
                                                            {/* End of LOOP */}
                                                        </div>

                                                        <div className="question-bot-sapce custom-loop-back-white"></div>
                                                    </div>
                                                )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HealthCareVisits;
