export const ToastTimeout = 4000;
export const HTTPResponse = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  NotAllowed: 405,
  Conflict: 409,
  InternalServerError: 500,
};
export const AppContextIntialState = {
  isLoading: false,
  showToast: false,
  toastMessage: "",
  actionPopUp: {
    isOpen: false,
    title: "Alert",
    description: "Are you sure?",
    confirmButtonLabel: "Yes",
    disableCancelBtn: false,
  },
  toastType: undefined,
  pageDataChanged: false,
  showTopMenu: false,
  activeTab: undefined,
  showCartPage: true,
  venRampUpWeekCnt: null,
  tmtAfterEpcoLOTCnt: null,
};
export const ToastMessageType = {
  Success: 0,
  Failed: 1,
};

export const TopMenuItems = {
  Patients: 1,
  Centers: 2,
  Abstractors: 3,
  Logout: 4,
};
export const SideMenuItems = {
  Introduction: 1,
  EligibilityCriteria: 2,
  Demographics: 3,
  ClinicalCharacteristics: 4,
  VenetoclaxRampup: 5,
  VenetoclaxRampupWeek: 6,
  TlsMonitoring: 7,
  TreatmentInterruptions: 8,
  HRU: 9,
  ValidateSubmit: 10,
};

export const SideMenuPageList = [
  { id: 1, title: "INTRODUCTION", path: "/Introduction"},
  { id: 2, title: "PATIENT ELIGIBILITY CRITERIA", path: "/EligibilityCriteria"}, 
  { id: 3, title: "PATIENT DEMOGRAPHICS", path: "/Demographics", incomplete: false },
  { id: 4, title: "PATIENT CLINICAL CHARACTERISTICS", path: "/ClinicalCharacteristics", incomplete: false },
  { id: 5, title: "VENETOCLAX RAMP-UP CHARACTERISTICS", path: "/VenetoclaxRamUp", incomplete: false },
  { path: "/VenetoclaxRampUpWeek" },
  { id: 7, title: "TLS MONITORING", path: "/TlsMonitoring", incomplete: false },
  { id: 8, title: "TREATMENT INTERRUPTIONS & DOSE REDUCTIONS", path: "/TreatmentInterruptions", incomplete: false },
  { id: 9, title: "HEALTHCARE VISITS", path: "/HealthCareVisits", incomplete: false },
  { id: 10, title: "VALIDATE & SUBMIT", path: "/ValidateSubmit"},
];

export const ToastMessages = {
  Save_Success: "Data has been Successfully saved.",
  Fail: "Oops!, Something went wrong.",
};

export const PageAction = {
  add: 1,
  edit: 2,
};

export const DefaultAGGridProps = {
  ROWS_PER_PAGE: 10,
  ROW_HEIGHT: 50,
  TABLE_HEIGHT: 50 * 10 + 100,
  NOT_FOUND: "No Records Found",
};
export const TableConstants = {
  PAGE_SIZE: 7,
  NOT_FOUND: "No Records Found",
};
export const PageDesignConstants = {
  backgroundColor: "#dff3f6",
  fontColor: "steelblue",
  fontWeight: "bold",
  PageDescriptionStyle: {
    fontWeight: "500",
    paddingBottom: "10px",
    textDecoration: "underline",
    fontStyle: "italic",
  },
};
export const DatePickerDefaultProps = {
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: "select",
  placeholderText: "MM/DD/YYYY",
  dateFormat: "MM/dd/yyyy",
  //isClearable
  //closeOnScroll: true,
  //peekNextMonth:
};
export const ErrorMessages = {
  SAVE_ERROR: "Error occurred while saving.",
  LOAD_ERROR: "Error occurred while loading.",
  EMIAL_NONEXISTENT: "Email address does not exists.",
};

export const Role = {
  Admin: 1,
  Center: 2,
};
export const DatePart = {
  Day: 1,
  Month: 2,
  Year: 3
}
export const DayMonthUnknown = {
  DayUnk: 1,
  MonthUnk: 2,
  DayMonthUnk: 3
}
export const DefaultDate = "MM/DD/YYYY";

export const Values = {
  value0:0, //instead of hardcode values using enums
  value1:1,
  value2:2,
  value3:3,
  value4:4,
  value5:5,
  value6:6,
  value99:99
}
// Values are the same as the radio btn values in Ven Ramp-up week page
export const WeeklyRegularDoseSchedule = {
  1: 2,  
  2: 3,
  3: 4,
  4: 5,
  5: 6,
}
export const WeeklyModifiedDoseSchedule = {
  1: 1,  
  2: 2,
  3: 3,
  4: 4,
  5: 5,
}
export const StrongCYP3Agents = [
  "Clarithromycin",
  "Itraconazole",
  "Ketoconazole",
  "Posaconazole",
  "Ritonavir",
  "Voriconazole"
]