import React from "react";

// Array of image sources
const imageSources = [
  "../Assets/modalPopupImages/1.jpg",
  "../Assets/modalPopupImages/2.jpg",
  "../Assets/modalPopupImages/3.jpg",
  "../Assets/modalPopupImages/4.jpg",
  "../Assets/modalPopupImages/5.jpg",
  "../Assets/modalPopupImages/6.png",
];

const handleLabelClick = () => {
  // Open the FDA label in a new tab
  window.open('https://www.rxabbvie.com/pdf/venclexta.pdf', '_blank', 'noopener,noreferrer');
};

export default function Appendix() {
  return (
    <>
      <div className="modal-popup-container" style={{ backgroundColor: "white" }}>
        <h5 style={{textAlign: "center"}}>APPENDIX</h5>

        {/* Section 1 with id */}
        <p id="venetoclax-dosing" className="modal-popup-title">
          <b>1. Venetoclax initiation and dosing summary:</b>
          <br />
          The full label is available at the
          {" "}
          <span
            className="modal-popup"
            onClick={handleLabelClick}
          >
            following link
          </span>
          . A summary of the label is described below.
        </p>
        {imageSources.slice(0, 5).map((src, index) => (
          <img key={index} className="modal-popup-images" src={src} alt={`Description of image ${index + 1}`} />
        ))}

        {/* Section 2 with id */}
        <p id="definition-line-of-therapy" className="modal-popup-title">
          <b>2. Definition of a line of therapy</b>
        </p>
        <img className="modal-popup-images" src={imageSources[5]} alt="Description of image 6" />

        {/* Section 3 with id */}
        <p id="dosing-schedule" className="modal-popup-title">
          <b>3. Dosing schedule for 5-week ramp-up phase for patients with CLL/SLL</b>
        </p>
        <table className="modal-popup-table-small">
          <thead>
            <tr>
              <th rowSpan="2" className="modal-popup-table-header-cell thead-small">Week</th>
              <th colSpan="2" className="modal-popup-table-header-cell thead-small">Oral Daily Dose (mg)</th>
            </tr>
            <tr>
              <th className="modal-popup-table-header-cell thead-small">Regular</th>
              <th className="modal-popup-table-header-cell thead-small">Modified*</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>1</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>20</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>10</td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>2</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>50</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>20</td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>3</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>100</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>50</td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>4</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>200</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>100</td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>5</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>400</td>
              <td className="modal-popup-table-cell" style={{textAlign: "center" }}>200</td>
            </tr>
          </tbody>
        </table>
        <p className="modal-para">*Please note that modified daily dosing schedule is required per label when venetoclax is co-administered with a moderate CYP3A inhibitor or P-gp inhibitor</p>

        {/* Section 4 with id */}
        <p id="tumor-lysis-syndrome" className="modal-popup-title">
          <b>4. Howard Definitions of Laboratory and Clinical Tumor Lysis Syndrome (TLS)*</b>
        </p>
        <table className="modal-popup-table-large">
          <thead className="modal-popup-table-header">
            <tr>
              <th className="modal-popup-table-header-cell">Metabolic Abnormality</th>
              <th className="modal-popup-table-header-cell">Criteria for Classification of Laboratory TLS</th>
              <th className="modal-popup-table-header-cell">Criteria for Classification of Clinical TLS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="modal-popup-table-cell">Hyperuricemia</td>
              <td className="modal-popup-table-cell">Uric acid &gt;8.0 mg/dL (475.8 μmol/L) in adults or above the upper limit of the normal range for age in children</td>
              <td className="modal-popup-table-cell"></td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell">Hyperphosphatemia</td>
              <td className="modal-popup-table-cell">Phosphorus &gt;4.5 mg/dL (1.5 mmol/L) in adults or &gt;6.5 mg/dL (2.1 mmol/L) in children</td>
              <td className="modal-popup-table-cell"></td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell">Hyperkalemia</td>
              <td className="modal-popup-table-cell">Potassium &gt;6.0 mmol/L</td>
              <td className="modal-popup-table-cell">Cardiac dysrhythmia or sudden death probably or definitely caused by hyperkalemia</td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell">Hypocalcemia</td>
              <td className="modal-popup-table-cell">Corrected calcium &lt;7.0 mg/dL (1.75 mmol/L) or ionized calcium &lt;1.12 (0.3 mmol/L)†</td>
                          <td className="modal-popup-table-cell">Cardiac dysrhythmia, sudden death, seizure, neuromuscular irritability (tetany, paresthesias, muscle twitching, carpopedal spasm, Trousseau’s sign, Chvostek’s sign, laryngospasm, or bronchospasm), hypotension, or heart failure probably or definitely caused by hypocalcemia</td>
            </tr>
            <tr>
              <td className="modal-popup-table-cell">Acute kidney injury‡</td>
              <td className="modal-popup-table-cell">Not applicable</td>
              <td className="modal-popup-table-cell">Increase in the serum creatinine level of 0.3 mg/dL (26.5 μmol/L) (or a single value &gt;1.5 times the upper limit of the age-appropriate normal range if no baseline creatinine measurement is available) or the presence of oliguria, defined as an average urine output of &lt;0.5 mL/kg/hr for 6 hours</td>
            </tr>
          </tbody>
        </table>
        <p className="modal-para">
          *In laboratory TLS, two or more metabolic abnormalities must be present during the same 24-hour period within 3 days before the start of therapy or up to 7 days afterward. Clinical TLS requires the presence of laboratory TLS plus an increased creatinine level, seizures, cardiac dysrhythmia, or death.
        </p>
        <p className="modal-para">
          †The corrected calcium level in milligrams per deciliter = measured calcium level in milligrams per deciliter + 0.8 × (4 − albumin in grams per deciliter).
        </p>
        <p className="modal-para">
          ‡Acute kidney injury is defined as an increase in the creatinine level of at least 0.3 mg per deciliter (26.5 μmol per liter) or a period of oliguria lasting 6 hours or more. By definition, if acute kidney injury is present, the patient has clinical TLS.
        </p>
      </div>
    </>
  );
}
