import WebServiceWrapper from "./WebServiceWrapper";

export const TLSMonitoringService = {
  LoadTLSMonitoringData,
  SaveTLSMonitoringDetails,
};

function LoadTLSMonitoringData(patientId) {
  return WebServiceWrapper.Get(`/tlsMonitoring/LoadTLSMonitoringData?patientId=${patientId}`); 
}

function SaveTLSMonitoringDetails(payload) {
  return WebServiceWrapper.PostWithHeader("/tlsMonitoring/SaveTLSMonitoringData", payload);
}

